import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { interfaceSelector } from "../../../redux/slices/interfaceSlice"
import styles from './Sheepy.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'

// /sheepy/success?reference=fc508e73562d9d0f9dced4827f7fc357
export const Sheepy = () => {
  // const { reference } = useParams()
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  const { selectedLanguage } = useAppSelector(interfaceSelector) // Current selected language
  const dispatch = useAppDispatch()

  useEffect(() => {

  }, []);

  return (
    <div className={clsx(stylesCommon.pageContainer)}>
      <h2>Success</h2>
      <p>Your transaction will be success created invoice. Wait please notification about payment success.</p>
      { reference && <p>Reference number {reference}</p> }
    </div>
  )
}
