import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './Security.module.scss'
import clsx from 'clsx'
import { SecurityElement, SecurityElementType } from "./SecurityElement/SecurityElement"
import Modal from "../../../layouts/ui/modal/Modal"
import React from "react"
import SecurityPassword from "../SecurityPassword/SecurityPassword"
import SecurityPhoneVerification from "../SecurityPhoneVerification/SecurityPhoneVerification"
import { SecuritySuccess } from "../SecurityPasswordSuccess/SecuritySuccess"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import {
  interfaceSelector,
  setIsShowPasswordModal,
  setPasswordSuccessModalShow,
  setIsShowPhoneNumberVerificationModal
} from "../../../../redux/slices/interfaceSlice"

export const Security = () => {

  const dispatch = useAppDispatch()

  const {
    successModal,
    isShowPasswordModal,
    isShowPhoneNumberVerificationModal
  } = useAppSelector(interfaceSelector)
  const passwordSuccessModalShow = successModal.show

  const elements: Array<SecurityElementType> = [
    {
      title: 'Change password',
      description: 'If you want to change your password, just simply click the button below',
      buttonText: 'Change password',
      onClick: () => dispatch(setIsShowPasswordModal(true))
    },
    {
      title: 'Two-factor authentication',
      description: 'Improve the security of your account by activating 2FA',
      buttonText: 'Setup 2FA',
      onClick: () => console.log('Setup 2FA')
    },
    {
      title: 'Phone number verification',
      description: 'You will receive an SMS on your phone',
      buttonText: 'Verify your phone number',
      onClick: () => dispatch(setIsShowPhoneNumberVerificationModal(true))
    }
  ]

  return (
    <div className={clsx(stylesCommon.profileRoundedTab, styles.security)}>
      {elements.map((element, index) => (
        <SecurityElement key={index} {...element} />
      ))}
      <Modal
        show={isShowPasswordModal}
        onClose={() => dispatch(setIsShowPasswordModal(false))}
        body={<SecurityPassword />}
        variant={'security'}
      />
      <Modal
        show={passwordSuccessModalShow}
        onClose={() => dispatch(setPasswordSuccessModalShow({ show: false, variant: 'password' }))}
        body={<SecuritySuccess />}
        variant={'success'}
      />

      <Modal
        show={isShowPhoneNumberVerificationModal}
        onClose={() => dispatch(setIsShowPhoneNumberVerificationModal(false))}
        body={<SecurityPhoneVerification />}
        variant={'security'}
      />


    </div>
  )
}