import React from "react"
import styles from "./FiltersForTable.module.scss"
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {FormControl} from "@mui/material"
import {BrightInput} from "../../../../../common/BrightInput/BrightInput"
import SelectInput from "../../../auth/formComponents/SelectInput/SelectInput"

type FilterOption = { value: string; label: string }

type FiltersForTableProps = {
  filters: Record<string, string> // Applied filters
  draftFilters?: Record<string, string> // Temporary filters before Apply
  onFilterChange: (key: string, value: string) => void
  filterOptions: Record<string, FilterOption[] | undefined> // Options for filters
  filterLabels?: Record<string, string>
  onApplyFilters?: () => void
  onClearFilters?: () => void
}

export const FiltersForTable: React.FC<FiltersForTableProps> = ({
                                                                  filters,
                                                                  onFilterChange,
                                                                  filterOptions,
                                                                  filterLabels = {},
                                                                  draftFilters,
                                                                  onApplyFilters,
                                                                  onClearFilters,
                                                                }) => {

  // Check if filters are different from draftFilters (to enable buttons)
  const isFiltersChanged = draftFilters && JSON.stringify(filters) !== JSON.stringify(draftFilters)

  // Default handlers in case they are not provided
  const applyFilters = onApplyFilters || (() => {})
  const clearFilters = onClearFilters || (() => {})

  return (
    <div className={styles.filtersForTable}>
      <div className={styles.filtersList}>
        {Object.entries(draftFilters || filters).map(([key, value]) => {
          const options = filterOptions[key]
          return options ? (
            <FormControl key={key} fullWidth>
              <SelectInput
                id={key}
                name={key}
                value={value}
                onChange={(e) => onFilterChange(key, e.target.value)}
                placeholder="Select option"
                options={[
                  { id: "", name: "All" },
                  ...options.map((option) => ({
                    id: String(option.value),
                    name: option.label,
                  })),
                ]}
                label={filterLabels[key] || ''}
              />
            </FormControl>
          ) : (
            <BrightInput
              key={key}
              value={value}
              onChange={(e) => onFilterChange(key, e.target.value)}
              placeholder={`Enter ${filterLabels[key] || key}`}
              label={filterLabels[key] || ''}
            />
          )
        })}
      </div>

      <div className={styles.bottomButtons}>
        <ButtonStyled variant={"glass"} onClick={clearFilters}>
          Clear all
        </ButtonStyled>
        <ButtonStyled variant={"glass"} disabled={!isFiltersChanged} onClick={applyFilters}>
          Apply
        </ButtonStyled>
      </div>
    </div>
  )
}
