import Site_logo from 'src/assets/images/icons/Democasino.svg'
import {Link} from "react-router-dom"
import React from "react"
import styles from './SiteLogo.module.scss'

export const SiteLogo = () => {
  return (
    <Link to="/">
      <img src={Site_logo} alt="site_logo" className={styles.siteLogo} />
    </Link>
  )
}