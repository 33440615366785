import React from "react"
import Box from "@mui/material/Box"
import styles from './MoneyBlock.module.scss'
import chevron_down from "../../assets/images/icons/chevron_down.svg"
import chevron_down_flipped from "../../assets/images/icons/chevron_down_flipped.svg"
import {currencySelectors} from "../CurrencySelectors"
import {useAppDispatch, useAppSelector} from "../../hooks"
import {interfaceSelector, setListBalancesDropdownShown} from "../../redux/slices/interfaceSlice"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import clsx from "clsx"
import {useTranslation} from "react-i18next";

type MoneyBlockProps = {
  currency: string
  amount: string
  type: 'Real' | 'Bonus'
  children?: React.ReactNode // children for ListBalancesDropdown
}


export const MoneyBlock: React.FC<MoneyBlockProps> = ({
                                                        type,
                                                        currency,
                                                        amount,
                                                        children,
                                                      }) => {
  const dispatch = useAppDispatch()
  const {isListBalancesDropdownShown, isBalanceLoadingAfterSwitch} = useAppSelector(interfaceSelector)

  // const isBalanceLoadingAfterSwitch = true

  const onAmountClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isBalanceLoadingAfterSwitch) return
    dispatch(setListBalancesDropdownShown(!isListBalancesDropdownShown))
  }

  const {t} = useTranslation()

  return (
    <Box className={styles.moneyContainer}>
      <div className={styles.type}>{t(type)}</div>
      <div className={clsx(styles.amount, isBalanceLoadingAfterSwitch && styles.disableCursorPointer)} onClick={onAmountClick}>
        {
          isBalanceLoadingAfterSwitch
            ? <CircularProgress className={styles.circular} size={30}/>
            : <>{currencySelectors(currency)} {amount}</>
        }
        <div className={styles.chevronContainer} >
          <img src={
            (isListBalancesDropdownShown && type === 'Real') ? chevron_down_flipped : chevron_down
          } alt="chevron down" className={clsx(styles.chevron, isBalanceLoadingAfterSwitch && styles.disableCursorPointer)}/>
          <div className={styles.childrenContainer} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>


      </div>

    </Box>
  )
}