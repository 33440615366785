import config from '../../config'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppDispatch } from "../../hooks";
import { useTranslation } from "react-i18next"
import { connectWebSocket } from 'src/redux/actions/bonus/bonusListActions'

import { 
  openModal as openModalBonus, 
  closeModal as closeModalBonus, 
  selectIsShowModal as  selectIsShowModalBonus
} from '../../redux/slices/modals/modalBonusInfoSlice'

import {
  selectBonusList,
  selectBonusListError,
  selectBonusListLoading
} from 'src/redux/selectors/bonus/bonusListSelectors'

import {
  fetchListBalances,
  selectListBalancesSliceData,
  selectListBalancesSliceMessages,
  selectListBalancesSliceError,
  selectListBalancesSliceSuccess,
  selectListBalancesSliceLoading,
  selectListBalancesSliceErrorArray,
  selectBalanceMainCurrency,
  BalanceMainCurrency
} from "src/redux/slices/Account/listBalancesSlice"

import {
  selectIsAuthenticated
} from "../../../src/redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../src/redux/slices/site/loginSlice"
import ModalAddBonus, { OnBonus, BonusFreespinsItem } from "../layouts/modals/ModalAddBonus"

type Bonus = {
  id: number
  image_link: string | null
  bonusLangs: { name: string }[]
  bonusBudgets: {
    amount: number
    currency: { code: string }
  }
  bonusFreespins: BonusFreespinsItem[] | [],
  bonusEventDeposits: [],
  bonusAccruals: [],
  bonusWagerings: [],
  bonusWageringToward: [],
  wagering_type: string,
};

// type OnBonus = {
//   id: number
// }

const GameGroup = () => {
  // const dispatch = useDispatch()
  const dispatch = useAppDispatch();
  const { t } = useTranslation()

  const bonusList = useSelector(selectBonusList) as Bonus[] | null
  const loading = useSelector(selectBonusListLoading) as boolean
  const error = useSelector(selectBonusListError) as string | null
  const isAuthenticated = useSelector(selectIsAuthenticated) as boolean
  const token = useSelector(selectToken) as string | null

  const listBalancesSliceData = useSelector(selectListBalancesSliceData)
  const listBalancesSliceError = useSelector(selectListBalancesSliceError)
  const listBalancesSliceSuccess = useSelector(selectListBalancesSliceSuccess)
  const listBalancesSliceLoading = useSelector(selectListBalancesSliceLoading)
  const listBalancesSliceErrorArray = useSelector(selectListBalancesSliceErrorArray)
  const balanceMainCurrency = useSelector(selectBalanceMainCurrency)

  const isShowModalBonus = useSelector(selectIsShowModalBonus)

  // const [showModalBonusInfo, setShowModalBonusInfo] = useState<boolean>(false)
  const [onBonus, setOnBonus] = useState<OnBonus | null>(null)
  const [mainCurrency, setMainCurrency] = useState<null | BalanceMainCurrency>(null);
  // const [spinCount, setSpinCount] = useState<number | string>("-");
  // const [wagerMultiplier, setWagerMultiplier] = useState<number | string>("-");

  useEffect(() => {
    if (isAuthenticated && token) {
      console.log('home page', token)
      const closeWebSocket = dispatch(connectWebSocket(token))

      return () => {
        if (closeWebSocket && typeof closeWebSocket === 'function') {
          closeWebSocket()
        }
      }
    }
  }, [dispatch, token, isAuthenticated])

  useEffect(() => {
    console.log('bonusList:', bonusList)
  }, [bonusList])

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchListBalances({ token }));
    }
  }, [token, isAuthenticated])

  useEffect(() => {
    if (listBalancesSliceSuccess && balanceMainCurrency) {
      setMainCurrency({
        id: balanceMainCurrency.id,
        code: balanceMainCurrency.code,
      })
    }
  }, [token, listBalancesSliceSuccess, balanceMainCurrency, listBalancesSliceData])

  // useEffect(() => {
  //   if (balanceMainCurrency) {
  //     // TODO откуда заполнять закомментированные данные!
  //     const mainCurrencyId = balanceMainCurrency.id;
  //     const mainCurrencyCode = balanceMainCurrency.code;

  //     if (onBonus && onBonus.bonusFreespins.length > 0) {
  //       const freespins = onBonus.bonusFreespins.find(
  //         (item) => item.currency.id == mainCurrencyId
  //       );

  //       setSpinCount(
  //         (() => {
  //           if (freespins) {
  //             return `${freespins.spin_count}`;
  //           }
  //           return t("-");
  //         })()
  //       );
  //       setWagerMultiplier(
  //         (() => {
  //           if (freespins) {
  //             if (freespins.wager_multiplier) {
  //               return `X${freespins.wager_multiplier}`;
  //             }
  //           }
  //           return t("-");
  //         })()
  //       )

  //     }


  //   }


  // }, [dispatch, balanceMainCurrency, onBonus]);



  const getSpinCount = (bonus: Bonus) => {
    if (balanceMainCurrency) {
      const mainCurrencyId = balanceMainCurrency.id;
      const mainCurrencyCode = balanceMainCurrency.code;

      if (bonus && bonus.bonusFreespins.length > 0) {
        const freespins = bonus.bonusFreespins.find(
          (item) => item.currency.id == mainCurrencyId
        );

        if (freespins) {
          return `${freespins.spin_count}`;
        }
      }
    }
    return t("-");
  }

  const getWagerMultiplier = (bonus: Bonus) => {
    if (balanceMainCurrency) {
      const mainCurrencyId = balanceMainCurrency.id;
      const mainCurrencyCode = balanceMainCurrency.code;

      if (bonus && bonus.bonusFreespins.length > 0) {
        const freespins = bonus.bonusFreespins.find(
          (item) => item.currency.id == mainCurrencyId
        );

        if (freespins) {
          if (freespins.wager_multiplier) {
            return `X${freespins.wager_multiplier}`;
          }
        }
      }
    }
    return t("-");
  }



  const onClickMoreInfo = async (e: React.MouseEvent<HTMLButtonElement>, bonus: Bonus) => {
    e.preventDefault() // Предотвращение дефолтного поведения кнопки (если требуется)
    console.log(`onClickMoreInfo`, bonus)
    setOnBonus({
      id: bonus.id,
      bonusEventDeposits: bonus.bonusEventDeposits,
      bonusAccruals: bonus.bonusAccruals,
      wagering_type: bonus.wagering_type,
      bonusWagerings: bonus.bonusWagerings,
      bonusFreespins: bonus.bonusFreespins,
      bonusWageringToward: bonus.bonusWageringToward,
    })
    dispatch(openModalBonus())
  }

  const onClickGetBonus = async (e: React.MouseEvent<HTMLButtonElement>, bonus: Bonus) => {
    e.preventDefault()
    console.log(`onClickGetBonus`, bonus)
  }

  return (
    <>
      <div>
        <h1>Bonuses</h1>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="row wrap-bonus">
          {bonusList &&
            bonusList.map((bonus, i) => (
              <div key={i} className="col-4">
                <div className="card">
                  {bonus.image_link !== null && bonus.image_link !== '' ? (
                    <img
                      className="card-img-top"
                      src={`${config.fileUrls.bonusFolder}${bonus.image_link}`}
                      alt={bonus.bonusLangs[0].name}
                    />
                  ) : (
                    <div className="defaultImage">
                      <h5>Bonus #{bonus.id}</h5>
                    </div>
                  )}
                  <div className="card-body">
                    <button type="button" className="btn btn-warning">Wellcome bonus group</button>
                    <h4 className="card-title">{bonus.bonusLangs[0].name}</h4>
                    <p className="card-text">Фриспины {getSpinCount(bonus)}</p>
                    <p className="card-text">Вейджеры
                      фриспинов {getWagerMultiplier(bonus)}</p>
                    <div className="bonus-card-buttons d-flex justify-content-between">
                      <button type="button" className="btn btn-primary"
                        onClick={(e) => onClickMoreInfo(e, bonus)}>Подробнее
                      </button>
                      <button type="button" className="btn btn-warning"
                        onClick={(e) => onClickGetBonus(e, bonus)}>Получить бонус
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}


      <ModalAddBonus
        isShowModal={isShowModalBonus}
        onCloseModal={() => dispatch(closeModalBonus())}
        onBonus={onBonus}
      />
    </>

  )
}

export default GameGroup
