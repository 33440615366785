import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

// i18n.use(initReactI18next).init({
//   fallbackLng: 'en', // Default language
//   debug: true,
//   interpolation: {
//     escapeValue: false,
//   },
//   returnKeyOnEmpty: true, // if no translate found, return static text
// });

const options: InitOptions = {
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  // Замена returnKeyOnEmpty
  missingKeyHandler: (lngs: readonly string[], ns: string, key: string, fallbackValue: string, updateMissing: boolean, options: any) => { 
    console.log(`Missing translation for ${key} in ${lngs.join(', ')}`); 
  },
};

i18n.use(initReactI18next).init(options);

export default i18n;
