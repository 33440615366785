// myProgressSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


// export interface BalanceItem {
//   amount: number;
//   currency: string;
//   currency_symbol: string | null;
// }

// export interface MyProgress {
//   main_balance: BalanceItem;
//   bonus_balance: BalanceItem;
// }

// export type MyProgressList = MyProgress[] | [];

type MyProgressSliceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type MyProgressSliceError = {
  error: any;
  message: string;
};

interface MyProgressSliceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: MyProgressSliceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchMyProgress
interface MyProgressSliceParams {
  token: string;
  params?: {};
}

export const fetchMyProgress = createAsyncThunk<
  MyProgressSliceResponse,
  MyProgressSliceParams,
  { rejectValue: MyProgressSliceError }
>(
  'MyProgressSlice/fetchMyProgress',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchMyProgress:', `${config.serverUrl}/player/my-progress`, { token, params });

      const response = await axios.get<MyProgressSliceResponse>(
        `${config.serverUrl}/player/my-progress`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchMyProgress response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error get list MyProgress:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get MyProgressSlice .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);

export const connectWebSocketMyProgress = (token: string, language: string | null = null) => {
  return (dispatch: any) => {
    const ws = new WebSocket(config.serverWebsocketUrl);
    console.log('Connecting to WebSocket server:', config.serverWebsocketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server for getMyProgress');
      ws.send(JSON.stringify({ type: 'getMyProgress', token }));
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'getMyProgress') {
          const myProgressData = data.myProgress?.data;
          // const myProgressArray = Array.isArray(myProgressData) ? myProgressData : [myProgressData];
          // dispatch(fetchMyProgressSuccess(myProgressArray));
          dispatch(fetchMyProgressSuccess(myProgressData));
        } else if (data.type === 'error') {
          dispatch(fetchMyProgressFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        dispatch(fetchMyProgressFailure('Error parsing WebSocket message'));
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};


const MyProgressSlice = createSlice({
  name: 'Profile/MyProgressSlice',
  initialState,
  reducers: {
    resetStateListMyProgress: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
    fetchMyProgressSuccess: (state, action: PayloadAction<any[]>) => {
      state.loading = false;
      state.success = true;
      state.data = action.payload;
      state.messages = null;
    },
    //Экшен для обработки ошибки при получении данных через WebSocket
    fetchMyProgressFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyProgress.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchMyProgress.fulfilled, (state, action: PayloadAction<MyProgressSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchMyProgress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateListMyProgress, fetchMyProgressSuccess, fetchMyProgressFailure } = MyProgressSlice.actions;

export const MyProgressSliceReducer = MyProgressSlice.reducer;



// ---------
// Selectors
// ---------
export const selectMyProgressSliceLoading = (state: RootState) => state.MyProgressSliceReducer.loading;
export const selectMyProgressSliceSuccess = (state: RootState) => state.MyProgressSliceReducer.success;
export const selectMyProgressSliceError = (state: RootState) => state.MyProgressSliceReducer.error;
export const selectMyProgressSliceMessages = (state: RootState) => state.MyProgressSliceReducer.messages;
export const selectMyProgressSliceData = (state: RootState) => state.MyProgressSliceReducer.data;

export const selectMyProgressSliceErrorString = (state: RootState): string | null => {
  const error = state.MyProgressSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectMyProgressSliceErrorArray = (state: RootState): string[] | null => {
  const error = state.MyProgressSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
