import React, { useEffect, useRef, useState } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../../../../hooks"
import Alert from '../../../../../layouts/ui/alert/Alert'
// import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { setAccountActiveButton } from "../../../../../../redux/slices/menus/menuAccountSlice"
import { setProfileActiveButton } from "../../../../../../redux/slices/menus/menuProfileSlice"
import { ModeTypes } from '../../Limits'

import {
    fetchCreateResponsibleGaming,
    selectCreateResponsibleGamingData,
    selectCreateResponsibleGamingError,
    selectCreateResponsibleGamingErrorArray,
    selectCreateResponsibleGamingSuccess,
    selectCreateResponsibleGamingMessagesString,
    selectCreateResponsibleGamingLoading,
    resetStateCreateResponsibleGaming,
    CreateResponsibleGamingParams
} from "../../../../../../redux/slices/Limits/createResponsibleGamingSlice"

import {
    fetchUpdateResponsibleGaming,
    selectUpdateResponsibleGamingData,
    selectUpdateResponsibleGamingError,
    selectUpdateResponsibleGamingErrorArray,
    selectUpdateResponsibleGamingSuccess,
    selectUpdateResponsibleGamingMessagesString,
    selectUpdateResponsibleGamingLoading,
    resetStateUpdateResponsibleGaming,
    UpdateResponsibleGamingParams
} from "../../../../../../redux/slices/Limits/updateResponsibleGamingSlice"

import {
    AvailableLimitsNames
} from "../../../../../../redux/slices/Limits/getAvailableLimitsSlice"


import {
    selectIsAuthenticated
} from "../../../../../../redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../../../redux/slices/site/loginSlice"

import styles from './Bet.module.scss'
import clsx from 'clsx'
import { ButtonStyled } from "../../../../../../common/ButtonStyled/ButtonStyled"
import { BrightInput } from "../../../../../../common/BrightInput/BrightInput"
import { setIsShowPasswordModal, setPasswordSuccessModalShow } from "../../../../../../redux/slices/interfaceSlice"
import { LimitItems } from '../../Limits'
import SelectInput, { SelectOption } from "../formComponents/SelectInput/SelectInput"

// interface UpdatePasswordState {
//     [key: string]: any; // Ключи — строки, значения могут быть разными
// }

type FormState = {
    [key: string]: string | number;  // Добавляем сигнатуру индекса
    period: number;
    amount: number;
};

type FieldName = "period" | "amount";

interface ClientErrors {
    required: { [key: string]: string };
}

export type ComponentParamsType = {
    limit: LimitItems
    mode: ModeTypes
    onSave: (token: string) => void
}

const makeInt = (value: any) => {
    if (typeof value === 'number') {
        return value;
    } else if (typeof value === 'string') {
        const result = value.match(/\d+/);
        return result ? parseInt(result[0], 10) : 0;
    }
    return 0;
};

const Bet = ({ limit, mode, onSave }: ComponentParamsType) => {
    const limitName: AvailableLimitsNames = 'bet'

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const isAuthenticated = useSelector(selectIsAuthenticated)
    const token = useSelector(selectToken)
    const formRef = useRef<HTMLFormElement>(null)

    const createResponsibleGamingData = useSelector(selectCreateResponsibleGamingData);
    const createResponsibleGamingError = useSelector(selectCreateResponsibleGamingError);
    const createResponsibleGamingErrorArray = useSelector(selectCreateResponsibleGamingErrorArray);
    const createResponsibleGamingSuccess = useSelector(selectCreateResponsibleGamingSuccess);
    const createResponsibleGamingLoading = useSelector(selectCreateResponsibleGamingLoading);
    const createResponsibleGamingMessagesString = useSelector(selectCreateResponsibleGamingMessagesString);

    const updateResponsibleGamingData = useSelector(selectUpdateResponsibleGamingData);
    const updateResponsibleGamingError = useSelector(selectUpdateResponsibleGamingError);
    const updateResponsibleGamingErrorArray = useSelector(selectUpdateResponsibleGamingErrorArray);
    const updateResponsibleGamingSuccess = useSelector(selectUpdateResponsibleGamingSuccess);
    const updateResponsibleGamingLoading = useSelector(selectUpdateResponsibleGamingLoading);
    const updateResponsibleGamingMessagesString = useSelector(selectUpdateResponsibleGamingMessagesString);

    const [clientErrors, setClientErrors] = useState<ClientErrors>({ required: {} })
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [errors, setErrors] = useState<string[] | null>(null)
    // const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками
    const [fields, setFields] = useState<string[]>([])

    const [serverValidationErrors, setServerValidationErrors] = useState({
        period: null,
        amount: null,
    })

    const [formState, setFormState] = useState<FormState>({
        period: 0,
        amount: 0,
    })

    const [initialState, setInitialState] = useState<FormState>({
        period: 0,
        amount: 0,
    })

    type Option = { id: string; name: string };

    const [fieldSelectOptions, setFieldSelectOptions] = useState<{
        period: Option[];
        amount: Option[];
    }>({
        period: [
            { id: 'Not set', name: 'Not set' },
            { id: '1', name: '1 day' },
            { id: '5', name: '5 days' },
            { id: '10', name: '10 day' },
            { id: '15', name: '15 days' }
        ],
        amount: [
            { id: '0', name: '0' },
            { id: '10', name: '10' },
            { id: '100', name: '100' },
            { id: '1000', name: '1000' },
            { id: '2000', name: '2000' },
            { id: '3000', name: '3000' },
        ],
    });

    useEffect(() => {
        dispatch(resetStateCreateResponsibleGaming())
        dispatch(resetStateUpdateResponsibleGaming())

    }, [dispatch])

    useEffect(() => {
        // console.log('Bet projectSettingsData', projectSettingsData)
        let fields = [
            'period',
            'amount',
        ]

        setFields(fields)
        setFormState({
            period: makeInt(limit.period),
            amount: limit.amount,
        })

    }, [dispatch, limit])

    useEffect(() => {
        if (createResponsibleGamingSuccess || updateResponsibleGamingSuccess) {
            if (token) {
                onSave(token)
                dispatch(resetStateCreateResponsibleGaming())
                dispatch(resetStateUpdateResponsibleGaming())
            }
            // window.location.reload();
            // navigate('/profile')
            // dispatch(setAccountActiveButton('myProfile'))
            // dispatch(setProfileActiveButton('limits'))
        }
        if (createResponsibleGamingError) {
            console.log('createResponsibleGamingError', createResponsibleGamingErrorArray)
            setErrors(createResponsibleGamingErrorArray)
        }
        if (updateResponsibleGamingError) {
            console.log('updateResponsibleGamingError', updateResponsibleGamingErrorArray)
            setErrors(updateResponsibleGamingErrorArray)
        }

    }, [token, createResponsibleGamingSuccess, updateResponsibleGamingSuccess, createResponsibleGamingError, updateResponsibleGamingError, createResponsibleGamingErrorArray, updateResponsibleGamingErrorArray])

    useEffect(() => {

        // Скидываем сообщения
        return () => {
            // Размонтирование компонента
            console.log('Component unmounted')
            clearValidationStates()

        }

    }, [])

    const clearValidationStates = () => {
        setServerValidationErrors({
            period: null,
            amount: null,
        })
        setClientErrors({ required: {} })
    }

    const clientValidation = (): boolean => {
        const formElements = formRef.current?.elements
        const newErrors: ClientErrors = { required: {} }
        let formIsValid = true

        if (formElements) {
            Array.from(formElements).forEach(field => {
                const input = field as HTMLInputElement
                if (input.required && !input.value.trim()) {
                    formIsValid = false
                    const errorMessage = input.getAttribute('data-error-required') || 'This field is required.'
                    newErrors.required[input.name] = errorMessage
                }
            })
        }

        setClientErrors(newErrors)
        return formIsValid
    }


    const handleInputChange = (code: string, e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value
        // console.log(code, value)
        const updatedFormState = {
            ...formState,
            [code]: value,
        }

        setFormState(updatedFormState)

        // Проверяем, изменились ли данные по сравнению с начальным состоянием
        setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

        // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
        setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors }

            // Проверка для строки перед использованием `trim()`
            const isFieldFilled = (typeof value === 'string' && value.trim() !== '')

            if (isFieldFilled) {
                delete updatedErrors['required'][code]
            } else {
                updatedErrors['required'][code] = 'This field is required.'
            }

            return updatedErrors
        })
    }

    // const handleErrorClose = () => {
    //     setShowErrors(false) // Скрываем ошибки при нажатии на крестик
    // }

    const handleButtonClick = () => {
        clientValidation()
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!token) {
            console.error('Token is missing')
            return // Прекращаем выполнение, если токен отсутствует
        }

        if (clientValidation()) {
            const params: CreateResponsibleGamingParams | UpdateResponsibleGamingParams = {
                token: token,
                type_limit: limitName,
                duration_days: formState.period,
                amount: formState.amount,
            }
            // console.error('fetchCreateResponsibleGaming params', params)
            if (mode === 'set') {
                dispatch(fetchCreateResponsibleGaming(params)) // Передаем токен как строку
            }
            if (mode === 'update') {
                dispatch(fetchUpdateResponsibleGaming(params)) // Передаем токен как строку
            }
        } else {
            console.error('Validation failed')
        }
    }


    const formatPlaceholder = (fieldName: string): string => {
        return fieldName
            .replace(/_/g, ' ') // Заменяем `_` на пробел
            .replace(/\b\w/g, (char) => char.toUpperCase()) // Делаем первую букву каждого слова заглавной
    }


    if (createResponsibleGamingSuccess || updateResponsibleGamingSuccess) {
        return <>
            <Alert><strong>Success!</strong> {createResponsibleGamingMessagesString || 'Settings saved successfully.'}</Alert>
        </>
    }


    return (

        <>
            <div className={clsx(styles.security)}>
                <div className={styles.title}>Bet limit</div>
                <p className="text-gray-400 text-sm mt-2">
                    You can change limits at any time.
                </p>
                <p className="text-gray-400 text-sm mt-2">
                    If you increase the amount or change the period, the changes will be
                    applied within 48 hours. Decrease of the limit amount will be applied
                    immediately.
                </p>
                <form onSubmit={handleSubmit} ref={formRef} className={styles.form}>
                    {
                        fields.map((nameField, indexField) => (
                            <div key={indexField} className="my-4">
                                <SelectInput
                                    id={nameField}
                                    name={nameField}
                                    value={formState[nameField]}
                                    label={formatPlaceholder(nameField)}
                                    placeholder={`Select ${nameField}`}
                                    onChange={(e) => handleInputChange(nameField, e)}
                                    required={true}
                                    options={fieldSelectOptions[nameField as keyof typeof fieldSelectOptions]}
                                    variant={nameField as FieldName}
                                    error={clientErrors['required'][nameField] || serverValidationErrors[nameField as FieldName]}
                                />
                            </div>
                        ))
                    }

                    <ButtonStyled
                        isWidth100={true}
                        onClick={() => handleButtonClick()}
                        type="submit"
                        disabled={createResponsibleGamingLoading}
                    >
                        {createResponsibleGamingLoading ? 'Loading...' : 'Save'}
                    </ButtonStyled>

                </form>

                <Alert type="error">
                    {errors && errors.map((error, index) => {
                        return (<div key={index}>{error}</div>)
                    })}
                </Alert>
            </div>

        </>
    )
}

export default Bet
