import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../../hooks"
import Alert from '../../../layouts/ui/alert/Alert'

import {
  fetchSendPhoneConfirmation,
  resetStateSendPhoneConfirmation,
  selectSendPhoneConfirmationLoading,
  selectSendPhoneConfirmationSuccess,
  selectSendPhoneConfirmationError,
  selectSendPhoneConfirmationMessages,
  selectSendPhoneConfirmationData,
  selectSendPhoneConfirmationErrorArray
} from '../../../../redux/slices/player/sendPhoneConfirmationSlice'

import {
  fetchConfirmPhone,
  resetStateConfirmPhone,
  selectConfirmPhoneErrorArray,
  selectConfirmPhoneLoading,
  selectConfirmPhoneSuccess,
  selectConfirmPhoneError,
  selectConfirmPhoneMessages,
  selectConfirmPhoneData
} from '../../../../redux/slices/site/confirmPhone';



import { fetchGetPersonalInfo } from 'src/redux/actions/player/getPersonalInfoActions'
import {
  selectGetPersonalInfo,
  selectGetPersonalInfoError,
  selectGetPersonalInfoLoading,
  selectGetPersonalInfoSuccess
} from 'src/redux/selectors/player/getPersonalInfoSelectors'

// import {
//   fetchProjectSettings,
//   selectProjectSettingsData,
//   selectProjectSettingsSuccess
// } from 'src/redux/slices/getProjectSettingsSlice'

import {
  selectIsAuthenticated
} from "../../../../redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../redux/slices/site/loginSlice"
import styles from './SecurityPhoneVerification.module.scss'
import clsx from 'clsx'
import { ButtonStyled } from "../../../../common/ButtonStyled/ButtonStyled"
import { BrightInput } from "../../../../common/BrightInput/BrightInput"
import { setIsShowPasswordModal, setPasswordSuccessModalShow } from "../../../../redux/slices/interfaceSlice"


// interface SecurityPhoneVerification {
//     [key: string]: any; // Ключи — строки, значения могут быть разными
// }

interface SecurityPhoneVerification {
  phone_number: string,
  sms_code: string,
}

interface ClientErrors {
  required: {
    [key: string]: {
      hasError: boolean;
      message: string;
    };
  };
}


// interface PersonalInfo {
//   phone: string;
// }

type PersonalInfoCombined = { id: number; subtype: string; code: string; name: string; flag_displayed: string; value?: string }[];


const SecurityPhoneVerification = () => {
  const dispatch = useAppDispatch()

  const getPersonalInfo: PersonalInfoCombined = useSelector(selectGetPersonalInfo)
  const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading)
  const getPersonalInfoError = useSelector(selectGetPersonalInfoError)
  const getPersonalInfoSuccess = useSelector(selectGetPersonalInfoSuccess)

  const sendPhoneConfirmationLoading = useSelector(selectSendPhoneConfirmationLoading);
  const sendPhoneConfirmationSuccess = useSelector(selectSendPhoneConfirmationSuccess);
  const sendPhoneConfirmationError = useSelector(selectSendPhoneConfirmationError);
  const sendPhoneConfirmationMessages = useSelector(selectSendPhoneConfirmationMessages);
  const sendPhoneConfirmationData = useSelector(selectSendPhoneConfirmationData);
  const sendPhoneConfirmationErrorArray = useSelector(selectSendPhoneConfirmationErrorArray);

  const confirmPhoneLoading = useSelector(selectConfirmPhoneLoading);
  const confirmPhoneSuccess = useSelector(selectConfirmPhoneSuccess);
  const confirmPhoneError = useSelector(selectConfirmPhoneError);
  const confirmPhoneMessages = useSelector(selectConfirmPhoneMessages);
  const confirmPhoneData = useSelector(selectConfirmPhoneData);
  const confirmPhoneErrorArray = useSelector(selectConfirmPhoneErrorArray);


  // const projectSettingsLoading = useSelector(selectProjectSettingsLoading)
  // const projectSettingsSuccess = useSelector(selectProjectSettingsSuccess)
  // const projectSettingsError = useSelector(selectProjectSettingsError)
  // const projectSettingsData = useSelector(selectProjectSettingsData)
  // const projectSettingsErrorArray = useSelector(selectProjectSettingsErrorArray)


  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const [isUpdatesSuccess, setIsUpdatesSuccess] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками
  const [fields, setFields] = useState<string[]>([])

  const [clientErrors, setClientErrors] = useState<ClientErrors>({
    required: {
      phone_number: {
        hasError: false,
        message: 'This field is required.'
      },
      sms_code: {
        hasError: false,
        message: 'This field is required.'
      }
    }
  });

  const [actionType, setActionType] = useState<'resend' | 'confirm'>('resend')

  const [serverValidationErrors, setServerValidationErrors] = useState({
    phone_number: null,
    sms_code: null,
  })

  const [formState, setFormState] = useState<SecurityPhoneVerification>({
    phone_number: '',
    sms_code: '',
  })

  const [initialState, setInitialState] = useState<SecurityPhoneVerification>({
    phone_number: '',
    sms_code: '',
  })
  // Начальные значения из API
  const [isEditing, setIsEditing] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    setActionType('resend')
    setShowErrors(false)
    dispatch(resetStateSendPhoneConfirmation())
    dispatch(resetStateConfirmPhone())
    
  }, [dispatch])
  
  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchGetPersonalInfo(token))
    }
  }, [dispatch, isAuthenticated, token])

  useEffect(() => {
    console.log('SecurityPhoneVerification getPersonalInfo', getPersonalInfo)

    if (actionType === 'resend') {
      setFields([
        'phone_number',
      ])
    } else {
      setFields([
        'phone_number',
        'sms_code',
      ])
    }

    if (getPersonalInfoSuccess) {
      const phoneInfo = getPersonalInfo.find(item => item.code === 'phone');

      setFormState({
        phone_number: phoneInfo?.value || '',
        sms_code: '',
      });
    }


  }, [getPersonalInfo, getPersonalInfoSuccess, actionType])

  useEffect(() => {
    if (sendPhoneConfirmationSuccess) {
      setActionType('confirm');
    }
    if (sendPhoneConfirmationError) {
      setServerValidationErrors({
        phone_number: sendPhoneConfirmationError.phone_number || null,
        sms_code: sendPhoneConfirmationError.sms_code || null,
      })
    }

    if (confirmPhoneError) {
      setServerValidationErrors({
        phone_number: confirmPhoneError.phone_number || null,
        sms_code: confirmPhoneError.sms_code || null,
      })
    }

  }, [dispatch, sendPhoneConfirmationSuccess, sendPhoneConfirmationError, confirmPhoneError])

  useEffect(() => {
    if (confirmPhoneSuccess) {
      setActionType('resend');
    }
  }, [dispatch, sendPhoneConfirmationSuccess])

  // const clearStates = () => {
  //   dispatch(resetState())
  //   setIsUpdatesSuccess(false)
  //   setShowErrors(false)
  // }

  // const clearValidationStates = () => {
  //   setServerValidationErrors({
  //     phone_number: null,
  //     sms_code: null,
  //   })
  //   // setClientErrors({ required: {} })
  // }

  // useEffect(() => {

  //   // Скидываем сообщения
  //   return () => {
  //     // Размонтирование компонента
  //     console.log('Component unmounted')
  //     setActionType('resend')
  //     setShowErrors(false)
  //     clearValidationStates()

  //   }

  // }, [])

  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    let formIsValid = true

    const isFieldRequired = (fieldName: string) => {
      return clientErrors.required.hasOwnProperty(fieldName)
    };

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        const inputName = input.name;
        const inputValue = input.value.trim();

        if (!inputValue && isFieldRequired(inputName)) {
          formIsValid = false

          setClientErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors }

            updatedErrors.required[inputName].hasError = true

            return updatedErrors
          })
        }
      })
    }


    return formIsValid
  }

  const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value = e.target.value
    // console.log(code, value)
    const updatedFormState = {
      ...formState,
      [code]: value,
    }

    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }

      // Проверка для строки перед использованием `trim()`
      const isFieldFilled = e.target.type === 'checkbox' ? value : (typeof value === 'string' && value.trim() !== '')

      if (isFieldFilled) {
        updatedErrors.required[code].hasError = false
      } else {
        updatedErrors.required[code].hasError = true
      }

      return updatedErrors
    })
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const handleButtonConfirmClick = () => {
    setActionType('confirm');
    if (clientValidation()) {
      const sms_code = formState.sms_code
      token && dispatch(fetchConfirmPhone({ token, sms_code }))
    } else {
      console.error('Confirm phone: Validation failed')
    }
  }

  const handleButtonResendClick = () => {
    setActionType('resend');
    if (clientValidation()) {
      token && dispatch(fetchSendPhoneConfirmation({ token }))
    } else {
      console.error('Resend code: Validation failed')
    }
  }

  // const handleButtonClickClear = () => {
  //   clearStates()
  //   clearValidationStates()
  //   setFormState(initialState)
  // }

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  //   if (!token) {
  //     console.error('Token is missing')
  //     return // Прекращаем выполнение, если токен отсутствует
  //   }

  //   if (clientValidation()) {
  //     // dispatch(fetchUpdatePassword({token, params: formState})) // Передаем токен как строку
  //   } else {
  //     console.error('Validation failed')
  //   }
  // }


  const formatPlaceholder = (fieldName: string): string => {
    return fieldName
      .replace(/_/g, ' ') // Заменяем `_` на пробел
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Делаем первую букву каждого слова заглавной
  }

  return (

    <>
      <div className={clsx(styles.security)}>
        <div className={styles.title}>Phone confirmation</div>
        <p>Please, enter the SMS code to confirm your phone number</p>
        <form
          // onSubmit={handleSubmit} 
          ref={formRef} className={styles.form}>
          {
            (fields as Array<keyof SecurityPhoneVerification>).map((nameField, indexField) => (
              <div key={indexField}>
                <BrightInput
                  id={nameField}
                  name={nameField}
                  value={formState[nameField] || ''}
                  onChange={(e) => handleInputChange(nameField, e)}
                  label={formatPlaceholder(nameField)}
                  error={
                    (clientErrors.required[nameField].hasError ? clientErrors.required[nameField].message : null) ||
                    (serverValidationErrors[nameField])
                  }
                  variation={'profile'}
                  className={styles.input}
                  type={'text'}
                />
              </div>

            ))
          }
          {
            sendPhoneConfirmationSuccess
            &&
            <>
              <p>{sendPhoneConfirmationMessages[0]}</p>
              <p>You may request new code in 00:00</p>
            </>
          }

            {actionType === 'resend' &&
            <>
              <ButtonStyled
                className="mt-4"
                variant='accent'
                isWidth100={true}
                onClick={() => handleButtonResendClick()}
                type="button"
              // disabled={!isEditing || updatePasswordLoading}
              >
                {sendPhoneConfirmationLoading ? 'Loading...' : 'Send code'}
              </ButtonStyled>
            </>
          }

          {actionType === 'confirm' &&
            <>
              <ButtonStyled
                isWidth100={true}
                onClick={() => handleButtonConfirmClick()}
                type="button"
              // disabled={!isEditing || updatePasswordLoading}
              >
                {confirmPhoneLoading ? 'Loading...' : 'Confirm'}
              </ButtonStyled>


              <ButtonStyled
                className="mt-4"
                variant='accent'
                isWidth100={true}
                onClick={() => handleButtonResendClick()}
                type="submit"
              // disabled={!isEditing || updatePasswordLoading}
              >
                {sendPhoneConfirmationLoading ? 'Loading...' : 'Resend code'}
              </ButtonStyled>
            </>
          }



        </form>

        {confirmPhoneSuccess && (
          <Alert><strong>Success!</strong> Phone confirmed successfully!</Alert>
        )}

        {showErrors && sendPhoneConfirmationErrorArray && sendPhoneConfirmationErrorArray.length > 0 && (
          <Alert onClose={handleErrorClose} type="error">
            {sendPhoneConfirmationErrorArray.map((error, index) => {
              return (<p key={index}>{error}</p>)
            })}
          </Alert>
        )}

        {showErrors && confirmPhoneErrorArray && confirmPhoneErrorArray.length > 0 && (
          <Alert onClose={handleErrorClose} type="error">
            {confirmPhoneErrorArray.map((error, index) => {
              return (<p key={index}>{error}</p>)
            })}
          </Alert>
        )}
      </div >

    </>
  )
}

export default SecurityPhoneVerification
