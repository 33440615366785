import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch, useAppSelector} from "../../../../hooks"
import Alert from '../../../layouts/ui/alert/Alert'
import {fetchGetPersonalInfo} from 'src/redux/actions/player/getPersonalInfoActions'
import {fetchUpdatePersonalInfo} from 'src/redux/actions/player/updatePersonalInfoActions'
import {
  selectUpdatePersonalInfoError,
  selectUpdatePersonalInfoErrorArray,
  selectUpdatePersonalInfoLoading,
  selectUpdatePersonalInfoSuccess,
  selectUpdatePersonalInfo,
  selectUpdatePersonalInfoMessages
} from 'src/redux/selectors/player/updatePersonalInfoSelectors'
import {
  selectGetPersonalInfo,
  selectGetPersonalInfoError,
  selectGetPersonalInfoLoading
} from 'src/redux/selectors/player/getPersonalInfoSelectors'
import {
  selectIsAuthenticated
} from "../../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../../src/redux/slices/site/loginSlice"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './Personal.module.scss'
import {BrightInput} from "../../../../common/BrightInput/BrightInput"
import clsx from 'clsx'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
import {interfaceSelector, setPasswordSuccessModalShow} from "../../../../redux/slices/interfaceSlice"
import Modal from "../../../layouts/ui/modal/Modal"
import {SecuritySuccess} from "../SecurityPasswordSuccess/SecuritySuccess"
import {useTranslation} from "react-i18next";

interface PersonalInfo {
  code: string;
  value: any;
  approve: string;
  name: string;
  id: string;
  flag_editable: string;
}

interface PersonalInfoState {
  [key: string]: any; // Ключи — строки, значения могут быть разными
}


const Personal = () => {
  const dispatch = useAppDispatch()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const getPersonalInfo: PersonalInfo[] = useSelector(selectGetPersonalInfo)

  const updatePersonalInfoMessages = (useSelector(selectUpdatePersonalInfoMessages) as unknown) as string[];
  console.log('getPersonalInfoMessages', updatePersonalInfoMessages)

  const getPersonalInfoLoading = useSelector(selectGetPersonalInfoLoading)
  const getPersonalInfoError = useSelector(selectGetPersonalInfoError)

  const updatePersonalInfoLoading = useSelector(selectUpdatePersonalInfoLoading)
  const updatePersonalInfoError = useSelector(selectUpdatePersonalInfoError)
  const updatePersonalInfoErrorArray = useSelector(selectUpdatePersonalInfoErrorArray)
  const updatePersonalInfoSuccess = useSelector(selectUpdatePersonalInfoSuccess)
  // console.log('updatePersonalInfoSuccess', updatePersonalInfoSuccess)

  const selectUpdatePersonalInfoData = useSelector(selectUpdatePersonalInfo)
  console.log('selectUpdatePersonalInfoData', selectUpdatePersonalInfoData)


  const [formState, setFormState] = useState<PersonalInfoState>({})
  const [initialState, setInitialState] = useState<PersonalInfoState>({}) // Начальные значения из API
  const [isEditing, setIsEditing] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false) // Флаг успешного обновления
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками

  const {successModal} = useAppSelector(interfaceSelector)
  const profileSuccessModalShow = successModal.show
  const {t} = useTranslation()

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchGetPersonalInfo(token))
    }
  }, [dispatch, isAuthenticated, token])

  useEffect(() => {
    console.log('getPersonalInfo', getPersonalInfo)
  }, [getPersonalInfo])

  useEffect(() => {
    const initialFormValues: Record<string, any> = {}
    if (getPersonalInfoError || getPersonalInfo === null) {
      return
    }
    getPersonalInfo.forEach((item: PersonalInfo) => {
      initialFormValues[item.code] = item.value
    })
    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [getPersonalInfo])

  useEffect(() => {
    if (updatePersonalInfoError) {
      setUpdateSuccess(false)
      setShowErrors(true) // раз ошибки есть то разблокируем показ окна алерта
    } else if (updatePersonalInfoSuccess) {
      setUpdateSuccess(true)
      setShowErrors(false)
      dispatch(setPasswordSuccessModalShow({show: true, variant: 'profile'}))
    }
  }, [updatePersonalInfoError, updatePersonalInfoSuccess])


  useEffect(() => {

    setUpdateSuccess(false)
    setShowErrors(false)

  }, [])

  const handleInputChange = (code: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value = e.target.value

    const updatedFormState = {
      ...formState,
      [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
    }

    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setUpdateSuccess(false)
    setShowErrors(false)

    if (!isEditing) {
      return
    }

    if (isAuthenticated && token) {
      // Filter only editable fields from formState
      const filteredFormState = Object.keys(formState)
        // Filter keys that correspond to editable fields in getPersonalInfo
        .filter((key) => {
          const field = getPersonalInfo.find((item) => item.code === key);
          return field && field.flag_editable !== 'NO';
        })
        // Convert the filtered keys into an object with corresponding values from formState
        .reduce((acc, key) => {
          acc[key] = formState[key];
          return acc;
        }, {} as Record<string, any>);

      dispatch(fetchUpdatePersonalInfo(token, filteredFormState));
    }

  }

  const renderInputField = (item: PersonalInfo) => {
    const labelText = item.approve === 'request' ? `${item.name} (update after approve admin)` : item.name

    if (item.code === 'gender') {
      return (
        <div key={item.id}>
          <label htmlFor={item.code} className="form-label"><strong>{labelText}</strong></label>
          <select
            id={item.code}
            className="form-control"
            name={item.code}
            value={formState[item.code] || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(item.code, e)}
            disabled={item.flag_editable === 'NO'}
          >
            <option value="M">{t('Male')}</option>
            <option value="F">{t('Female')}</option>
          </select>
        </div>
      )
    }

    // Для всех остальных полей рендерим текстовое поле
    return (
      <div key={item.id}>
        <label htmlFor={item.code} className={styles.label}>{t(labelText)}</label>
        <BrightInput type="text"
                     id={item.code}
                     name={item.code}
                     value={formState[item.code] || ''}
                     onChange={(e) => handleInputChange(item.code, e)}
                     disabled={item.flag_editable === 'NO'}
                     variation={'profile'}
        />
      </div>
    )
  }


  return (

    <>
      {getPersonalInfoLoading ? (
        <p>Loading...</p>
      ) : getPersonalInfoError ? (
        <p>Error: {getPersonalInfoError}</p>
      ) : getPersonalInfo ? (
        <div className={clsx(stylesCommon.profileRoundedTab, styles.personal)}>
          <form onSubmit={handleSubmit}>
            {/* Рендерим поля динамически */}
            <div className={styles.gridContainer}>
              {getPersonalInfo.map(renderInputField)}
              <div className={styles.buttonMessages}>
                <ButtonStyled type="submit" disabled={!isEditing} variant={'glass'} className={styles.button}>{updatePersonalInfoLoading ? t('Loading...') : t('Save')}</ButtonStyled>
                {showErrors && updatePersonalInfoErrorArray && updatePersonalInfoErrorArray.length > 0 && (

                  <Alert onClose={handleErrorClose} type="error">
                    {updatePersonalInfoErrorArray.map((error: string, index: number) => {
                      return (<p key={index}>{error}</p>)
                    })}
                  </Alert>
                )}
                {/*{updateSuccess && <Alert className={styles.alert}>{updatePersonalInfoMessages.map((message: string, index: number) => {
                  return (
                    <div key={index}>
                      {message}
                    </div>
                  );
                })}</Alert>

                  }*/}
              </div>
            </div>

            {/* Кнопка будет disabled, если данные не изменились */}

          </form>

          {/* Сообщение об успешном обновлении */}
        </div>
      ) : (<p>No data...</p>)}
      <Modal
        show={profileSuccessModalShow}
        onClose={() => dispatch(setPasswordSuccessModalShow({show: false, variant: 'profile'}))}
        body={<SecuritySuccess />}
        variant={'success'}
      />
    </>
  )
}

export default Personal

/*todo: need to style alert & change password hardcoded message*/