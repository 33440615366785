import React, {useEffect, useMemo, useState} from 'react'
import {Box} from '@mui/material'
import DepositWithdrawalTable from '../DepositWithdrawalTable/DepositWithdrawalTable'
import styles from './DepositWithdrawalHandler.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {DEFAULT_PAGINATION_VALUE, SIBLING_COUNT, TRANSACTIONS_PAGINATION_VALUES} from 'src/common/constants'
import {Pagination} from '../../../../../common/Pagination'
import {TransactionsState} from '../../../../../redux/slices/transactionsSlice'
import {Transaction} from '../../../../../api/types'
import {RootState} from '../../../../../redux/store'
import {useSelector} from "react-redux"
import {selectToken} from "../../../../../redux/slices/site/loginSlice"
import clsx from 'clsx'
import stylesCommonTables from "../TablesCommonStyles.module.scss"
import {TitleAndFilterButton} from "../TitleAndFilterButton/TitleAndFilterButton"
import {useFilters} from "../../../../../common/hooks/useFilters"
import {useTranslation} from "react-i18next";
import {useGetTransactionHistoryQuery} from "../../../../../api/api"
import {useLocalPagination} from 'src/common/hooks/useLocalPagination'
import {extractUniqueOptions} from "../utils"

const initialFilters = {
  uidFilter: '',
  dateFilter: '',
  amountFilter: '',
  currencyFilter: '',
  statusFilter: ''
}

export const DepositWithdrawalHandler: React.FC<TransactionsComponentProps> = ({direction, selector}) => {

  const token = useSelector(selectToken)
  const {t} = useTranslation()

  const { filters, draftFilters, handleDraftFilterChange, handleApplyFilters, handleClearFilters } = useFilters<typeof initialFilters>(initialFilters)

  // State for sorting
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof Transaction>('date')

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1)
    }
  }, [filters])

  // State for show/hide filters
  const [filtersShown, setFiltersShown] = useState(false)

  const { data: initialTransactionInfo } = useGetTransactionHistoryQuery(
    { token: token ? token : '', direction, page: 1, pageSize: 1 },
    { skip: !token }
  )

  const totalCount = initialTransactionInfo?.pagination?.total_count

  const { data: transactions, isError, isLoading } = useGetTransactionHistoryQuery(
    { token: token ? token : '', direction, page: 1, pageSize: totalCount ?? DEFAULT_PAGINATION_VALUE },
    { pollingInterval: 30000, skip: !totalCount }
  )

  // Local data filtering
  const filteredData = useMemo(() => {
    if (!transactions?.data.length) return []

    return transactions.data.filter((transaction: Transaction) => {
      const matchesUid = !filters.uidFilter || transaction.uid.includes(filters.uidFilter)
      const matchesDate = !filters.dateFilter || new Date(transaction.date * 1000).toLocaleDateString().includes(filters.dateFilter)
      const matchesAmount = !filters.amountFilter || transaction.amount.toString().includes(filters.amountFilter)
      const matchesCurrency = !filters.currencyFilter || transaction.currency_code.includes(filters.currencyFilter)
      const matchesStatus = !filters.statusFilter || transaction.status.includes(filters.statusFilter)

      return matchesUid && matchesDate && matchesAmount && matchesCurrency && matchesStatus
    })
  }, [transactions, filters])

  const {
    paginatedData,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage
  } = useLocalPagination({
    data: filteredData,
    defaultPageSize: DEFAULT_PAGINATION_VALUE
  });

  const statusOptions = transactions
    ? extractUniqueOptions(transactions.data, 'status')
    : []

  if (isLoading) return <p>Loading...</p>
  if (isError) return <p>Error loading transactions.</p>
  if (!transactions || !transactions.data.length) return <div>No transactions available</div>

  return (
    <div>
      <Box className={clsx(styles.transactionsContainer, stylesCommon.profileRoundedTabNoPadding)}>
        <TitleAndFilterButton title={direction === 'deposit' ? t('Deposits') : t('Withdrawals')} filterShown={filtersShown} onFilterShown={setFiltersShown}/>
        <div className={styles.tableContainer}>
          <DepositWithdrawalTable
            data={paginatedData} // Display only filtered data
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            filtersState={{
              filters,
              draftFilters,
              handleDraftFilterChange,
              handleApplyFilters,
              handleClearFilters
            }} // Passing filters as a single state
            isFilterShown={filtersShown}
            statusOptions={statusOptions}
          />
        </div>
      </Box>
      <div className={stylesCommonTables.paginationTableContainer}>
        {
          transactions && <Pagination
                variant={'transactions'}
                onPageChange={(value) => setCurrentPage(Number(value))}
                totalCount={filteredData.length}
                currentPage={currentPage}
                pageSize={itemsPerPage}
                siblingCount={SIBLING_COUNT}
                selectSettings={{
                  value: itemsPerPage.toString(),
                  onChangeOption: (value: string) => {
                    setItemsPerPage(Number(value)) // Change the number of items per page
                    setCurrentPage(1) // Reset current page to 1
                  },
                  arr: TRANSACTIONS_PAGINATION_VALUES.map((val) => val.toString())
                }}
            />

        }
      </div>

    </div>

  )
}

type TransactionsComponentProps = {
  direction: 'deposit' | 'withdrawal';
  selector: (state: RootState) => TransactionsState['deposit'] | TransactionsState['withdrawal'];
};
