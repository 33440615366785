import React, { useState, useEffect } from "react"
import styles from './ExperienceLoyalty.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {
  ExperienceLoyaltyElement,
  ExperienceLoyaltyElementProps
} from "./ExperienceLoyaltyElement/ExperienceLoyaltyElement"
import clsx from 'clsx'

import {
  fetchMyProgress,
  connectWebSocketMyProgress,
  selectMyProgressSliceData,
  selectMyProgressSliceMessages,
  selectMyProgressSliceError,
  selectMyProgressSliceSuccess,
  selectMyProgressSliceLoading,
  selectMyProgressSliceErrorArray,
} from "src/redux/slices/Account/myProgressSlice"

import {
  selectIsAuthenticated
} from "../../../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../../../src/redux/slices/site/loginSlice"

import { useAppDispatch, useAppSelector } from "src/hooks"
import { useSelector } from 'react-redux'

// const progress: Array<ExperienceLoyaltyElementProps> = [
//   { variant: 'Experience', lvl: 'X', points: 'X/XX' },
//   { variant: 'Loyalty', lvl: 'X', points: 'X/XX' },
// ]

export const ExperienceLoyalty = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const myProgressSliceData = useSelector(selectMyProgressSliceData)
  const myProgressSliceError = useSelector(selectMyProgressSliceError)
  const myProgressSliceSuccess = useSelector(selectMyProgressSliceSuccess)
  const myProgressSliceLoading = useSelector(selectMyProgressSliceLoading)
  const myProgressSliceErrorArray = useSelector(selectMyProgressSliceErrorArray)

  const [defaultProgress, setDefaultProgress] = useState<ExperienceLoyaltyElementProps[]>([
    { variant: 'Experience', lvl: 'X', points: 'X/XX' },
    { variant: 'Loyalty', lvl: 'X', points: 'X/XX' },
  ]);
  const [progress, setProgress] = useState<ExperienceLoyaltyElementProps[] | null>(null);

  // useEffect(() => {
  //   if (isAuthenticated && token) {
  //     dispatch(fetchMyProgress({ token }));
  //   }

  //   console.log('ExperienceLoyalty fetchMyProgress');
  // }, [dispatch, token])

  // С использованием сокетов
  useEffect(() => {
    if (isAuthenticated && token) {
      // dispatch(fetchMyProgress({ token }));
      const closeWebSocketMyProgress = dispatch(connectWebSocketMyProgress(token));

      return () => {
        closeWebSocketMyProgress();
      };
    }
  }, [dispatch, token, isAuthenticated]);

  useEffect(() => {
    console.log('ExperienceLoyalty myProgressSliceData', myProgressSliceData);
    if (myProgressSliceSuccess) {
      const progress: ExperienceLoyaltyElementProps[] = [
        { variant: 'Experience', lvl: myProgressSliceData.experience.current_level, points: myProgressSliceData.experience.current_points },
        { variant: 'Loyalty', lvl: myProgressSliceData.loyalty.current_level, points: myProgressSliceData.loyalty.current_points },
      ]

      setProgress(progress);
    }
  }, [myProgressSliceData, myProgressSliceSuccess]);

  return (
    <div className={clsx(stylesCommon.accountElement, styles.experienceLoyalty)}>
      {
        progress ?
          progress.map((element: ExperienceLoyaltyElementProps, index: number) => <ExperienceLoyaltyElement
            key={index} variant={element.variant} lvl={element.lvl} points={element.points} />)

          :

          defaultProgress.map((element: ExperienceLoyaltyElementProps, index: number) => <ExperienceLoyaltyElement
          key={index} variant={element.variant} lvl={element.lvl} points={element.points} />)
      }
    </div>
  )
}