import React from "react"
import {Balance} from "src/common/types"
import {CheckedStates} from "../Accounts"
import styles from './AccountsElement.module.scss'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
import clsx from 'clsx'
import {CurrencySelector} from "../../../../common/CurrencySelectors"
import {useTranslation} from "react-i18next"

type Props = {
  balance: Balance
  checkedStates: CheckedStates
  handleCheckboxChange: (id: number) => void
  onClickArrow: (e: React.MouseEvent<HTMLElement>, balance: Balance) => void
  onDeposit: (e: React.MouseEvent<HTMLButtonElement>, balance: Balance) => void
  isLoadingAccountSwitch: boolean
  isLoadingSocket: boolean
}

export const AccountsElement = ({
                                  balance,
                                  onClickArrow,
                                  isLoadingAccountSwitch,
                                  isLoadingSocket,
                                  onDeposit
                                }: Props) => {

  const {t} = useTranslation()


  return (
    <div className={
      clsx(styles.accountsElement, balance.flag_main === 'YES' && styles.accountsElementActive)
    } onClick={(e) => onClickArrow(e, balance)}>
      <div className={styles.mainContent}>
        <div className={styles.currency_header}>
          <div className={styles.currencyContainer}>
            <CurrencySelector currencyCode={balance.currency_code} size={24}/>
            <div className={styles.currency_code}>{balance.currency_code}</div>
          </div>
          {/*{balance.currency_code === 'BTC' && <img src={trash_01} alt="trash_01"/> } don`t delete if fully, because maybe later will be condition to show */}
        </div>
        <div className={styles.moneyContainer}>
          <div className={styles.moneyBlock}>
            <div className={styles.moneyType}>{t('Real')}</div>
            <div className={styles.currencyContainer}>
              <CurrencySelector currencyCode={balance.currency_code} size={16}/>
              <div className={styles.amount}>{balance.amount}</div>
            </div>
          </div>
          <div className={styles.moneyBlock}>
            <div className={styles.moneyType}>{t('Bonus')}</div>
            <div className={styles.currencyContainer}>
              <CurrencySelector currencyCode={balance.currency_code} size={16}/>
              <div className={styles.amount}>{balance.amount_bonus}</div>
            </div>
          </div>
        </div>

      </div>
      {balance.flag_main === 'YES' &&
          <ButtonStyled variant={balance.flag_main === 'YES' ? 'primary' : 'glass'} onClick={(e) => {
            e.stopPropagation()
            onDeposit(e, balance)
          }}>Deposit</ButtonStyled>
      }

    </div>
  )
}