import React from 'react'
import {BrightInput} from "../../../../common/BrightInput/BrightInput"
import ErrorMessage from "./ErrorMessage/ErrorMessage"

type TextInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
  activeIcon?: string;
  passiveIcon?: string;
};


const TextInput: React.FC<TextInputProps> = ({
                                               id,
                                               name,
                                               value = '',
                                               onChange,
                                               required = false,
                                               placeholder,
                                               error,
                                               activeIcon,
                                               passiveIcon
                                             }) => {
  return (
    <div>
      <BrightInput
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        data-error-required={`${placeholder} is required`}
        variation={'auth'}
        icon={passiveIcon}
        activeIcon={activeIcon}
      />
      {error && <ErrorMessage error={error}/>}
    </div>
  )
}

export default TextInput
