// sendPhoneConfirmationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type SendPhoneConfirmationResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type SendPhoneConfirmationError = {
  error: any;
  message: string;
};

interface SendPhoneConfirmationState {
  success: boolean;
  loading: boolean;
  error: any | null; // Уточните тип ошибки, если возможно
  data: any[]; // Уточните тип массива, если известно
  messages: string | null;
}


const initialState: SendPhoneConfirmationState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchSendPhoneConfirmation
export interface SendPhoneConfirmationParams {
  token: string;
}

export const fetchSendPhoneConfirmation = createAsyncThunk<
  SendPhoneConfirmationResponse,
  SendPhoneConfirmationParams,
  { rejectValue: SendPhoneConfirmationError }
>(
  'SendPhoneConfirmation/fetchSendPhoneConfirmation',
  async ({ token }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchSendPhoneConfirmation:', `${config.serverUrl}/player/send-phone-confirmation`, { token });

      const response = await axios.post<SendPhoneConfirmationResponse>(
        `${config.serverUrl}/player/send-phone-confirmation`,
        {},
        {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
        }
      );
      console.log('Attempting to fetchSendPhoneConfirmation response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error Send Phone Confirmation:', error);

      if (axios.isAxiosError(error)) {
        if (!error.response) {
          return rejectWithValue({
            error: [ error.code ||  'Network error'],
            message: 'Network error. Please check your connection.',
          });
        }
        return rejectWithValue({
          error: error.response.data.errors || error.response.data,
          message: error.response.data.message || error.response.data.message || 'Failed to Send Phone Confirmation.',
        });
      }

      return rejectWithValue({
        error: [ 'Unknown error' ],
        message: 'An unknown error occurred.',
      });
    }
  }
);



const SendPhoneConfirmationSlice = createSlice({
  name: 'SendPhoneConfirmation',
  initialState,
  reducers: {
    resetStateSendPhoneConfirmation: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSendPhoneConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchSendPhoneConfirmation.fulfilled, (state, action: PayloadAction<SendPhoneConfirmationResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchSendPhoneConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateSendPhoneConfirmation } = SendPhoneConfirmationSlice.actions;

export const SendPhoneConfirmationReducer = SendPhoneConfirmationSlice.reducer;



// ---------
// Selectors
// ---------
export const selectSendPhoneConfirmationLoading = (state: RootState) => state.SendPhoneConfirmationReducer.loading;
export const selectSendPhoneConfirmationSuccess = (state: RootState) => state.SendPhoneConfirmationReducer.success;
export const selectSendPhoneConfirmationError = (state: RootState) => state.SendPhoneConfirmationReducer.error;
export const selectSendPhoneConfirmationMessages = (state: RootState) => state.SendPhoneConfirmationReducer.messages;
export const selectSendPhoneConfirmationData = (state: RootState) => state.SendPhoneConfirmationReducer.data;

export const selectSendPhoneConfirmationErrorString = (state: RootState): string | null => {
  const error = state.SendPhoneConfirmationReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectSendPhoneConfirmationMessagesString = (state: RootState): string | null => {
  const messages = state.SendPhoneConfirmationReducer.messages;

  if (Array.isArray(messages)) {
    return messages.join('\n');
  }

  if (typeof messages === 'object' && messages !== null) {
    return Object.entries(messages)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return messages;
};

export const selectSendPhoneConfirmationErrorArray = (state: RootState): string[] | null => {
  const error = state.SendPhoneConfirmationReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
