import currency_btc from "src/assets/images/icons/headerCurrencies/currency_bitcoin.svg"
import currency_euro from "src/assets/images/icons/headerCurrencies/currency_euro.svg"
import currency_dollar from "src/assets/images/icons/headerCurrencies/currency_dollar.svg"
import React from "react"

// old version of CurrencySelector for currencies, left here for compatibility
export const currencySelectors = (currency_code: string) => {
  switch (currency_code) {
    case 'BTC':
      return <img src={currency_btc} alt={currency_code}/>
    case 'EUR':
      return <img src={currency_euro} alt={currency_code}/>
    case 'USD':
      return <img src={currency_dollar} alt={currency_code}/>
    default:
      return <div>{currency_code}</div>
  }
}


type CurrencySelectorProps = {
  currencyCode: string
  size?: 24 | 20 | 18 | 16
}

export const CurrencySelector: React.FC<CurrencySelectorProps> = ({ currencyCode, size = 18 }) => (
  <img
    src={`/images/icons/currencies/${currencyCode.toLowerCase()}.svg`}
    style={{ width: `${size}px`, height: `${size}px` }}
    alt={`${currencyCode} currency`}
  />
)