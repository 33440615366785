import React from 'react'
import styles from "./GameCard.module.scss"
import config from "../../config"
import gameDummyImg from "../../assets/images/game_dummy.png"
import star_fill from "src/assets/images/icons/star_fill.svg"
import star_01 from "src/assets/images/icons/star_01.svg"
import {ButtonStyled} from "../ButtonStyled/ButtonStyled"
import {GameInWidget} from "../../api/types"
import {useTranslation} from "react-i18next"
import clsx from "clsx"

type Props = {
  game: GameInWidget
  isAuthenticated: boolean
  onStarClick: (gameId: number) => void
  variant: 'GameWidget' | 'GameList'
}

export const GameCard = ({game, isAuthenticated, onStarClick, variant}: Props) => {
  const {t} = useTranslation()

  return (
    <div key={game.game_id}
         className={clsx(styles.gameCard, variant === 'GameList' ? styles.variantGameList : styles.variantGameWidget)}>
      {/* Game image */}
      <img
        src={game.image_path_album
          ? `${config.fileUrls.game}${game.image_path_portrait}`
          : gameDummyImg}
        alt={game.name}
        className={styles.gameImage}
      />

      <div className={styles.hoverContainer}>
        <div className={styles.gameTitleContainer}>
          <div className={styles.gameName}>{game.name}</div>
          {
            isAuthenticated &&
              <>
                {
                  game.isFavorite
                    ?
                    <img src={star_fill} alt="star_fill" className={styles.favoriteIcon}
                         onClick={() => onStarClick(game.game_id)}/>
                    : <img src={star_01} alt="star_01" className={styles.favoriteIcon}
                           onClick={() => onStarClick(game.game_id)}/>
                }
              </>
          }
        </div>
        <div className={styles.buttonGroup}>
          <ButtonStyled to={`/games/${game.slug}`} variant={'primary'} height={'48px'}>{t('Play real')}</ButtonStyled>
          <ButtonStyled to={`/games/${game.slug}?mode=demo`} variant={'glass'}
                        height={'48px'}>{t('Play demo')}</ButtonStyled>
        </div>
        <p className={styles.providerName}>{game.provider_name}</p>
      </div>
    </div>
  )
}