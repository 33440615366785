import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';

// Async Thunk для получения IP
export const fetchIp = createAsyncThunk('ip/fetchIp', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
    console.info('fetchIp fetching IP:', response.data.ip);
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP:', error);
    return rejectWithValue('Failed to fetch IP');
  }
});

// Слайс для хранения IP
const ipSlice = createSlice({
  name: 'ip',
  initialState: {
    ip: null as string | null,
    loading: false,
    error: null as string | null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIp.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchIp.fulfilled, (state, action) => {
        state.loading = false;
        state.ip = action.payload;
        state.success = true;
      })
      .addCase(fetchIp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.success = false;
      });
  },
});

export default ipSlice.reducer;

export const selectIp = (state: RootState) => state.ipReducer.ip;
export const selectIpLoading = (state: RootState) => state.ipReducer.loading;
export const selectIpError = (state: RootState) => state.ipReducer.error;
export const selectIpSuccess = (state: RootState) => state.ipReducer.success;