import React from 'react'
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {getComparator} from '../utils'
import {Transaction} from "../../../../../api/types"
import commonTableStyles from '../TablesCommonStyles.module.scss'
import tablesCommonStyles from '../TablesCommonStyles.module.scss'
import {SortableTableCell} from "../SortableTableCell"
import {useGetCurrencySelectOptions} from "../useGetCurrencySelectOptions"
import {STATUS_OPTIONS} from "../TablesConstants"
import {BrightCopyToClipboard} from "../../../../../common/BrightCopyToClipboard/BrightCopyToClipboard"
import {FiltersForTable} from "../FiltersForTable/FiltersForTable"

const DepositWithdrawalTable: React.FC<SortableTableProps> = ({data, order, orderBy, setOrder, setOrderBy, filtersState, isFilterShown = false, statusOptions}) => {
  const {filters, handleDraftFilterChange, handleClearFilters, handleApplyFilters, draftFilters} = filtersState

  const {currencySelectOptions} = useGetCurrencySelectOptions()

  const handleRequestSort = (property: keyof Transaction) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = [...data].sort(getComparator<Transaction,  keyof Transaction>(order, orderBy))

  return (
    <TableContainer>
      {isFilterShown && filters && (
        <FiltersForTable
          filters={filters}
          draftFilters={draftFilters}
          onFilterChange={(key, value) => handleDraftFilterChange(key as keyof FiltersState, value)}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          filterOptions={{
            currencyFilter: currencySelectOptions,
            statusFilter: statusOptions ?? STATUS_OPTIONS
          }}
          filterLabels={{
            uidFilter: "UID",
            dateFilter: "Date",
            amountFilter: "Amount",
            currencyFilter: "Currency",
            statusFilter: "Status"
          }}
        />
      )}
      <Table sx={
        {minWidth: 750,
        "& .MuiTableCell-root": { borderBottom: "none" }
      }} aria-labelledby="tableTitle" >
        <TableHead className={commonTableStyles.backgroundSurfaceElevation2}>
          <TableRow >
            <SortableTableCell
              label="UID"
              orderBy="uid"
              currentOrderBy={orderBy}
              order={order}
              onSort={() => handleRequestSort('uid')}
            />
            <SortableTableCell
              label="Date"
              orderBy="date"
              currentOrderBy={orderBy}
              order={order}
              onSort={() => handleRequestSort('date')}
            />
            <SortableTableCell
              label="Amount"
              orderBy="amount"
              currentOrderBy={orderBy}
              order={order}
              onSort={() => handleRequestSort('amount')}
            />
            <SortableTableCell
              label="Currency"
              orderBy="currency_title"
              currentOrderBy={orderBy}
              order={order}
              onSort={() => handleRequestSort('currency_code')}
            />
            <SortableTableCell
              label="Status"
              orderBy="status"
              currentOrderBy={orderBy}
              order={order}
              onSort={() => handleRequestSort('status')}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, index) => (
            <TableRow hover key={row.id} className={index % 2 !== 0 ? tablesCommonStyles.backgroundSurfaceElevation2 : undefined}>
              <TableCell>
                <Box className={commonTableStyles.uid}>
                  {row.uid}
                  <BrightCopyToClipboard text={row.uid} variant={'history'}/>
                </Box>
              </TableCell>
              <TableCell>{new Date(row.date * 1000).toLocaleDateString()}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.currency_code}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


type SortableTableProps = {
  data: Transaction[]
  order: 'asc' | 'desc'
  orderBy: keyof Transaction
  setOrder: (value: 'asc' | 'desc') => void
  setOrderBy: (value: keyof Transaction) => void
  filtersState: {
    filters: FiltersState
    draftFilters: FiltersState
    handleDraftFilterChange: (field: keyof FiltersState, value: string) => void
    handleApplyFilters: () => void
    handleClearFilters: () => void
  }
  isFilterShown?: boolean
  statusOptions?: { value: string; label: string }[]
}

type FiltersState = {
  uidFilter: string
  dateFilter: string
  amountFilter: string
  currencyFilter: string
  statusFilter: string
}

export default DepositWithdrawalTable
