import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {
    selectIsAuthenticated
} from "../../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../../src/redux/slices/site/loginSlice"
import Login from '../Login/Login'
import Signup from '../Signup/Signup'
import {RootState} from '../../../../redux/store'
import styles from './AuthPage.module.scss'

const AuthPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'login' | 'signup'>('login');
    const isAuthenticated = useSelector<RootState, boolean>(selectIsAuthenticated); // По умолчанию активен логин
    const navigate = useNavigate();
    const { param } = useParams();

    // Редирект на главную, если пользователь аутентифицирован
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }else{
            if (param) {
                switch (param) {
                    case 'login':
                        setActiveTab('login');
                        break;
                    case 'signup':
                        setActiveTab('signup');
                        break;
                    default:
                        navigate('/404');
                        break;
                }
            }
        }
    }, [isAuthenticated, navigate]);

    const handleButtonActiveTabClick = (tab: 'login' | 'signup') => {
        setActiveTab(tab);
    };

    return (
        
        <div className={styles.authPage}>
            {activeTab === 'login' && <Login handleButtonActiveTabClick={handleButtonActiveTabClick} />}
            {activeTab === 'signup' && <Signup handleButtonActiveTabClick={handleButtonActiveTabClick} />}
        </div>
    );
};

export default AuthPage;
