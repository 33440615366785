import React, {FormEvent, useEffect, useRef, useState} from 'react'
import Alert from '../../../layouts/ui/alert/Alert'
import {useSelector} from 'react-redux'
import axios from 'axios'
import {useAppDispatch} from "../../../../hooks"
import TextInput from './TextInput/TextInput'
import config from '../../../../config.js'
import {selectIsAuthenticated} from "../../../../redux/slices/site/isAuthenticatedSlice"
import {selectToken} from "../../../../redux/slices/site/loginSlice"
import {fetchGetDepositBonusList} from '../../../../redux/actions/playerDeposit/bonusDepositListActions'
import {
  selectGetDepositBonusList,
  selectGetDepositBonusListErrorString,
  selectGetDepositBonusListLoading
} from '../../../../redux/selectors/playerDeposit/bonusDepositListSelectors'
import {Balance, PaymentFields, PaymentMethod} from '../PlayerDeposit'
import clsx from "clsx"

import {
  fetchSetDepositRequest,
  selectSetDepositRequestData,
  selectSetDepositRequestError,
  selectSetDepositRequestErrorArray,
  selectSetDepositRequestLoading,
  selectSetDepositRequestMessages,
  selectSetDepositRequestSuccess,
  SetDepositRequestParams
} from '../../../../redux/slices/setDepositRequestSlice'
import styles from './DepositDetails.module.scss'
import {ButtonStyled} from "../../../../common/ButtonStyled/ButtonStyled"
import {
  BrightCarouselDetachedControl,
  CarouselHandle
} from "../../../../common/BrightCarousel/BrightCarouselDetachedControl"
import {ScrollButton} from "../../../../common/ScrollButton/ScrollButton"
import {CurrencySelector} from "../../../../common/CurrencySelectors"
import {carouselGradient} from "../../../../common/styles/styleConstants"

interface BonusLang {
  id: number;
  id_bonus: number;
  id_lang: number;
  name: string;
  description: string;
  image_path: string | null;
  image_link: string;
}

interface BonusGroupLang {
  id: number;
  id_bonus_group: number;
  id_lang: number;
  title: string;
  description: string;
}

interface BonusGroup {
  id: number;
  name: string | null;
  bonusGroupLangs: BonusGroupLang[];
}

interface BonusEvent {
  id: number;
  name: string;
}

interface Currency {
  id: number;
  type: string;
  title: string;
  code: string;
  symbol: string | null;
  flag_active: string;
  flag_active_players: string;
  pos: number;
}

interface BonusBudget {
  id: number;
  amount: number;
  flag_unlim: string;
  currency: Currency;
}

interface Bonus {
  id: string;
  subscription_availability: boolean;
  id_bonus_group: number;
  id_bonus_type: number;
  id_bonus_event: number;
  id_main_currency: number;
  external_id: string | null;
  status: string;
  flag_use_converter: string;
  flag_active: string;
  flag_all_currencies: string;
  flag_visible_player: string;
  flag_visible_guest: string;
  seo_url: string;
  priority: number | null;
  image_path: string | null;
  image_link: string;
  subscription_type: string;
  repeat_type: string;
  repeat_count: number;
  repeat_interval_type: string;
  repeat_interval_value: number;
  flag_repeat_total: string;
  accrual_type: string;
  flag_distribution_strict: string;
  distribution_bet_real: number;
  distribution_bet_bonus: number;
  distribution_win_real: number;
  distribution_win_bonus: number;
  wagering_type: string;
  freespin_wagering_type: string;
  freespin_wagering_value: number;
  restriction_days: string;
  flag_restriction_unsubscribe: string;
  expiration_type: string;
  expiration_relative_type: string;
  expiration_relative_duration: number;
  expiration_action: string;
  expiration_fixed_datetime: string | null;
  flag_cancellation: string;
  system_cancellation_action: string;
  player_cancellation_action: string;
  action_reaching_threshold: string;
  withdrawal_restriction: string;
  id_loyalty_min_level: number | null;
  id_loyalty_max_level: number | null;
  id_experience_min_level: number | null;
  id_experience_max_level: number | null;
  created_at: number;
  updated_at: number;
  bonusLangs: BonusLang[];
  bonusGroup: BonusGroup;
  bonusEvent: BonusEvent;
  bonusEventDeposits: any[];
  bonusBudgets: BonusBudget[];
  bonusFreespins: any[];
}

interface DepositDetailsProps {
  selectedCurrency: Balance | null;
  selectedPaymentMethod: PaymentMethod | null;
  handleSubmit: (e: FormEvent<Element>, fieldsToSave: PaymentFields) => void;
  onPaymentSuccess: () => void;
}


const imgDirPayment = config.fileUrls.payment
const bonusImgDir = config.fileUrls.bonusFolder
const dafaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid"

const DepositDetails: React.FC<DepositDetailsProps> = ({selectedCurrency, selectedPaymentMethod, handleSubmit, onPaymentSuccess}) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  const setDepositRequestLoading = useSelector(selectSetDepositRequestLoading)
  const setDepositRequestSuccess = useSelector(selectSetDepositRequestSuccess)
  const setDepositRequestError = useSelector(selectSetDepositRequestError)
  const setDepositRequestMessages = useSelector(selectSetDepositRequestMessages)
  const setDepositRequestData = useSelector(selectSetDepositRequestData)
  const setDepositRequestErrorArray = useSelector(selectSetDepositRequestErrorArray)

  const [formState, setFormState] = useState<{ [key: string]: any }>({})
  const [clientErrors, setClientErrors] = useState<{ required: { [key: string]: string }, range: { [key: string]: string } }>({required: {}, range: {}})
  // const [bonusStatuses, setBonusStatuses] = useState<{ [key: string]: boolean }>({});
  // const [changedBonuses, setChangedBonuses] = useState<string[]>([]);
  const [checkedBonus, setCheckedBonus] = useState<string>("")
  const [ip, setIp] = useState<string>('')
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками
  const formRef = useRef<HTMLFormElement>(null)

  const getDepositBonusList = useSelector(selectGetDepositBonusList)
  const getDepositBonusListLoading = useSelector(selectGetDepositBonusListLoading)
  const getDepositBonusListErrorString = useSelector(selectGetDepositBonusListErrorString)

  useEffect(() => {
    const eventIdDeposit = 2
    if (isAuthenticated && token) {
      dispatch(fetchGetDepositBonusList(token, eventIdDeposit))  // Вызов экшена для получения данных
    }

    console.log('DepositDetails: fetchGetDepositBonusList')
    console.log('paytest DepositDetails: selectedPaymentMethod', selectedPaymentMethod)
  }, [dispatch, token, isAuthenticated, selectedPaymentMethod])

  // Array of refs for each slider
  const carouselRef = useRef<CarouselHandle | null>(null)

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }

    fetchIp()
  }, [])

  // useEffect(() => {
  //     console.log('DepositDetails: getDepositBonusList:', getDepositBonusList);
  // }, [getDepositBonusList]);

  // Отправка платежа. Редирект на урл с апи.
  useEffect(() => {
    if (setDepositRequestSuccess) {
      onPaymentSuccess();
      window.location.href = setDepositRequestData.payment_url
    } else if (setDepositRequestError) {
      setShowErrors(true)
    }

    console.log('component fetchGetPlayerBalances')
  }, [dispatch, setDepositRequestSuccess, setDepositRequestData, setDepositRequestError, setDepositRequestErrorArray])

  // Очистка стейта
  useEffect(() => {
    setShowErrors(false)
  }, [])

  // Только один или ни одного бонуса можно выбрать
  const toggleBonusStatus = (bonusId: string) => {
    checkedBonus === bonusId ? setCheckedBonus("") : setCheckedBonus(bonusId)
  }

  const clientValidation = () => {
    const formElements = formRef.current!.elements

    const newErrors = {required: {} as { [key: string]: string }, range: {} as { [key: string]: string }}
    let formIsValid = true

    Array.from(formElements).forEach(field => {
      const inputField = field as HTMLInputElement
      const isFieldEmpty = inputField.type === 'checkbox' ? !inputField.checked : !inputField.value.trim()
      if (inputField.required && isFieldEmpty) {
        formIsValid = false
        const errorMessage = inputField.getAttribute('data-error-required') || 'This field is required.'
        newErrors.required[inputField.name] = errorMessage
      }

      // range validation
      if(inputField.name === 'amount'){
        const val = inputField.value.trim();
        const minDep = selectedPaymentMethod?.min_deposit != null ? selectedPaymentMethod?.min_deposit : null;
        const maxDep = selectedPaymentMethod?.max_deposit != null ? selectedPaymentMethod?.max_deposit : null;
        const errorMin = minDep ? Number(val) < Number(minDep) : false; 
        const errorMax = maxDep ? Number(val) > Number(maxDep) : false;
        if(errorMin || errorMax){
          formIsValid = false;
          newErrors.range[inputField.name] = `Amount can be more then ${minDep} and less then ${maxDep}`;
        }
      }
    })
    setClientErrors(newErrors)
    return formIsValid
  }

  const updateClientErrors = (name: string, value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    setClientErrors(prevErrors => {
      const updatedErrors = { ...prevErrors }
      if (value.trim() !== '') {
        delete updatedErrors.required[name]
      } else {
        const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.'
        updatedErrors.required[name] = errorMessage
      }

      // range validation
      if(name === 'amount'){
        const val = value.trim();
        const minDep = selectedPaymentMethod?.min_deposit != null ? selectedPaymentMethod?.min_deposit : null;
        const maxDep = selectedPaymentMethod?.max_deposit != null ? selectedPaymentMethod?.max_deposit : null;
        const errorMin = minDep ? Number(val) < Number(minDep) : false; 
        const errorMax = maxDep ? Number(val) > Number(maxDep) : false;

        maxDep && console.log('refg', errorMin || errorMax);

        if(errorMin || errorMax){
          updatedErrors.range[name] = `Amount can be more then ${minDep} and less then ${maxDep}`;
        }else{
          delete updatedErrors.range[name]
        }
      }

      return updatedErrors
    })
  }

  const renderFormField = (item: any) => {
    if (item.flag_display === "NO") return null

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(item.name_tech, e)

    const commonProps = {
      id: item.name_tech,
      name: item.name_tech,
      value: formState[item.name_tech] || '',
      onChange: handleChange,
      required: item.flag_required === "YES",
      placeholder: item.name,
      error: clientErrors.required[item.name_tech]
    }

    return <TextInput {...commonProps} />
  }

  const renderAmountFormField = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange('amount', e)

    const commonProps = {
      id: 'amount',
      name: 'amount',
      value: formState['amount'] || '',
      onChange: handleChange,
      required: true,
      placeholder: 'Enter amount',
      error: clientErrors.required['amount'] || clientErrors.range['amount'],
      hint: `${selectedPaymentMethod?.min_deposit != null ? `Min ${selectedPaymentMethod.min_deposit}` : ''
        }${selectedPaymentMethod?.min_deposit != null && selectedPaymentMethod?.max_deposit != null ? ' - ' : ''
        }${selectedPaymentMethod?.max_deposit != null ? `Max ${selectedPaymentMethod.max_deposit}` : ''
        } ${selectedPaymentMethod?.currency_code || ''}`,
    }

    const buttonData = [
      ...(selectedPaymentMethod?.min_deposit != null
        ? [{ amount: selectedPaymentMethod.min_deposit, label: 'Min' }]
        : []),
      { amount: '50', label: '50+' },
      { amount: '100', label: '100+' },
      { amount: '200', label: '200+' },
      ...(selectedPaymentMethod?.max_deposit != null
        ? [{ amount: selectedPaymentMethod.max_deposit, label: 'Max' }]
        : []),
    ]

    const buttonsGroup = () => (
      <div className={`${styles.amountButtonsGroup} btn-group d-flex`} role="group" aria-label="Basic example">
        {buttonData.map((button, index) => (
          <ButtonStyled
            variant={'glass'}
            key={index}
            onClick={(e) => handleButtonAmountClick('amount', button.amount, e)}
            className={styles.amountButton}
          >
            {button.label}
          </ButtonStyled>
        ))}
      </div>
    )

    return (
      <>
        <TextInput {...commonProps} currency_code={selectedCurrency?.currency_code} />
        {buttonsGroup()}
      </>
    )
  }

  const handleInputChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setFormState(prevState => ({ ...prevState, [name]: value }))
    updateClientErrors(name, value.toString(), e)
  }

  const handleButtonAmountClick = (name: string, value: string | number, e: React.MouseEvent<HTMLButtonElement>) => {
    setFormState(prevState => ({ ...prevState, [name]: value }))
    updateClientErrors(name, value.toString(), e as unknown as React.ChangeEvent<HTMLInputElement>)
  }

  const handleButtonDepositClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    clientValidation() // нужно для того, чтобі сробатывал кастомный стиль валидации
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (clientValidation()) {
      if (isAuthenticated && token) {

        const idPaymentPaysystem = selectedPaymentMethod ? selectedPaymentMethod.pay_system_id : ''
        const idPaymentMethod = selectedPaymentMethod ? selectedPaymentMethod.id : ''
        const idCurrency = selectedPaymentMethod ? selectedPaymentMethod.currency_id : ''

        const writeFinanceDetails = () => {
          const keysToRemove = ['amount', 'comment'];

          // const formStateTest = {
          //   amount: '4324',
          //   phone: '341234',
          //   test: 'wwwww'
          // };
          // const newFormState: any = { ...formStateTest };

          const newFormState: any = { ...formState };
          
          keysToRemove.forEach(key => delete newFormState[key]);
          
          // Проверка на пустой объект
          if (Object.keys(newFormState).length === 0) {
            return '';
          }
          
          return JSON.stringify(newFormState);
        };
        

        const fieldsToSave: SetDepositRequestParams = {
          token: token,
          params: {
            amount: formState['amount'],
            id_payment_paysystem: idPaymentPaysystem,
            id_payment_method: idPaymentMethod,
            // id_payment_contract: '1',
            id_currency: idCurrency,
            ip: ip,
            comment: 'test comment',
            finance_details: writeFinanceDetails(),
          }
        }

        if (checkedBonus) {
          fieldsToSave.params.id_bonus = checkedBonus
        }
        // console.log('formState formState', fieldsToSave)
        handleSubmit(e, fieldsToSave)
        dispatch(fetchSetDepositRequest(fieldsToSave))
      }

    } else {
      console.log('Validation failed')
    }
  }

  console.log('getDepositBonusList', getDepositBonusList)


  return (
    <div className={styles.component}>

      {selectedPaymentMethod && (
        <div id="block-paysystem" className={styles.paySystem}>
          <div className={styles.currencyImg}>
            <CurrencySelector currencyCode={selectedPaymentMethod.currency_code} size={24} />
          </div>
          <img
            src={selectedPaymentMethod?.image_path ? `${imgDirPayment}${selectedPaymentMethod?.image_path}` : dafaultImg}
            alt={selectedPaymentMethod?.pay_system_name}
            className={styles.paySystemLogo}
          />
        </div>
      )}

      <div className="mb-2">
        {getDepositBonusListLoading ? (
          <div>Loading...</div>
        ) : getDepositBonusListErrorString ? (
          <div>
            <Alert type="error" message="Error fetching bonus list">
              {getDepositBonusListErrorString}
            </Alert>
          </div>
        ) : (
          <div
            id="block-bonuslist"
            className={styles.carousel}
          >
            <div className={styles.carouselControls}>
              <ScrollButton onClick={() => carouselRef.current?.scroll('left')} direction={'left'} variant={'arrow'} />
              <ScrollButton onClick={() => carouselRef.current?.scroll('right')} direction={'right'} variant={'arrow'} />
            </div>
            <BrightCarouselDetachedControl
              ref={carouselRef}
              array={getDepositBonusList.map((bonus: Bonus, index: number) => (
                <div
                  key={bonus.id}
                  className={clsx(styles.bonusCard, checkedBonus === bonus.id && styles.bonusCardActive)}
                  onClick={() => toggleBonusStatus(bonus.id)}
                  style={{
                    backgroundImage: bonus?.bonusLangs[0].image_path
                        ? `${carouselGradient}, url(${bonusImgDir}${bonus?.bonusLangs[0].image_path})`
                        : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                  <div className={styles.bonusHeader}>
                    {bonus?.bonusLangs[0].name}
                    {/* index: {index + 1} */}
                  </div>
                  <div className={styles.bonusDescription}>
                    {bonus?.bonusLangs[0].description.slice(0, 40)}{bonus?.bonusLangs[0].description.length > 40 && '...'}
                  </div>
                </div>


              ))}
              className={styles.carousel}
            />
          </div>
        )}
      </div>

      <form onSubmit={handleFormSubmit} ref={formRef}>
        {selectedPaymentMethod && selectedPaymentMethod.fields && selectedPaymentMethod.fields.map((item: any) => (

          <React.Fragment key={item.id}>
            {renderFormField(item)}
          </React.Fragment>
        ))}
        {renderAmountFormField()}

        <ButtonStyled
          type="submit"
          onClick={(e) => handleButtonDepositClick(e)}
          disabled={setDepositRequestLoading}
          className={styles.submitButton}
        >
          {setDepositRequestLoading ? 'Processing...' : 'DEPOSIT'}
        </ButtonStyled>
      </form>


      {showErrors && setDepositRequestErrorArray && setDepositRequestErrorArray.length > 0 && (

        <Alert onClose={handleErrorClose} type="error">
          {setDepositRequestErrorArray.map((error, index) => {
            return (<p key={index}>{error}</p>)
          })}
        </Alert>
      )}


    </div>
  )
}

export default DepositDetails