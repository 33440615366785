import {ButtonId, NavigationButtonData} from "../../../../common/types"
import React, {useState} from "react"
import {NavigationButtonsGroup} from "../../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {GamingPad01Icon} from "../../../../assets/images/icons/withColorChange/GamingPad01Icon"
import {CreditDeposit01Icon} from "../../../../assets/images/icons/withColorChange/CreditDeposit01Icon"
import {CreditWithdraw01Icon} from "../../../../assets/images/icons/withColorChange/CreditWithdraw01Icon"
import {Gift01Icon} from "../../../../assets/images/icons/withColorChange/Gift01Icon"
import {SessionsInfo} from "../../Transactions/GameActivity/SessionsInfo/SessionsInfo"
import Deposits from "../../Transactions/Deposits"
import {Withdrawals} from "../../Transactions/Withdrawals"
import BonusHistory from "../../BonusHistory"

export const History = () => {
  const buttonData: Array<NavigationButtonData> = [
    {id: 1, label: 'Game activity', icon: GamingPad01Icon},
    {id: 2, label: 'Deposits', icon: CreditDeposit01Icon},
    {id: 3, label: 'Withdrawals', icon: CreditWithdraw01Icon},
    {id: 4, label: 'Bonus history', icon: Gift01Icon},
  ]

  const [activeButton, setActiveButton] = useState<number>(1)

  const handleButtonClick = (id: ButtonId) => {
    setActiveButton(id as number)
  }

  return (
    <div>
      <NavigationButtonsGroup buttonData={buttonData} activeButton={activeButton}
                              handleButtonClick={handleButtonClick} variant={'profileMain'}/>
      {activeButton === 1 && <SessionsInfo />}
      {activeButton === 2 && <Deposits />}
      {activeButton === 3 &&  <Withdrawals />}
      {activeButton === 4 && <BonusHistory />}
    </div>


  )
}