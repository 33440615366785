import React from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import styles from './ButtonStyled.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"

export type ButtonStyledProps = {
  to?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  children: React.ReactNode
  className?: string
  variant?: 'primary' | 'glass' | 'accent'
  height?: string
  paddingVariant?: '12px 16px' | '14px 18px'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  isWidth100?: boolean
}

export const ButtonStyled = ({
                               to,
                               onClick,
                               children,
                               className = '',
                               variant = 'primary',
                               height = '40px',
                               paddingVariant = '12px 16px',
                               type = 'button',
                               disabled = false,
                               isWidth100 = false,
                             }: ButtonStyledProps) => {
  const buttonClass = clsx(stylesCommon.button, styles.button, className, {
    [styles.primary]: variant === 'primary',
    [styles.glass]: variant === 'glass',
    [styles.accent]: variant === 'accent',
    [styles.width100]: isWidth100,
  })

  const style: React.CSSProperties = {
    height: height,
    padding: paddingVariant,
  }

  if (to) {
    return (
      <Link to={to} className={buttonClass} style={style}>
        {children}
      </Link>
    )
  }

  return (
    <button className={buttonClass} onClick={onClick} style={style} type={type} disabled={disabled}>
      {children}
    </button>
  )
}
