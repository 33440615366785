// logoutSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState, AppDispatch } from '../../store';
import { resetStateLogin } from './loginSlice';
import { resetStateAuthenticated } from './isAuthenticatedSlice';


type LogoutSliceResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type LogoutSliceError = {
  error: any;
  message: string;
};

interface LogoutSliceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: boolean; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: LogoutSliceState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};


// Типизация для fetchLogout
type TokenParam = string;


export const fetchLogout = createAsyncThunk<
  LogoutSliceResponse,
  TokenParam,
  { rejectValue: LogoutSliceError }
>(
  'LogoutSlice/fetchLogout',
  async (token , { dispatch, rejectWithValue }) => {
    try {
      console.log('Attempting to fetchLogout:', `${config.serverUrl}/site/logout`, token );

      const response = await axios.post<LogoutSliceResponse>(
          `${config.serverUrl}/site/logout`,
          {},
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json; charset=UTF-8',
            },
          }
        
      );
      console.log('Attempting to fetchLogout response.data:', response.data);

      // После успешного ответа с сервера
      if (response.data.success) {
        clearAuthState(dispatch as AppDispatch);
      }

      return response.data;
    } catch (error) {
      console.error('Error fetchLogout:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to get Logout .',
        });
      }

      return rejectWithValue({
        error: null,
        message: 'An unknown error occurred.',
      });
    }
  }
);

export const clearAuthState = (dispatch: AppDispatch) => {
  dispatch(resetStateLogin());
  dispatch(resetStateAuthenticated());
};

const LogoutSlice = createSlice({
  name: 'Profile/LogoutSlice',
  initialState,
  reducers: {
    resetStateLogout: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchLogout.fulfilled, (state, action: PayloadAction<LogoutSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateLogout } = LogoutSlice.actions;

export const LogoutSliceReducer = LogoutSlice.reducer;



// ---------
// Selectors
// ---------
export const selectLogoutSliceLoading = (state: RootState) => state.LogoutSliceReducer.loading;
export const selectLogoutSliceSuccess = (state: RootState) => state.LogoutSliceReducer.success;
export const selectLogoutSliceError = (state: RootState) => state.LogoutSliceReducer.error;
export const selectLogoutSliceMessages = (state: RootState) => state.LogoutSliceReducer.messages;
export const selectLogoutSliceData = (state: RootState) => state.LogoutSliceReducer.data;


export const selectLogoutSliceErrorString = (state: RootState): string | null => {
  const error = state.LogoutSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectLogoutSliceErrorArray = (state: RootState): string[] | null => {
  const error = state.LogoutSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
