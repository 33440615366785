//PlayerDeposit.js

import config from '../../../config.js'
import React, {FormEvent, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import {
    selectIsAuthenticated
} from "../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../src/redux/slices/site/loginSlice"

import {
    fetchGetDepositResponsibleGaming,
    selectGetDepositResponsibleGamingData,
    selectGetDepositResponsibleGamingError,
    selectGetDepositResponsibleGamingErrorArray,
    selectGetDepositResponsibleGamingLoading,
    selectGetDepositResponsibleGamingMessages,
    selectGetDepositResponsibleGamingSuccess
} from '../../../redux/slices/getDepositResponsibleGamingSlice'

import {
    selectPlayerBalances,
    selectPlayerBalancesErrorString,
    selectPlayerBalancesLoading
} from '../../../redux/selectors/playerDeposit/playerBalancesSelectors'
import {fetchGetPlayerBalances} from '../../../redux/actions/playerDeposit/playerBalancesActions'
import {
    selectGetPaymentMethods,
    selectGetPaymentMethodsErrorString,
    selectGetPaymentMethodsLoading
} from '../../../redux/selectors/payment/getPaymentMethodsSelectors'
import {fetchGetPaymentMethods} from '../../../redux/actions/payment/getPaymentMethodsActions'

import {selectCurrentView, selectViews} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setNextView, setView} from '../../../redux/actions/playerDeposit/viewActions'

import CurrencyAndPayment from './CurrencyAndPayment/CurrencyAndPayment'
import DepositDetails from './DepositDetails/DepositDetails'

import Alert, {AlertProps} from '../../layouts/ui/alert/Alert'

import FormWrapper from './FormWrapper/FormWrapper'


export interface Balance {
    id: string;
    currency_id: number;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    currency_flag_active: string;
    flag_main: string;
    amount: string;
    comment: string | null;
    created_at: string;
    updated_at: string;
}

export interface PaymentMethod {
    id: string;
    name: string;
    image_path: string;
    direction: string;
    min_deposit: string;
    max_deposit: string;
    pay_system_id: string;
    pay_system_name: string;
    pay_system_tech_name: string;
    currency_id: number;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    fields: any[]; // Можете уточнить тип для полей, если они будут известны
}

export interface PaymentFields {
    // Определите точные поля, которые ожидаются в объекте fields
    [key: string]: any;
}

const imgDir = config.fileUrls.payment;
const dafaultImg = "https://img.freepik.com/premium-vector/content-delivery-network-concept-proxy-anonymous-vpn-vector-design-cloud-processing-computing_135661-1393.jpg?semt=ais_hybrid";

interface PlayerDepositProps {
    propCurrencyId?: number | null;
}

const PlayerDeposit: React.FC<PlayerDepositProps> = ({propCurrencyId}) => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const views = useSelector(selectViews);
    const currentView = useSelector(selectCurrentView);

    const getDepositResponsibleGamingSuccess = useSelector(selectGetDepositResponsibleGamingSuccess);
    const getDepositResponsibleGamingLoading = useSelector(selectGetDepositResponsibleGamingLoading);
    const getDepositResponsibleGamingData = useSelector(selectGetDepositResponsibleGamingData);
    const getDepositResponsibleGamingError = useSelector(selectGetDepositResponsibleGamingError);
    const getDepositResponsibleGamingErrorArray = useSelector(selectGetDepositResponsibleGamingErrorArray);
    const getDepositResponsibleGamingMessages = useSelector(selectGetDepositResponsibleGamingMessages);


    const playerBalances = useSelector(selectPlayerBalances);
    const playerBalancesLoading = useSelector(selectPlayerBalancesLoading);
    const playerBalancesErrorString = useSelector(selectPlayerBalancesErrorString);

    const getPaymentMethods = useSelector(selectGetPaymentMethods);
    const getPaymentMethodsLoading = useSelector(selectGetPaymentMethodsLoading);
    const getPaymentMethodsErrorString = useSelector(selectGetPaymentMethodsErrorString);

    const [selectedCurrency, setSelectedCurrency] = useState<Balance | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
    const [isButtonContinueClick, setIsButtonContinueClick] = useState(false);

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
    const [isDepositResponsibleGamingSuccess, setIsDepositResponsibleGamingSuccess] = useState(true);

    useEffect(() => {
        // Для переключения форм
        if (isAuthenticated && token) {
            dispatch(fetchGetDepositResponsibleGaming({ token }));
            dispatch(setView('CurrencyAndPayment'));
        }

        // console.log('component fetchGetDepositResponsibleGaming', propCurrencyId);
    }, [dispatch, token])


    useEffect(() => {
        if (getDepositResponsibleGamingLoading === false) {
            if (getDepositResponsibleGamingSuccess) {
                setIsDepositResponsibleGamingSuccess(true);
            } else {
                setIsDepositResponsibleGamingSuccess(false);
            }
        }
        if (getDepositResponsibleGamingError) {
            console.error('get deposit responsible gaming error', getDepositResponsibleGamingError);
        }
        // console.log('PlayerDeposit getDepositResponsibleGamingSuccess', getDepositResponsibleGamingSuccess);

    }, [getDepositResponsibleGamingSuccess, getDepositResponsibleGamingLoading, getDepositResponsibleGamingError]);


    useEffect(() => {
        // Для переключения форм
        if (isAuthenticated && token) {
            dispatch(fetchGetPlayerBalances(token));
        }

        console.log('component fetchGetPlayerBalances');
    }, [dispatch, token])

    useEffect(() => {
        let defaultCurrency: Balance | null = null;
        if (propCurrencyId) {
            defaultCurrency = playerBalances.find (
                (balance: Balance) => Number(balance.currency_id) === propCurrencyId || balance.currency_id === propCurrencyId
            );

            // console.log('component fetchGetDepositResponsibleGaming propCurrencyId', propCurrencyId);
            // console.log('component fetchGetDepositResponsibleGaming playerBalances', playerBalances);
            // console.log('component fetchGetDepositResponsibleGaming defaultCurrency', defaultCurrency);
        } else if (selectedCurrency === null) {
            defaultCurrency = playerBalances.find(
                (balance: Balance) => balance.flag_main === "YES" && balance.currency_flag_active === "YES"
            );
        }
        
        if (defaultCurrency) {
            setSelectedCurrency(defaultCurrency);
            setSelectedPaymentMethod(null);
            findPaymentMethods(defaultCurrency.currency_id);
        }
        
    }, [playerBalances]);

    useEffect(() => {
        console.log('PlayerDeposit::playerBalances', playerBalances);
        console.log('PlayerDeposit::selectedCurrency', selectedCurrency);
    }, [playerBalances]);

    useEffect(() => {
        console.log('PlayerDeposit::getPaymentMethods', getPaymentMethods);
        console.log('PlayerDeposit::currentView', currentView);

        if (getPaymentMethods.length > 0) {
            if(!selectedPaymentMethod){
                const defaultPaymentMethod = getPaymentMethods[0];

                if (defaultPaymentMethod) {
                    setSelectedPaymentMethod(defaultPaymentMethod);
                }
            }
            
        }else{
            setSelectedPaymentMethod(null);
        }

    }, [getPaymentMethods, currentView]);

    // Функция для получения методов оплаты
    const findPaymentMethods = (currency_id: number) => {
        const direction = 'deposit';
        const language = 2; // TODO: Получить из приложения
        token && dispatch(fetchGetPaymentMethods(token, currency_id, direction, language));
    };

    const handleCurrencyChange = (currency_id: number) => {
        const selected = playerBalances.find((balance: Balance) => balance.currency_id === currency_id);
        setSelectedCurrency(selected);
        setSelectedPaymentMethod(null);
        findPaymentMethods(selected.currency_id);
    };

    const handlePaymentMethodChange = (id: string) => {
        const selectedMethod = getPaymentMethods.find((method: PaymentMethod) => method.id === id);
        setSelectedPaymentMethod(selectedMethod);
    };

    const handleButtonContinueClick = () => {
        setIsButtonContinueClick(true);
        if (selectedCurrency && selectedPaymentMethod) {
            console.log('paytest handleButtonContinueClick selectedCurrency', selectedCurrency);
            console.log('paytest handleButtonContinueClick selectedMethod', selectedPaymentMethod);
            dispatch(setNextView());
        }
    };

    const handleCreatePaymentSubmit = (e: FormEvent<Element>, fieldsToSave: PaymentFields): void => {
        e.preventDefault();
        console.log('PlayerDeposit: handleCreatePaymentSubmit', fieldsToSave);
    };

    const handlePaymentSuccess = () => {
        setIsPaymentSuccess(true);
    };

    const renderView = () => {
        if (!isDepositResponsibleGamingSuccess) {
            return null;
        }

        switch (currentView) {
            case 'CurrencyAndPayment':
                return (
                    <>
                        <CurrencyAndPayment
                            playerBalances={playerBalances}
                            getPaymentMethods={getPaymentMethods}
                            playerBalancesLoading={playerBalancesLoading}
                            playerBalancesErrorString={playerBalancesErrorString}
                            getPaymentMethodsLoading={getPaymentMethodsLoading}
                            getPaymentMethodsErrorString={getPaymentMethodsErrorString}
                            selectedCurrency={selectedCurrency}
                            selectedPaymentMethod={selectedPaymentMethod}
                            handleCurrencyChange={handleCurrencyChange}
                            handlePaymentMethodChange={handlePaymentMethodChange}
                            handleButtonContinueClick={handleButtonContinueClick}
                            isButtonContinueClick={isButtonContinueClick}
                        />
                    </>
                );

            case 'DepositDetails':
                return (
                    <DepositDetails
                        selectedCurrency={selectedCurrency}
                        selectedPaymentMethod={selectedPaymentMethod}
                        handleSubmit={handleCreatePaymentSubmit}
                        onPaymentSuccess={handlePaymentSuccess}
                    />
                );

            default:
                return null; // Можно вернуть null или какой-то компонент по умолчанию
        }
    };

    const getAlertProps = (): AlertProps | null => {
        if (isPaymentSuccess) {
            return { type: "success", message: "Operation was successful", onClose: () => setIsPaymentSuccess(false) };
        }

        if (isDepositResponsibleGamingSuccess) {
            if (getDepositResponsibleGamingData && getDepositResponsibleGamingData.residue > 0) {
                return { type: "info", message: "Deposit creation allowed. Available balance(residue): " + getDepositResponsibleGamingData.residue, showButtomClose: false };
            }
        } else{
            if (!getDepositResponsibleGamingLoading) {
                if(getDepositResponsibleGamingData && getDepositResponsibleGamingData.residue === 0){
                    return { type: "error", message: "Unable to create a deposit. Remaining balance is 0.", showButtomClose: false };
                }else{
                    return { type: "error", message: "The operation is not possible. You have reached your deposit limit.", showButtomClose: false };
                    // return { type: "error", message: "Operation failed. You cannot create a deposit", showButtomClose: false };
                }
                
            }
        }

        
        

        return null;
    };

    const getAlertPosition = (): 'top' | 'bottom' => {
        if (!isDepositResponsibleGamingSuccess || isDepositResponsibleGamingSuccess) {
            return "top";
        }
        return 'bottom';
    };

    return (
        <FormWrapper
            alertProps={getAlertProps()}
            alertPosition={getAlertPosition()}
        >
            <>
                {getDepositResponsibleGamingError && getDepositResponsibleGamingErrorArray && getDepositResponsibleGamingErrorArray.length > 0 && (
                    <Alert type="error">
                        {getDepositResponsibleGamingMessages && <p><strong>{getDepositResponsibleGamingMessages}</strong></p>}
                        {getDepositResponsibleGamingErrorArray.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </Alert>
                )}
                {renderView()} {/* Вызов функции для рендеринга текущего вида */}
            </>

        </FormWrapper>
    );
};

export default PlayerDeposit;

/*todo: need to fix overflow at CurrencyAndPayment*/
