import React, {useEffect, useRef, useState} from 'react'
import {ClassNames, DayPicker as ReactDayPicker} from 'react-day-picker'

// import { useTranslation } from '@/shared/assets'
import {clsx} from 'clsx'
import {format} from 'date-fns'

import 'react-day-picker/style.css'
import styles from './DayPicker.module.scss'
import {useOutsideDayClick} from "./OutsideDayClickHook"
import {BrightInput} from "../../../../../common/BrightInput/BrightInput"
import calendar from 'src/assets/images/icons/calendar.svg'
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {getNumberFromPx} from "../../../../../common/helpers"

export const DayPicker = (props: DayPickerProps) => {
  const {onChange, selected} = props

  const [isPickerSingleHidden, setIsPickerSingleHidden] = useState<boolean>(true)
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
  const [inputWidth, setInputWidth] = useState<number | undefined>(undefined)

  const [previousSelectedDate, setPreviousSelectedDate] = useState<string | undefined>(selected)

  const classNames: Partial<ClassNames> = {
    // caption_label: clsx(styles.caption, styles.caption_label),
    root: clsx('rdp-root', styles.root),
    month_grid: clsx('rdp-month_grid', styles.month_grid),
    months: styles.months,
    day: clsx('rdp-day', styles.day),
    dropdown: clsx('rdp-dropdown', styles.dropdown),
    caption_label: clsx('rdp-caption_label', styles.caption_label),
    month_caption: clsx('rdp-month_caption', styles.month_caption),
    chevron: styles.chevron,
    nav: clsx('rdp-nav', styles.nav),
    button_previous: clsx('rdp-button_previous', styles.button_previous),
    button_next: clsx('rdp-button_next', styles.button_next),
    today: styles.today,
    weekday: clsx(styles.weekday),
  }

  const handleSingleSelect = (date: Date | undefined) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd')
      onChange(formattedDate)
    }
  }

  // Function to parse selected date if it exists
  const parseSelectedDate = (): Date | undefined => {
    return selected ? new Date(selected) : undefined
  }

  const onClickSingle = () => {
    setPreviousSelectedDate(selected) // Save the current date before opening the calendar
    // setIsPickerSingleHidden(false)
    setIsPickerSingleHidden((prev) => !prev);   // Open the calendar
  }

  const onApplyClick = () => {
    setIsPickerSingleHidden(true) // Close the calendar
  }

  const onCancelClick = () => {
    setIsPickerSingleHidden(true)     // Close the calendar
    onChange(previousSelectedDate)    // Revert to the previous date
  }

  const calendarRef = useRef<HTMLDivElement>(null)

  const handleOutsideClick = () => {
    // setIsPickerSingleHidden(true)
  }

  useOutsideDayClick(calendarRef, handleOutsideClick)
  useEffect(() => {
    if (calendarRef.current) {
      setInputWidth(calendarRef.current.offsetWidth)
    }
  }, [])

  const currentTime = new Date()
  const currentYear = currentTime.getFullYear()
  const maxHumanAge = 122

  const handleMonthChange = (month: Date) => {
    setCurrentMonth(month)
  }

  const customWeekdays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  const CalendarFooter = () => {
    return <div className={styles.calendarFooter}>
      <ButtonStyled variant={'glass'} className={styles.button} onClick={onCancelClick}>Cancel</ButtonStyled>
      <ButtonStyled className={styles.button} onClick={onApplyClick}>Apply</ButtonStyled>
    </div>
  }

  const stylesBodyCommon = getComputedStyle(document.body)
  const formColumnSpacing = getNumberFromPx(stylesBodyCommon.getPropertyValue('--form-column-spacing'))

  return (
    <div
      className={clsx(styles.pickerContainer, styles.scrollbar)}
      style={{
        '--input-width': `${
          !inputWidth 
            ? inputWidth /*waits until it loads*/
            : inputWidth * 2 + formColumnSpacing 
        }px`} as React.CSSProperties}
    >
      <BrightInput
        value={selected || ''}
        onClick={onClickSingle}
        icon={calendar}
        className={styles.input}
        variation={'auth'}
        iconPosition={'right'}
        ref={calendarRef}
      />
      {!isPickerSingleHidden &&
          <div className={styles.calendarWrapper}>
              <ReactDayPicker
                  captionLayout={'dropdown'} // this is month & year dropdowns quick selection
                  classNames={classNames}
                  fromYear={currentYear - maxHumanAge}
                  mode={'single'}
                  month={currentMonth}
                  onMonthChange={handleMonthChange}
                  onSelect={handleSingleSelect}
                  selected={parseSelectedDate()}
                  showOutsideDays
                  toYear={currentYear}
                  formatters={{
                    formatWeekdayName: (day: Date) => customWeekdays[day.getDay()],
                  }}
                  footer={
                    <CalendarFooter/>
                  }
              />
          </div>
      }
    </div>
  )
}

export type DayPickerProps = {
  errorMessage?: string
  onChange: (value: string | undefined) => void
  selected: string | undefined
}
