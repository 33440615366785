import React from 'react'
import {BrightInput} from "../../../../common/BrightInput/BrightInput"
import lock_01_passive from 'src/assets/images/icons/lock_01_passive.svg'
import lock_01 from 'src/assets/images/icons/lock_01.svg'
import ErrorMessage from "./ErrorMessage/ErrorMessage"


type PasswordInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
                                                       id,
                                                       name,
                                                       value = '',
                                                       onChange,
                                                       required = false,
                                                       placeholder,
                                                       error = ''
                                                     }) => {
  return (
    <div>
      <BrightInput
        type="password"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        data-error-required={`${placeholder} is required`}
        variation={"auth"}
        icon={lock_01_passive}
        activeIcon={lock_01}
      />
      {error && <ErrorMessage error={error}/>}
    </div>
  )
}

export default PasswordInput
