import styles from './Account.module.scss'
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import clsx from 'clsx'
import {PlayerContainer} from "../../../common/PlayerContainer/PlayerContainer"
import React from "react"
import {useSelector} from "react-redux"
import {
  selectToken
} from "../../../../src/redux/slices/site/loginSlice"
import {useGetPersonalInfoQuery} from "../../../api/api"
import {NavigationButtonsGroup} from "../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {Promo} from "./Main/Promo/Promo"
import {Main} from './Main/Main'
import {MyProfile} from "./OtherTabs/MyProfile"
import {MyWallet} from "./OtherTabs/MyWallet"
import {History} from "./OtherTabs/History"
import {useAppDispatch} from "../../../hooks"
import {
  AccountButtonId,
  selectActiveAccountButton,
  selectAccountButtonData,
  setAccountActiveButton
} from "../../../redux/slices/menus/menuAccountSlice"
import {ButtonId} from "../../../common/types"
import {PageHeader} from "../../../common/PageHeader/PageHeader"
import {useTranslation} from "react-i18next"

export const Account = () => {
  const token = useSelector(selectToken)
  const {data: getPersonalInfoData} = useGetPersonalInfoQuery({token: token ? token : ''})
  const playerId = getPersonalInfoData?.data?.player_id

  const dispatch = useAppDispatch()
  const accountActiveButton = useSelector(selectActiveAccountButton)
  const buttonData = useSelector(selectAccountButtonData)
  const {t} = useTranslation()

  const handleButtonClick = (id: ButtonId) => {
    dispatch(setAccountActiveButton(id as AccountButtonId))
  }

  return (
    <div className={clsx(styles.profile, stylesCommon.pageContainer)}>
      <div className={styles.headerContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.accountPlayer}>
            <PageHeader title={t('Account')}/>
            {getPersonalInfoData?.data?.login && playerId &&
                <PlayerContainer loginName={getPersonalInfoData?.data?.login} playerId={playerId}/>}
          </div>
          <div className={styles.navigationContainer}>
            <NavigationButtonsGroup buttonData={buttonData} activeButton={accountActiveButton}
                                    handleButtonClick={handleButtonClick} variant={'profile'}/>
          </div>
        </div>
        <Promo/>
      </div>
      <div className={styles.mainContainer}>
        { accountActiveButton === 'main' && <Main/> }
        { accountActiveButton === 'myProfile' && <MyProfile /> }
        { accountActiveButton === 'myWallets' && <MyWallet /> }
        { accountActiveButton === 'history' && <History /> }
      </div>

    </div>
  )
}