import home_02 from "../../../assets/images/icons/home_02.svg"
import user_circle from "../../../assets/images/icons/user_circle.svg"
import wallet_02 from "../../../assets/images/icons/wallet_02.svg"
import file_06 from "../../../assets/images/icons/file_06.svg"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../store"

export type AccountButtonId = 'main' | 'myProfile' | 'myWallets' | 'history'

// button type
type AccountButtonData = {
  id: AccountButtonId
  label: string
  img: string
  imgAlt: string
}

export type MenuState = {
  activeButton: AccountButtonId  // ID of active button
  buttonData: Array<AccountButtonData> // Button data
};

const initialState: MenuState = {
  activeButton: 'main', // initial state of active button
  buttonData: [
    {id: "main", label: 'Main', img: home_02, imgAlt: 'home_02'},
    {id: "myProfile", label: 'My profile', img: user_circle, imgAlt: 'user_circle'},
    {id: "myWallets", label: 'My wallet', img: wallet_02, imgAlt: 'wallet_02'},
    {id: 'history', label: 'History', img: file_06, imgAlt: 'file_06'},
  ],
}

const menuAccountSlice = createSlice({
  name: 'menuAccount',
  initialState,
  reducers: {
    setAccountActiveButton: (state, action: PayloadAction<AccountButtonId>) => {
      state.activeButton = action.payload // setting active button
    },
  },
})

export const menuAccountReducer = menuAccountSlice.reducer;
export const { setAccountActiveButton } = menuAccountSlice.actions;

export const selectActiveAccountButton = (state: RootState) => state.menuAccountReducer.activeButton
export const selectAccountButtonData = (state: RootState) => state.menuAccountReducer.buttonData;


