// components/elems/MyProgressModal.js

import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModalMyProgress, connectWebSocketMyProgress } from '../../redux/actions/player/myProgressActions';
import { selectShowModalMyProgress, selectPositionStylesModalMyProgress, selectMyProgress, selectMyProgressLoading, selectMyProgressError } from '../../redux/selectors/player/myProgressSelectors';
import {
    selectIsAuthenticated
} from "../../../src/redux/slices/site/isAuthenticatedSlice.ts"

import {
    selectToken
} from "../../../src/redux/slices/site/loginSlice.ts"

const MyProgressModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const showModalMyProgress = useSelector(selectShowModalMyProgress);
    const positionStylesModalMyProgress = useSelector(selectPositionStylesModalMyProgress);
    const myProgress = useSelector(selectMyProgress);
    const myProgressLoading = useSelector(selectMyProgressLoading);
    const myProgressError = useSelector(selectMyProgressError);

    useEffect(() => {
        if (isAuthenticated) {
            // dispatch(fetchProviders(token));
            const closeWebSocketMyProgress = dispatch(connectWebSocketMyProgress(token));

            return () => {
                closeWebSocketMyProgress();
            };
        }
    }, [dispatch, token, isAuthenticated]);

    useEffect(() => {
        console.log('MyProgressModal - myProgress', myProgress, token);
    }, [myProgress, token]);

    const handleClose = () => {
        dispatch(setShowModalMyProgress(false));
    };

    const handleOffers = (e) => {
        console.log('handleOffers');
    };

    const calculateProgressPercentage = (currentPoints, maxPoints) => {
        if (maxPoints === 0) return 0;
        return (currentPoints / maxPoints) * 100;
    };

    return (
        <div
            className={"modal-myprogress" + (showModalMyProgress ? " d-block" : " d-none")}
            style={{
                position: 'absolute',
                zIndex: 1050, // Поверх всего
                ...positionStylesModalMyProgress, // Динамическое позиционирование из state
            }}
        >
            <div className="card" style={{ width: '24rem' }}>
                <div className="card-header d-flex justify-content-between">
                    <h5>My Progress</h5>
                    <button
                        type="button"
                        className="close border border-light"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="card-body">

                    {/* <h5 className="card-title">My Progress</h5> */}
                    {
                        myProgressLoading ? (
                            <p>Loading...</p>
                        ) : myProgressError ? (
                            <p>Error: {myProgressError}</p>
                        ) : myProgress.length > 0 ?
                            (<div className="d-flex justify-content-between">
                                <div>
                                    <div>
                                        <p><strong>LOYALTY LEVEL</strong></p>
                                        <p>{myProgress[0].loyalty.current_level}</p>
                                    </div>
                                    <div>
                                        <p><strong>LOYALTY POINTS</strong></p>
                                        <p>{myProgress[0].loyalty.current_points}</p>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: `${calculateProgressPercentage(myProgress[0].loyalty.current_points, myProgress[0].loyalty.max_points)}%` }}
                                                aria-valuenow={calculateProgressPercentage(myProgress[0].loyalty.current_points, myProgress[0].loyalty.max_points)} 
                                                aria-valuemin="0" 
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p><strong>EXPERIENCE LEVEL</strong></p>
                                        <p>{myProgress[0].experience.current_level}</p>
                                    </div>
                                    <div>
                                        <p><strong>EXPERIENCE POINTS</strong></p>
                                        <p>{myProgress[0].experience.current_points}</p>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: `${calculateProgressPercentage(myProgress[0].experience.current_points, myProgress[0].experience.max_points)}%` }}
                                                aria-valuenow={calculateProgressPercentage(myProgress[0].experience.current_points, myProgress[0].experience.max_points)} 
                                                aria-valuemin="0" 
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                    </div>
                                </div>


                            </div>)
                            :
                            (<p className="card-text">Here you can view your progress.</p>)
                    }

                </div>

                <div className="card-footer">
                    <p><strong>There are no activities you are participating in. </strong></p>
                    <p><strong>Join to get additional benefits</strong></p>
                    <button className="btn btn-primary" onClick={handleOffers}>OFFERS</button>


                </div>

            </div>
        </div>
    );
};

export default MyProgressModal;
