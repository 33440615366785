import React, { useState, useRef, useEffect, FormEvent, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks";
import {
    selectIsAuthenticated
} from "../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../src/redux/slices/site/loginSlice"
import styles from '../PlayerDeposit.module.scss';
import TextInput from './TextInput';
import Alert from '../../layouts/ui/alert/Alert';
import {
    fetchListAvailableBalances,
    selectListAvailableBalancesLoading,
    selectListAvailableBalancesSuccess,
    selectListAvailableBalancesError,
    selectListAvailableBalancesErrorArray,
    selectListAvailableBalancesMessages,
    selectListAvailableBalancesData,
    resetState as resetStateListAvailableBalances

} from '../../../redux/slices/listAvailableBalancesSlice'

import {
    fetchCreateBalance,
    selectCreateBalanceLoading,
    selectCreateBalanceSuccess,
    selectCreateBalanceError,
    selectCreateBalanceErrorArray,
    selectCreateBalanceMessages,
    selectCreateBalanceData,
    resetState as resetStateCreateBalance

} from '../../../redux/slices/createBalanceSlice'



interface Currency {
    id: number;
    type: string;
    title: string;
    code: string;
    symbol: string | null;
    flag_active: "YES" | "NO";
    flag_active_players: "YES" | "NO";
    pos: number;
}

// interface ListBalances {
//     fiat?: Currency[]; // Массив для "fiat", может отсутствовать
//     crypto?: Currency[]; // Массив для "crypto", может отсутствовать
// }

interface ListBalances {
    [key: string]: Currency[]; // Ключ — строка, значение — массив объектов Currency
}


interface AddWalletProps {

}

const AddWallet: React.FC<AddWalletProps> = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const listAvailableBalancesLoading = useSelector(selectListAvailableBalancesLoading);
    const listAvailableBalancesSuccess = useSelector(selectListAvailableBalancesSuccess);
    const listAvailableBalancesError = useSelector(selectListAvailableBalancesError);
    const listAvailableBalancesErrorArray = useSelector(selectListAvailableBalancesErrorArray);
    const listAvailableBalancesMessages = useSelector(selectListAvailableBalancesMessages);
    const listAvailableBalancesData = useSelector(selectListAvailableBalancesData);

    const createBalanceLoading = useSelector(selectCreateBalanceLoading);
    const createBalanceSuccess = useSelector(selectCreateBalanceSuccess);
    const createBalanceError = useSelector(selectCreateBalanceError);
    const createBalanceErrorArray = useSelector(selectCreateBalanceErrorArray);
    const createBalanceMessages = useSelector(selectCreateBalanceMessages);
    const createBalanceData = useSelector(selectCreateBalanceData);

    // const [activeButton, setActiveButton] = useState<number>(1);

    // const handleButtonClick = (id: number) => {
    //     setActiveButton(id); // Устанавливаем активную кнопку
    // };

    const [searchWallets, setSearchWallets] = useState<string>("");
    const [listBalances, setListBalances] = useState<null | ListBalances>(null);
    const [filteredBalances, setFilteredBalances] = useState<null | ListBalances>(null);
    const [selectedCurrencyId, setSelectedCurrencyId] = useState<null | number>(null);
    const [showErrorsListAvailableBalances, setShowErrorsListAvailableBalances] = useState<boolean>(false);
    const [showErrorsCreateBalance, setShowErrorsCreateBalance] = useState<boolean>(false);
    const noDataMessage = "No available balances...";

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('handleFormSubmit');

    };

    useEffect(() => {
        resetStates();
        if (isAuthenticated && token) {
            dispatch(fetchListAvailableBalances({ token }));  // Вызов экшена для получения данных
        }

        console.log('AddWallet: fetchListAvailableBalances');
    }, [dispatch, token, isAuthenticated]);


    useEffect(() => {
        if (listAvailableBalancesSuccess && listAvailableBalancesData) {
            if (Object.entries(listAvailableBalancesData).length > 0) {
                setListBalances(listAvailableBalancesData);
                setFilteredBalances(listAvailableBalancesData);
            } else {
                setListBalances(null);
                setFilteredBalances(null);
            }
        } else if (listAvailableBalancesError) {
            // Если была ошибка, можно добавить обработку
            // setShowErrors(true);
            console.error("Error fetching balances");
        }
    }, [listAvailableBalancesSuccess, listAvailableBalancesData, listAvailableBalancesError]);


    useEffect(() => {
        if (listBalances) {
            if (searchWallets.trim() !== "") {
                // Фильтрация по полю 'code'
                const filteredData = Object.entries(listBalances).reduce((acc, [key, currencies]) => {
                    const filteredCurrencies = currencies.filter((currency: Currency) =>
                        currency.code.toLowerCase().includes(searchWallets.toLowerCase())
                    );

                    if (filteredCurrencies.length > 0) {
                        acc[key] = filteredCurrencies;
                    }

                    return acc;
                }, {} as ListBalances);

                if (Object.entries(filteredData).length > 0) {
                    setFilteredBalances(filteredData);
                } else {
                    setFilteredBalances(null);
                }
            } else {
                // Если поле поиска пустое, отображаем весь список
                setFilteredBalances(listBalances);
            }
        }
    }, [searchWallets, listBalances]);

    useEffect(() => {
        console.log('AddWallet selectedCurrencyId', selectedCurrencyId);
    }, [selectedCurrencyId]);

    useEffect(() => {
        if (createBalanceSuccess) {
            window.location.reload();
        } else if (createBalanceError) {
            // error
        }
    }, [createBalanceSuccess, createBalanceError]);

    const resetStates = () => {
        setSearchWallets("");
        setSelectedCurrencyId(null);
        setListBalances(null);
        setFilteredBalances(null);
        setShowErrorsCreateBalance(false);
        setShowErrorsListAvailableBalances(false);
        dispatch(resetStateListAvailableBalances());
        dispatch(resetStateCreateBalance());
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchWallets(value);
    };

    const handleCurrencyButtonClick = (currency: Currency) => {
        console.log('AddWallet handleCurrencyButtonClick', currency.id);
        setSelectedCurrencyId(currency.id);
    
        const confirmAction = window.confirm(
            `Are you sure you want to create a balance for currency: ${currency.code}?`
        );
    
        if (confirmAction) {
            if (isAuthenticated && token) {
                let currencyId = currency.id;
                dispatch(fetchCreateBalance({ token, currencyId }));
                // window.location.reload(); in useEffect
            }
        } else {
            console.log('Action cancelled by the user.');
        }
    };
    

    const renderFormField = () => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e);

        const nameItem = "search-wallets";
        let isRequired = false;
        // <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" class="DocSearch-Search-Icon"><path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>

        const commonProps = {
            id: nameItem,
            name: nameItem,
            value: searchWallets,
            onChange: handleChange,
            required: isRequired,
            label: "",
            placeholder: "Search currency",
            error: ""
        };

        return <TextInput {...commonProps} />;
    };

    const renderButtons = () => {
        return (
            <>
                <section id="add-wallet-currency-list" className="my-4">
                    {listAvailableBalancesLoading && "loading..."}

                    {listAvailableBalancesSuccess &&
                        filteredBalances === null &&
                        (<div className="row">{noDataMessage}</div>)
                    }

                    {listAvailableBalancesSuccess &&
                        filteredBalances &&
                        Object.entries(filteredBalances).map(([key, currencies]) => (
                            <div key={key} className="my-4 add-wallet-currency-list_block">
                                <h5>{key === "fiat" ? "Fiat Currency" : "Crypto Currency"}</h5>
                                <div className="row g-2">
                                    {currencies.map((currency: Currency) => (
                                        <div key={currency.id} className="col-3">
                                            <button
                                                onClick={() => handleCurrencyButtonClick(currency)}
                                                type="button"
                                                className="btn btn-outline-secondary rounded-pill w-100"
                                                title="Add wallet"
                                            >
                                                {currency.code}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </section>
            </>

        );
    };



    const renderErrors = () => {
        return (
            <>
                {listAvailableBalancesErrorArray && listAvailableBalancesErrorArray.length > 0 && (
                    <Alert onClose={() => (setShowErrorsListAvailableBalances(false))} type="error">
                        {listAvailableBalancesErrorArray.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </Alert>
                )}

                {createBalanceErrorArray && createBalanceErrorArray.length > 0 && (
                    <Alert onClose={() => (setShowErrorsCreateBalance(false))} type="error">
                        {createBalanceErrorArray.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </Alert>
                )}
            </>
        );
    }

    const renderMessages = () => {
        return (
            <>
                {!listAvailableBalancesError && listAvailableBalancesMessages && listAvailableBalancesMessages.length > 0 && (
                    <Alert type="info">
                        {listAvailableBalancesMessages.map((message: string, index: number) => (
                            <p key={index}>{message}</p>
                        ))}
                    </Alert>
                )}
            </>
        );
    }

    return (
        <div>
            {renderErrors()}
            {renderMessages()}
            <form onSubmit={handleFormSubmit} ref={formRef}>
                {renderFormField()}
            </form>

            {renderButtons()}
        </div>
    )
};

export default AddWallet;
