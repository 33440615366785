//ModalPlayerDeposit.js
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import PlayerDeposit from "../../pages/PlayerDeposit/PlayerDeposit"
import Withdrawal from "../../pages/Withdrawal/Withdrawal"
import Modal from "../ui/modal/Modal"
import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'
import stylesModal from '../ui/modal/Modal.module.scss'
import arrow_left from 'src/assets/images/icons/arrow_left.svg'
import {selectActiveButton, setActiveButton} from "../../../redux/slices/menuFinancialProcessesSlice"
import {CurrencyComingSoon} from "../../pages/PlayerDeposit/common/CurrencyComingSoon/CurrencyComingSoon"

interface ModalPlayerDepositProps {
    isShowModal: boolean;
    onCloseModal: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    currencyId?: number | null;
}


const ModalPlayerDeposit: React.FC<ModalPlayerDepositProps> = ({ isShowModal, onCloseModal, currencyId }) => {

    const dispatch = useAppDispatch()
    const previousView = useSelector(selectPreviousView)
    const activeButton = useSelector(selectActiveButton)

    console.log("previousView:", previousView);

    useEffect(() => {
        if(isShowModal === false){
            dispatch(setActiveButton(1))
        }
    }, [dispatch, isShowModal])

    const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setPreviousView())
    }

    const viewComponent = () => {
        if(activeButton === 1){
            return <PlayerDeposit propCurrencyId={currencyId} />
        }
        if(activeButton === 2){ //'Withdraw' TODO
            return <Withdrawal />
        }
        if (activeButton === 3 || activeButton === 4) {
            return <CurrencyComingSoon />
        }
    }
    
    return (
        <>
            <Modal
                show={isShowModal}
                onClose={onCloseModal}
                body={isShowModal && <>
                    {previousView && <button className={stylesModal.btnPrev} onClick={handleModalBackClick}>
                        <img src={arrow_left} alt="arrow_left" />
                    </button>}
                    {viewComponent()}
                </>}
                showHeader={false}

            />
        </>
    );
};

export default ModalPlayerDeposit;
