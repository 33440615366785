// registerSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RootState } from '../../store';


type RegisterSliceResponse = {
  success: boolean;
  data: boolean; // Уточните тип данных
  messages: string | null;
};

type RegisterSliceError = {
  error: any;
  message: string;
};

interface RegisterSliceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: boolean; // Уточните тип массива, если известно
    messages: string | null;
  }
  

const initialState: RegisterSliceState = {
  success: false,
  loading: false,
  error: null,
  data: false,
  messages: null,
};

// Типизация параметров для fetchRegister
// Типизация параметров для fetchRegister
export type FetchRegisterParam = { 
  bonus_id?: number | null; // Может быть undefined, поэтому делаем необязательным
  city: string;
  datebirth: string; // Дата рождения в строковом формате (ISO YYYY-MM-DD)
  email: string;
  firstname: string;
  id_country: string; // ID страны в виде строки
  id_currency: string; // ID валюты в виде строки
  ip: string;
  lastname: string;
  new_password?: string; // Пароль может быть пустым, поэтому необязательный
  terms?: string; // Возможно, это соглашение (чекбокс), поэтому делаем опциональным
  verifycode: string; // Код верификации
};


export const fetchRegister = createAsyncThunk<
  RegisterSliceResponse,
  FetchRegisterParam,
  { rejectValue: RegisterSliceError }
>(
  'RegisterSlice/fetchRegister',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchRegister:', { userData });

      const response = await axios.post<RegisterSliceResponse>(
        `${config.serverUrl}/site/register`,
        { userData },
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log(`fetchRegister success: ${response.status}`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error in fetchRegister:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to register user.',
        });
      }

      return rejectWithValue({
        error: ['An unknown error occurred during registration.'],
        message: 'An unknown error occurred during registration.',
      });
    }
  }
);



const RegisterSlice = createSlice({
  name: 'Profile/RegisterSlice',
  initialState,
  reducers: {
    resetStateRegister: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegister.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchRegister.fulfilled, (state, action: PayloadAction<RegisterSliceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchRegister.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetStateRegister } = RegisterSlice.actions;
export const RegisterSliceReducer = RegisterSlice.reducer;


// ---------
// Selectors
// ---------
export const selectRegisterSliceLoading = (state: RootState) => state.RegisterSliceReducer.loading;
export const selectRegisterSliceSuccess = (state: RootState) => state.RegisterSliceReducer.success;
export const selectRegisterSliceError = (state: RootState) => state.RegisterSliceReducer.error;
export const selectRegisterSliceMessages = (state: RootState) => state.RegisterSliceReducer.messages;
export const selectRegisterSliceData = (state: RootState) => state.RegisterSliceReducer.data;
// export const selectRegister = (state: RootState): boolean => { return state.RegisterSliceReducer.data === true};


export const selectRegisterSliceErrorString = (state: RootState): string | null => {
  const error = state.RegisterSliceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectRegisterSliceErrorArray = (state: RootState): string[] | null => {
  const error = state.RegisterSliceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
