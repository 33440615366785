import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  selectIsAuthenticated
} from "../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../src/redux/slices/site/loginSlice"
import {
  selectListBalances,
  selectListBalancesError,
  selectListBalancesLoading
} from "src/redux/selectors/player/listBalancesSelectors"
import {connectListBalancesWebSocket} from "src/redux/actions/player/listBalances"
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {switchAccountSelector} from "../../../redux/slices/switchAccountSlice"
import {LinearProgress} from "@mui/material"
import styles from './Accounts.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {Balance} from "../../../common/types"
import ModalPlayerDeposit from "../../layouts/modals/ModalPlayerDeposit"
import ModalAddWallet from "../../layouts/modals/ModalAddWallet"
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
import {AccountsElement} from "./AccountsElement/AccountsElement"
import {useSwitchAccount} from "../../../common/hooks/useSwitchAccount"
import {PageSubHeader} from "../../../common/PageSubHeader/PageSubHeader"
import clsx from "clsx"

export type CheckedStates = {
  [key: number]: boolean
}

const Accounts: React.FC = () => {

  const [isLoadingSocket, setIsLoadingSocket] = useState(false)

  const dispatch = useAppDispatch()

  const listBalances = useSelector(selectListBalances) as Balance[] | null


  const listBalancesLoading = useSelector(selectListBalancesLoading)
  const listBalancesError = useSelector(selectListBalancesError)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const storedToken = useSelector(selectToken)
  const [showModalPlayerDeposit, setShowModalPlayerDeposit] = useState<boolean>(false)
  const [showModalAddWallet, setModalAddWallet] = useState<boolean>(false)

  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | null>(null)

  const {isLoading: isLoadingAccountSwitch} = useAppSelector(switchAccountSelector)

  useEffect(() => {
    if (isAuthenticated && storedToken) {
      const closeWebSocket = dispatch(connectListBalancesWebSocket(storedToken))

      return () => {
        closeWebSocket()
      }
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {

    const initialCheckedStates = listBalances?.reduce((acc: CheckedStates, balance) => {
      acc[balance.id] = balance.flag_main === 'YES'
      return acc
    }, {})

    if (initialCheckedStates) {
      setCheckedStates(initialCheckedStates)
    }

    setIsLoadingSocket(false)
  }, [listBalances])

  const [checkedStates, setCheckedStates] = useState<CheckedStates>({})

  const {onSwitchAccountClick} = useSwitchAccount()

  const onClickArrow = async (e: React.MouseEvent<HTMLElement>, balance: Balance) => {
    e.preventDefault()

    if (storedToken) {
      setIsLoadingSocket(true)
      void onSwitchAccountClick(balance.currency_id)
    }
  }

  const onDeposit = async (e: React.MouseEvent<HTMLButtonElement>, balance: Balance) => {
    e.preventDefault()
    console.log('onClickPlus', balance)
    setShowModalPlayerDeposit(true)
    setSelectedCurrencyId(balance.currency_id)
  }

  // Тут потом будет реализовано, что будет при нажатии на чекбоксы
  const handleCheckboxChange = (id: number) => {
    setCheckedStates(prevStates => ({
      ...prevStates,
      [id]: !prevStates[id]
    }))
    console.log(`Checkbox with id ${id} clicked`)
  }

  const handleAddWalletClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    console.log(`handleAddWalletClick`)
    setModalAddWallet(true)
  }

  return (
    <div className={clsx(styles.accounts, stylesCommon.profileRoundedTab)}>
      <div className={styles.headerContainer}>
        <PageSubHeader title={'Wallets'} />
        <ButtonStyled variant={'glass'} onClick={(e) => handleAddWalletClick(e)}>Add wallet</ButtonStyled>
      </div>


      {listBalancesLoading ? (
        <p>Loading...</p>
      ) : listBalancesError ? (
          <p>Error: {listBalancesError}</p>
        ) :
        <div className={styles.accountsContainer}>
          {listBalances === null || listBalances.length === 0 ? (
            <strong>No data!</strong>
          ) : (
            listBalances.map((balance, i) => (
              <AccountsElement
                key={i}
                balance={balance}
                checkedStates={checkedStates}
                handleCheckboxChange={handleCheckboxChange}
                onClickArrow={onClickArrow}
                onDeposit={onDeposit}
                isLoadingAccountSwitch={isLoadingAccountSwitch}
                isLoadingSocket={isLoadingSocket}
              />
            ))
          )}
        </div>
      }
      {(isLoadingAccountSwitch || isLoadingSocket) && <LinearProgress className={styles.linearProgress}/>}

      <ModalPlayerDeposit
        isShowModal={showModalPlayerDeposit}
        onCloseModal={() => setShowModalPlayerDeposit(false)}
        currencyId={selectedCurrencyId}
      />

      <ModalAddWallet
        isShowModal={showModalAddWallet}
        onCloseModal={() => setModalAddWallet(false)}
      />

    </div>
  )
}

// export default withAuth(Accounts)
export default Accounts
