import styles from './PromoInput.module.scss'
import {useTranslation} from "react-i18next";

export const PromoInput = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.promoInput}>
      <input className={styles.input} placeholder={t('Enter a promo code')}/>
      <button className={styles.button}>{t('Apply')}</button>
    </div>

  )
}