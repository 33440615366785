import React, { useState } from 'react';
import styles from './SelectInput.module.scss';
import { CurrencySelector } from "../../../../../../../common/CurrencySelectors";
import { useTranslation } from "react-i18next";
import check from 'src/assets/images/icons/check.svg';

export type SelectOption = {
  id: string;
  name: string;
  amount?: string;
  amount_bonus?: string;
};

type SelectInputProps = {
  id: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  label?: string;
  options: SelectOption[] | null;
  placeholder: string;
  error?: string | string[] | null;
  variant?: 'amount' | 'period';
};

const SelectInput: React.FC<SelectInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  label,
  options,
  placeholder,
  error = '',
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const selectedOption = options ? options.find(option => option.id.toString() === value) : null;

  return (
    <div className={styles.selectInput}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="input-group">
        <select
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={`form-select ${styles.select}`} // Applying Bootstrap class
          required={required}
        >
          <option value="" disabled>{placeholder}</option>
          {options && options.length > 0 ? (
            options.map((option) => (
              <option key={option.id} value={option.id}>
                {variant === 'amount' && option.name && (
                  <>
                    {/* <CurrencySelector currencyCode={option.name} size={20} /> */}
                    {option.name}
                  </>
                )}
                {variant === 'period' && option.name && option.name}
                {option.id === selectedOption?.id && (
                  ''
                  // <img src={check} alt="check" className={styles.checkIcon} />
                )}
              </option>
            ))
          ) : (
            <option disabled>{t('No data...')}</option>
          )}
        </select>
      </div>
      <div className="invalid-feedback">
        {Array.isArray(error) ? (
          error.map((errMsg, index) => (
            <p key={index}>{errMsg}</p>
          ))
        ) : (
          error || ''
        )}
      </div>
    </div>
  );
};

export default SelectInput;
