import React, {useState} from 'react'
import {useGetPageListQuery} from '../../../api/api'
import styles from './PageList.module.scss'
import {Pagination} from "../../../common/Pagination"
import {ARTICLES_PER_PAGE, PAGE_PAGINATION_VALUES, SIBLING_COUNT} from "../../../common/constants"
import {useNavigate} from "react-router-dom"
import {PageHeader} from "../../../common/PageHeader/PageHeader"
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
import config from "../../../config"

export const PageList = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(ARTICLES_PER_PAGE)

  const navigate = useNavigate()

  const {data, isLoading, error} = useGetPageListQuery({language: 1, page: currentPage, pageSize: itemsPerPage})

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error loading pages.</p>

  const onReadMore = (slug: string) => {
     navigate(`/page/${slug}`)
  }

  return (
    <div className={styles.pageList}>
      <PageHeader title={'Articles'} className={styles.pageHeader}/>
      <div className={styles.articlesContainer}>
        {data && data.success && data.data.map((page) => (
          <div key={page.id} className={styles.article}>
            <div className={styles.contentText}>
              <div className={styles.h1}>{page.h1}</div>
              {/*<p><b>meta_title:</b> {page.meta_title}</p>*/}
              <div className={styles.meta_description}>
                {page.meta_description.length > 50
                  ? `${page.meta_description.substring(0, 50)}...`
                  : page.meta_description}
              </div>
              {/*<p><b>Body:</b> {page.body.substring(0, 200)}...</p>*/}
              <div className={styles.body}>{page.body.substring(0, 350)}...</div>
              {/*<p><b>Slug:</b> {page.slug}</p>*/}
              {/*<p><b>Created At:</b> {new Date(page.created_at * 1000).toLocaleDateString()}</p>*/}
              {/*<p><b>Updated At:</b> {new Date(page.updated_at * 1000).toLocaleDateString()}</p>*/}
              {/*<p><b>Status:</b> {page.flag_active === 'YES' ? 'Active' : 'Inactive'}</p>*/}
              <ButtonStyled variant={'glass'} onClick={() => onReadMore(page.slug)}>Show more</ButtonStyled>
            </div>
            {
              page.image_path !== null &&  <div className={styles.contentImage}>
                    <img src={`${config.fileUrls.page}${page.image_path}`} alt={page.h1}/>
                </div>
            }
          </div>
        ))}
      </div>

      {data && !(data.pagination.total_count < itemsPerPage) && (
        <Pagination
          variant="articles"
          onPageChange={(value) => setCurrentPage(Number(value))}
          totalCount={data.pagination.total_count}
          currentPage={currentPage}
          pageSize={itemsPerPage}
          siblingCount={SIBLING_COUNT}
          selectSettings={{
            value: itemsPerPage.toString(),
            onChangeOption: (value: string) => {
              setItemsPerPage(Number(value))
              setCurrentPage(1)
            },
            arr: PAGE_PAGINATION_VALUES.map((val) => val.toString()),
          }}
          className={styles.pagination}
          isSelectorOfPagesHidden={true}
        />
      )}
    </div>
  )
}
