import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import styles from './Limits.module.scss'
import clsx from 'clsx'
import { LimitsElement, LimitsElementType } from "./LimitsElement/LimitsElement"
import Modal from "../../../layouts/ui/modal/Modal"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectIsAuthenticated
} from "../../../../redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../redux/slices/site/loginSlice"

import {
    fetchDeleteResponsibleGaming,
    selectDeleteResponsibleGamingData,
    selectDeleteResponsibleGamingError,
    selectDeleteResponsibleGamingErrorArray,
    selectDeleteResponsibleGamingSuccess,
    selectDeleteResponsibleGamingMessagesString,
    selectDeleteResponsibleGamingLoading,
    DeleteResponsibleGamingParams
} from '../../../../redux/slices/Limits/deleteResponsibleGamingSlice'

import {
    fetchListResponsibleGamings,
    selectListResponsibleGamingsData,
    selectListResponsibleGamingsError,
    selectListResponsibleGamingsSuccess,
    selectListResponsibleGamingsLoading,
} from "../../../../redux/slices/Limits/listResponsibleGamingsSlice"

import {
    fetchAvailableLimits,
    selectAvailableLimitsData,
    selectAvailableLimitsError,
    selectAvailableLimitsSuccess,
    selectAvailableLimitsLoading,
    AvailableLimitsNames
} from "../../../../redux/slices/Limits/getAvailableLimitsSlice"


// import {
//     fetchDeleteResponsibleGaming,
//     selectDeleteResponsibleGamingData,
//     selectDeleteResponsibleGamingError,
//     selectDeleteResponsibleGamingSuccess,
//     selectDeleteResponsibleGamingLoading,
// } from "../../../../redux/slices/Limits/deleteResponsibleGamingSlice"

import { useAppDispatch, useAppSelector } from "../../../../hooks"
import Deposit from "./modalsBody/Deposit/Deposit"
import Bet from "./modalsBody/Bet/Bet"
import Loss from "./modalsBody/Loss/Loss"
import Self from "./modalsBody/Self/Self"


export type LimitItems = {
    period: string,
    amount: number,
    currency: string,
    spent: number
}

export type ModeTypes = 'set' | 'update' | 'delete'

export type ModeState = {
    deposit: ModeTypes,
    bet: ModeTypes,
    loss: ModeTypes,
    self: ModeTypes,
    
}

export type AvailableSetLimitsState = {
    deposit: boolean,
    bet: boolean,
    loss: boolean,
    self: boolean,
    
}

export const Limits = () => {

    const dispatch = useAppDispatch()
    const isAuthenticated = useSelector(selectIsAuthenticated);

    //   const {successModal, isShowPasswordModal} = useAppSelector(interfaceSelector)
    //   const passwordSuccessModalShow = successModal.show
    const listResponsibleGamingsData = useSelector(selectListResponsibleGamingsData)
    const listResponsibleGamingsError = useSelector(selectListResponsibleGamingsError)
    const listResponsibleGamingsSuccess = useSelector(selectListResponsibleGamingsSuccess)
    const listResponsibleGamingsLoading = useSelector(selectListResponsibleGamingsLoading)

    const availableLimitsData = useSelector(selectAvailableLimitsData);
    const availableLimitsError = useSelector(selectAvailableLimitsError);
    const availableLimitsSuccess = useSelector(selectAvailableLimitsSuccess);
    const availableLimitsLoading = useSelector(selectAvailableLimitsLoading);

    const deleteResponsibleGamingData = useSelector(selectDeleteResponsibleGamingData);
    const deleteResponsibleGamingError = useSelector(selectDeleteResponsibleGamingError);
    const deleteResponsibleGamingErrorArray = useSelector(selectDeleteResponsibleGamingErrorArray);
    const deleteResponsibleGamingSuccess = useSelector(selectDeleteResponsibleGamingSuccess);
    const deleteResponsibleGamingLoading = useSelector(selectDeleteResponsibleGamingLoading);
    const deleteResponsibleGamingMessagesString = useSelector(selectDeleteResponsibleGamingMessagesString);

    const token = useSelector(selectToken)

    const [showModalDeposit, setIsShowModalDeposit] = useState<boolean>(false);
    const [showModalBet, setIsShowModalBet] = useState<boolean>(false);
    const [showModalLoss, setIsShowModalLoss] = useState<boolean>(false);
    const [showModalSelfExclusion, setIsShowModalSelfExclusion] = useState<boolean>(false);

    const defaultLimitItems: LimitItems = {
        period: 'Not set',
        amount: 0,
        currency: '',
        spent: 0
    }

    const [depositLimit, setDepositLimit] = useState<LimitItems>(defaultLimitItems);
    const [betLimit, setBetLimit] = useState<LimitItems>(defaultLimitItems);
    const [lossLimit, setLossLimit] = useState<LimitItems>(defaultLimitItems);
    const [selfLimit, setSelfLimit] = useState<LimitItems>(defaultLimitItems);

    const defaultAvailableSetLimits: AvailableSetLimitsState = {
        deposit: false,
        bet: false,
        loss: false,
        self: false,
    };
    const [availableSetLimits, setAvailableSetLimits] = useState<AvailableSetLimitsState>(defaultAvailableSetLimits);

    const defaultMode: ModeState = {
        deposit: 'set',
        bet: 'set',
        loss: 'set',
        self: 'set',
    };

    const [mode, setMode] = useState<ModeState>(defaultMode);

    useEffect(() => {
        if (token) {
            getData(token)
        }
    }, [dispatch, token])


    useEffect(() => {
        console.log('limits mode', mode)
    }, [mode])
    

    useEffect(() => {
        if(deleteResponsibleGamingSuccess){
            // deleteResponsibleGamingData && window.location.reload();
            if (token) {
                getData(token)
            }
        }
        if(deleteResponsibleGamingError){
            console.error(deleteResponsibleGamingError)
        }
    }, [deleteResponsibleGamingSuccess, deleteResponsibleGamingData, deleteResponsibleGamingError])

    useEffect(() => {
        if (availableLimitsSuccess) {
            console.log('availableLimitsData', availableLimitsData);
            const availableLimits: AvailableSetLimitsState = {
                deposit: Boolean(availableLimitsData?.['deposit']),
                bet: Boolean(availableLimitsData?.['bet']),
                loss: Boolean(availableLimitsData?.['loss']),
                self: Boolean(availableLimitsData?.['self']),
            };
            setAvailableSetLimits(availableLimits)
        }
    }, [availableLimitsSuccess, availableLimitsData])

    useEffect(() => {
        if (listResponsibleGamingsSuccess) {
            const deposit = listResponsibleGamingsData.deposit?.data;
            const bet = listResponsibleGamingsData.bet?.data;
            const loss = listResponsibleGamingsData.loss?.data;
            const self = listResponsibleGamingsData.self?.data;

            if (!deposit || Object.keys(deposit).length === 0) {
                setDepositLimit(defaultLimitItems);
            } else {
                setDepositLimit({
                    period: deposit.Period,
                    amount: deposit.Amount.info.amount,
                    currency: deposit.Amount.info.currency,
                    spent: deposit.Amount.info.transactions
                });
            }

            if (!bet || Object.keys(bet).length === 0) {
                setBetLimit(defaultLimitItems);
            } else {
                setBetLimit({
                    period: bet.Period,
                    amount: bet.Amount.info.amount,
                    currency: bet.Amount.info.currency,
                    spent: bet.Amount.info.transactions
                });
            }

            if (!loss || Object.keys(loss).length === 0) {
                setLossLimit(defaultLimitItems);
            } else {
                setLossLimit({
                    period: loss.Period,
                    amount: loss.Amount.info.amount,
                    currency: loss.Amount.info.currency,
                    spent: loss.Amount.info.transactions
                });
            }

            if (!self || Object.keys(self).length === 0) {
                setSelfLimit(defaultLimitItems);
            } else {
                setSelfLimit({
                    period: self.Period,
                    amount: self.Amount.info.amount,
                    currency: self.Amount.info.currency,
                    spent: self.Amount.info.transactions
                });
            }

            console.log('listResponsibleGamingsData', listResponsibleGamingsData);
        }
    }, [dispatch, listResponsibleGamingsData, listResponsibleGamingsSuccess]);
    // listResponsibleGamingsData

    const getData = (token: string): void => {
        dispatch(fetchListResponsibleGamings({ token }))
        dispatch(fetchAvailableLimits({ token }))
        
    }

    const onDepositLimitSave = (token: string): void => {
        if(token){
            getData(token)
            setTimeout(() => {
                setIsShowModalDeposit(false);
            }, 1000);
        }
        
    }

    const onBetLimitSave = (token: string): void => {
        if(token){
            getData(token)
            setTimeout(() => {
                setIsShowModalBet(false);
            }, 1000);
        }
        
    }

    const onLossLimitSave = (token: string): void => {
        if(token){
            getData(token)
            setTimeout(() => {
                setIsShowModalLoss(false);
            }, 1000);
        }
        
    }

    const onSelfLimitSave = (token: string): void => {
        if(token){
            getData(token)
            setTimeout(() => {
                setIsShowModalSelfExclusion(false);
            }, 1000);
        }
        
    }
    
    const elements: Array<LimitsElementType> = [
        {
            title: 'Deposit limit',
            description: 'We offer you a variety of different options that you can set up to control your finances while gambling.',
            data: [
                {
                    name: 'Period',
                    value: depositLimit.period,
                },
                {
                    name: 'Amount',
                    value: `${depositLimit.amount} ${depositLimit.currency}`,
                },
                {
                    name: 'Already spent',
                    value: `${depositLimit.spent} ${depositLimit.currency}`,
                },
            ],
            buttonText: `${(mode.deposit).charAt(0).toUpperCase()}${mode.deposit.slice(1)} limit`,
            mode: mode.deposit,
            isAvailableSetLimit: availableSetLimits.deposit,
            onClickBotton: () => { 
                if(mode.deposit as ModeTypes == 'delete'){
                    const type_limit: AvailableLimitsNames = 'deposit';
                    const params: DeleteResponsibleGamingParams = {
                        token, 
                        type_limit
                    }
                    dispatch(fetchDeleteResponsibleGaming(params))
                }else{
                    setIsShowModalDeposit(true)
                }
                
            },
            onClickMenuItemAdd: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    deposit: 'set'
                }));
            },
            onClickMenuItemEdit: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    deposit: 'update'
                }));
            },
            onClickMenuItemDelete: () => {
                console.log('onClickMenuItemDelete')

                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    deposit: 'delete'
                }));
            },
        },
        {
            // title: 'Best amount limit',
            title: 'Bet limit',
            description: 'We offer you a variety of different options that you can set up to control your finances while gambling.',
            data: [
                {
                    name: 'Period',
                    value: betLimit.period,
                },
                {
                    name: 'Amount',
                    value: `${betLimit.amount} ${betLimit.currency}`,
                },
                {
                    name: 'Already spent',
                    value: `${betLimit.spent} ${betLimit.currency}`,
                },
            ],
            buttonText: `${(mode.bet).charAt(0).toUpperCase()}${mode.bet.slice(1)} limit`,
            mode: mode.bet,
            isAvailableSetLimit: availableSetLimits.bet,
            onClickBotton: () => { 
                if(mode.bet as ModeTypes == 'delete'){
                    const type_limit: AvailableLimitsNames = 'bet';
                    const params: DeleteResponsibleGamingParams = {
                        token, 
                        type_limit
                    }
                    dispatch(fetchDeleteResponsibleGaming(params))
                }else{
                    setIsShowModalBet(true)
                }
                
            },
            onClickMenuItemAdd: () => {
                console.log('onClickMenuItemAdd');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    bet: 'set'
                }));
            },
            onClickMenuItemEdit: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    bet: 'update'
                }));
            },
            onClickMenuItemDelete: () => {
                console.log('onClickMenuItemDelete')

                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    bet: 'delete'
                }));
            },
        },
        {
            title: 'Loss limit',
            description: 'We offer you a variety of different options that you can set up to control your finances while gambling.',
            data: [
                {
                    name: 'Period',
                    value: lossLimit.period,
                },
                {
                    name: 'Amount',
                    value: `${lossLimit.amount} ${lossLimit.currency}`,
                },
                {
                    name: 'Already spent',
                    value: `${lossLimit.spent} ${lossLimit.currency}`,
                },
            ],
            buttonText: `${(mode.loss).charAt(0).toUpperCase()}${mode.loss.slice(1)} limit`,
            mode: mode.loss,
            isAvailableSetLimit: availableSetLimits.loss,
            onClickBotton: () => { 
                if(mode.loss as ModeTypes == 'delete'){
                    const type_limit: AvailableLimitsNames = 'loss';
                    const params: DeleteResponsibleGamingParams = {
                        token, 
                        type_limit
                    }
                    dispatch(fetchDeleteResponsibleGaming(params))
                }else{
                    setIsShowModalLoss(true)
                }
                
            },
            onClickMenuItemAdd: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    loss: 'set'
                }));
            },
            onClickMenuItemEdit: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    loss: 'update'
                }));
            },
            onClickMenuItemDelete: () => {
                console.log('onClickMenuItemDelete')

                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    loss: 'delete'
                }));
            },
        },
        {
            title: 'Self-exclusion period',
            description: 'We offer you a variety of different options that you can set up to control your finances while gambling.',
            data: [
                {
                    name: 'Period',
                    value: selfLimit.period,
                },
                // {
                //     name: 'Amount',
                //     value: `${selfLimit.amount} ${selfLimit.currency}`,
                // },
                // {
                //     name: 'Already spent',
                //     value: `${selfLimit.spent} ${selfLimit.currency}`,
                // },
            ],
            buttonText: `${(mode.self).charAt(0).toUpperCase()}${mode.self.slice(1)} limit`,
            mode: mode.self,
            isAvailableSetLimit: availableSetLimits.self,
            onClickBotton: () => { 
                if(mode.self as ModeTypes == 'delete'){
                    const type_limit: AvailableLimitsNames = 'self';
                    const params: DeleteResponsibleGamingParams = {
                        token, 
                        type_limit
                    }
                    dispatch(fetchDeleteResponsibleGaming(params))
                }else{
                    setIsShowModalSelfExclusion(true)
                }
            },
            onClickMenuItemAdd: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    self: 'set'
                }));
            },
            onClickMenuItemEdit: () => {
                console.log('onClickMenuItemEdit');
                
                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    self: 'update'
                }));
            },
            onClickMenuItemDelete: () => {
                console.log('onClickMenuItemDelete')

                setMode((prevState: ModeState) => ({
                    ...prevState, 
                    self: 'delete'
                }));
            },
        },
    ]

    return (
        <div className={clsx(stylesCommon.profileRoundedTab, styles.security)}>
            {elements.map((element, index) => (
                <LimitsElement key={index} {...element} />
            ))}
            <Modal
                show={showModalDeposit}
                onClose={() => setIsShowModalDeposit(false)}
                body={<Deposit onSave={onDepositLimitSave} limit={depositLimit} mode={mode.deposit}/>}
                variant={'security'}
            />
            <Modal
                show={showModalBet}
                onClose={() => setIsShowModalBet(false)}
                body={<Bet onSave={onBetLimitSave} limit={betLimit} mode={mode.bet}/>}
                variant={'security'}
            />
            <Modal
                show={showModalLoss}
                onClose={() => setIsShowModalLoss(false)}
                body={<Loss onSave={onLossLimitSave} limit={lossLimit} mode={mode.loss}/>}
                variant={'security'}
            />
            <Modal
                show={showModalSelfExclusion}
                onClose={() => setIsShowModalSelfExclusion(false)}
                body={<Self onSave={onSelfLimitSave} limit={selfLimit} mode={mode.self}/>}
                variant={'security'}
            />
        </div>
    )
}