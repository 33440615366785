import {ButtonId, NavigationButtonData} from "../../../../common/types"
import React, {useState} from "react"
import {NavigationButtonsGroup} from "../../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {Wallet01Icon} from "../../../../assets/images/icons/withColorChange/Wallet01Icon"
import {CreditDeposit01Icon} from "../../../../assets/images/icons/withColorChange/CreditDeposit01Icon"
import {CreditWithdraw01Icon} from "../../../../assets/images/icons/withColorChange/CreditWithdraw01Icon"
import {CoinsExchange01Icon} from "../../../../assets/images/icons/withColorChange/CoinsExchange01Icon"
import {CurrencyBuycrypto01Icon} from "../../../../assets/images/icons/withColorChange/CurrencyBuycrypto01Icon"
import Accounts from "../../Accounts/Accounts"
import {openModal} from "../../../../redux/slices/modals/modalPlayerDepositSlice"
import {useAppDispatch} from "../../../../hooks"
import {setActiveButton} from "../../../../redux/slices/menuFinancialProcessesSlice"


export const MyWallet = () => {
  const [activeWalletButton, setActiveWalletButton] = useState<number>(1)
  const dispatch = useAppDispatch()


  const onDepositClick = () => {
    dispatch(openModal());
    dispatch(setActiveButton(1))
  }

  const onWithdrawClick = () => {
    dispatch(openModal());
    dispatch(setActiveButton(2))
  }

  const buttonData: Array<NavigationButtonData> = [
    {id: 1, label: 'Wallets', icon: Wallet01Icon},
    {id: 2, label: 'Deposit', icon: CreditDeposit01Icon, onClick: onDepositClick},
    {id: 3, label: 'Withdraw', icon: CreditWithdraw01Icon, onClick: onWithdrawClick},
    {id: 4, label: 'Exchange', icon: CoinsExchange01Icon},
    {id: 5, label: 'Buy Crypto', icon: CurrencyBuycrypto01Icon},
  ]

  const handleButtonClick = (id: ButtonId) => {
    // If the button with this ID has onClick, execute it
    const onClick = buttonData.find((btn) => btn.id === id)?.onClick;
    if (onClick) {
      onClick();
      return;
    }

    // If there is no onClick, just change the active tab
    setActiveWalletButton(id as number);
  }

  return (
    <div>
      <NavigationButtonsGroup buttonData={buttonData} activeButton={activeWalletButton}
                              handleButtonClick={handleButtonClick} variant={'profileMain'}/>
      {activeWalletButton === 1 && <Accounts /> }
    </div>

  )
}