import DeviceDetector from "device-detector-js";
import { deviceDetector } from "./deviceDetector";


export const deviceType = (): string => {
  const device = deviceDetector().device;
  const type = device ? device.type : "unknown";
  return `${type}`;
};

export const browser = (): string => {
  const client = deviceDetector().client;
  const name = client ? client.name : "unknown";
  const version = client ? client.version : "unknown";

  return `${name} ${version}`.trim();
};

export const os = (): string => {
  const operatingSystem = deviceDetector().os;
  const name = operatingSystem ? operatingSystem.name : "unknown";
  const version = operatingSystem ? operatingSystem.version : "unknown";
  const platform = operatingSystem ? operatingSystem.platform : "unknown";

  return `${name} ${version} (${platform})`.trim();
};
