import bonus_image from 'src/assets/images/bonus_image.jpg'
import styles from './MyBonusesElement.module.scss'
import {ButtonStyled} from "../../../../../../common/ButtonStyled/ButtonStyled"
import divider_vertical from 'src/assets/images/icons/divider_vertical.svg'

type Countdown = {
  days: number
  hours: number
  minutes: number
}

type Props = {
   bonusHeader: string
   bonusText: string
   isActive?: boolean
   countdown?: Countdown
}

export const MyBonusesElement = ({bonusHeader, bonusText, isActive, countdown}: Props) => {
  return (
    <div className={styles.myBonusesElement}>
      <div className={styles.bonusImgContainer}>
        <img src={bonus_image} alt="" className={styles.bonusImage}/>
        {isActive && <div className={styles.active}>Active</div> }
        {countdown && <div className={styles.countdown}>
            <span>{countdown.days} d</span>
            <img src={divider_vertical} alt="divider" className={styles.divider}/>
            <span>{countdown.hours} h</span>
            <img src={divider_vertical} alt="divider" className={styles.divider}/>
            <span>{countdown.minutes} m</span>
        </div>}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bonusHeader}>{bonusHeader}</div>
        <div className={styles.textButtonsContainer}>
          <div className={styles.bonusText}>{bonusText}</div>
          <div className={styles.buttons}>
            <ButtonStyled variant={'glass'} height={'40px'}>Learn more</ButtonStyled>
            <ButtonStyled variant={'accent'} height={'40px'}>Activate</ButtonStyled>
          </div>
        </div>
      </div>
    </div>
  )
}