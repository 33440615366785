import { useLocation, useParams, useNavigate } from 'react-router-dom'
import MainLayout from '../MainLayout/MainLayout'
import AuthLayout from '../AuthLayout/AuthLayout'
import React, { ReactNode, useEffect, useState } from 'react';
import { fetchIp, selectIpSuccess, selectIp } from '../../../redux/slices/ipSlice';
import { useSelector } from 'react-redux';
import {
    selectUserIpBlacklistSuccess,
    selectUserIpBlacklistError,
    selectHasUserIpBlacklist,
    fetchUserIpBlacklist
} from '../../../redux/slices/blacklist/userIpBlacklistSlice'; // Выбор селектора IP из состояния
import { useAppDispatch } from '../../../hooks';

import { BlacklistPage } from "../../pages/BlacklistPage/BlacklistPage";
import EmptyPage  from "../../pages/EmptyPage/EmptyPage";

type LayoutWrapperProps = {
    children: ReactNode;
};

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { param } = useParams();  // Параметр маршрута

    const isAuthPath = location.pathname.startsWith('/auth');
    const navigate = useNavigate()

    const ip = useSelector(selectIp);
    const ipSuccess = useSelector(selectIpSuccess);
    const userIpBlacklistSuccess = useSelector(selectUserIpBlacklistSuccess);
    const hasUserIpBlacklist = useSelector(selectHasUserIpBlacklist);

    const [showBlacklistScreen, setShowBlacklistScreen] = useState<boolean>(false);
    const [completeCheckBlacklist, setCompleteCheckBlacklist] = useState<boolean>(false);

    // Получаем значение из state из App путей
    const fromRedirect = location.state?.fromRedirect;


    useEffect(() => {
        setCompleteCheckBlacklist(false);
        // Загружаем текущий IP клиента
        if (!ip) {
            dispatch(fetchIp());
        }

    }, [ip]);

    useEffect(() => {
        // Загружаем текущий IP клиента
        if (ip && ipSuccess) {
            console.log('Client ip', ip);
            dispatch(fetchUserIpBlacklist({ ip: ip }));
        }

    }, [ip, ipSuccess]);

    useEffect(() => {
        console.log('hasUserIpBlacklist', hasUserIpBlacklist);
        if (userIpBlacklistSuccess) {
            setCompleteCheckBlacklist(true);
            if (hasUserIpBlacklist) {
                setShowBlacklistScreen(true);
            }
        }
        // if(hasUserIpBlacklist){
        //     setShowBlacklistScreen(true);
        // }

    }, [hasUserIpBlacklist, userIpBlacklistSuccess]);


    // console.log('Current path:', location.pathname);  // Логируем текущий путь
    // console.log('Param:', param);

    // Проверка на /auth без параметров
    // if (location.pathname === '/auth') {
    //     console.log('LayoutWrapper', 'AuthLayout /auth');
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    // // Проверка для путей с параметром, например /auth/login или /auth/signup
    // if (param === 'login' || param === 'signup') {
    //     console.log('LayoutWrapper', `AuthLayout /auth/${param}`);
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    if (completeCheckBlacklist) {
        if (showBlacklistScreen) {
            return <BlacklistPage />;
        }

        if (location.pathname.startsWith('/robots.txt')) {
            return <>{children}</>;
        }

        // Если ни одно из условий не подошло, используем MainLayout
        return <>
            <MainLayout>
                {!isAuthPath && children}
            </MainLayout>
            {/* Modal for /auth */}
            {isAuthPath && (
                <AuthLayout
                    show={isAuthPath}
                    onClose={() => {
                        // window.history.back(); // Return to the previous page
                        if (location.key !== 'default') {
                            // Если предыдущая страница только для игрока, и имеет редирект на аут пейдж
                            if (fromRedirect) {
                                navigate('/')
                            } else {
                                navigate(-1) // Переход на предыдущую страницу, если история есть
                            }
                        } else {
                            navigate('/') // Переход на главную, если истории нет
                        }
                    }}
                >
                    {children}
                </AuthLayout>
            )}
        </>;
    } else {
        return <EmptyPage />
    }

};

export default LayoutWrapper;
