import {useAppDispatch} from "../../hooks"
import {setAccountActiveButton} from "../../redux/slices/menus/menuAccountSlice"
import {setProfileActiveButton} from "../../redux/slices/menus/menuProfileSlice"

export const useVerifyClick = () => {
  const dispatch = useAppDispatch()

  const onVerifyClick = () => {
    dispatch(setAccountActiveButton('myProfile'))
    dispatch(setProfileActiveButton('verification'))
  }

  return { onVerifyClick }
}

