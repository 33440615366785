import styles from './SecurityElement.module.scss'
import shield_2 from 'src/assets/images/shield_2.png'
import {ButtonStyled} from "../../../../../common/ButtonStyled/ButtonStyled"
import {useTranslation} from "react-i18next";

export type SecurityElementType = {
  title: string
  description: string
  buttonText: string
  onClick: () => void
}

export const SecurityElement = ({title, description, buttonText, onClick}: SecurityElementType) => {
  const {t} = useTranslation()
  return (
    <div className={styles.securityElement}>
      <div>
        <img src={shield_2} alt="-" />
        <div className={styles.title}>{t(title)}</div>
        <div className={styles.description}>{t(description)}</div>
      </div>
      <ButtonStyled variant={'accent'} onClick={onClick} className={styles.button}>{t(buttonText)}</ButtonStyled>
    </div>
  )
}