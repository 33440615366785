import {useSelector} from "react-redux"
import {
  selectToken
} from "../../../src/redux/slices/site/loginSlice"
import {setBalanceLoadingAfterSwitch, setListBalancesDropdownShown} from "src/redux/slices/interfaceSlice"
import {switchAccount} from "src/redux/slices/switchAccountSlice"
import {useCallback} from "react"
import {useGetListBalancesQuery} from "src/api/api"
import {useAppDispatch} from "../../hooks"

export const useSwitchAccount = () => {
  const dispatch = useAppDispatch()
  const token = useSelector(selectToken)

  const {refetch} = useGetListBalancesQuery({token: token ? token : ''}, {skip: !token})

  const onSwitchAccountClick = useCallback(
    async (currencyId: number) => {
      if (token) {
        dispatch(setBalanceLoadingAfterSwitch(true))
        dispatch(setListBalancesDropdownShown(false))
        await dispatch(switchAccount({token: token, currencyId: currencyId}))
        refetch()
      }
    },
    [dispatch, token, refetch]
  )

  return {onSwitchAccountClick}
}
