import React, { useState, useRef, useEffect, FormEvent, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks";
import {
    selectIsAuthenticated
} from "../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../src/redux/slices/site/loginSlice"
import styles from './AddBonus.module.scss';
import bonus_image from 'src/assets/images/bonus_image.jpg'
import divider_vertical from 'src/assets/images/icons/divider_vertical.svg'
import { useTranslation } from "react-i18next"
import Wrapper from './Wrapper/Wrapper'
import axios from 'axios'
import Alert, { AlertProps } from '../../layouts/ui/alert/Alert.js'
import { OnBonus } from "../../layouts/modals/ModalAddBonus"
// import TextInput from './TextInput';

import { 
    openModal as openModalBonus, 
    closeModal as closeModalBonus, 
    selectIsShowModal as  selectIsShowModalBonus,

  } from '../../../redux/slices/modals/modalBonusInfoSlice'

import {
    fetchGetBonusInfo,
    selectGetBonusInfoLoading,
    selectGetBonusInfoSuccess,
    selectGetBonusInfoError,
    selectGetBonusInfoErrorString,
    selectGetBonusInfoErrorArray,
    selectGetBonusInfoMessages,
    selectGetBonusInfoData,
    resetState as resetStateGetBonusInfo

} from '../../../redux/slices/Bonus/getBonusInfoSlice'

import {
    fetchBonusSubscribe,
    selectBonusSubscribeLoading,
    selectBonusSubscribeSuccess,
    selectBonusSubscribeError,
    selectBonusSubscribeErrorArray,
    selectBonusSubscribeErrorString,
    selectBonusSubscribeMessages,
    selectBonusSubscribeData,
    resetState as resetStateBonusSubscribe

} from '../../../redux/slices/Bonus/bonusSubscribeSlice'

import {
    fetchListBalances,
    selectListBalancesSliceData,
    selectListBalancesSliceMessages,
    selectListBalancesSliceError,
    selectListBalancesSliceSuccess,
    selectListBalancesSliceLoading,
    selectListBalancesSliceErrorArray,
    selectBalanceMainCurrency,
    BalanceMainCurrency
} from "src/redux/slices/Account/listBalancesSlice"


interface AddBonusProps {
    onBonus: OnBonus | null;
}

type BonusInfo = {
    id: string,
    id_main_currency: number,
    name: string,
    description: string,
    isActive: boolean,
    image_link: string,
    period: string,
    amount: string,
    bonus: string,
    alreadySpent: string,
    maxWin: number | string,
    wagering: string,
    minDeposit: string,
    maxBet?: string,
    freeSpinsGame: string,
    availability: string | JSX.Element,
}

// type MainCurrency = {
//     id: number,

// }

const AddBonus: React.FC<AddBonusProps> = ({ onBonus }) => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);

    const { t } = useTranslation()

    const getBonusInfoLoading = useSelector(selectGetBonusInfoLoading);
    const getBonusInfoSuccess = useSelector(selectGetBonusInfoSuccess);
    const getBonusInfoError = useSelector(selectGetBonusInfoError);
    const getBonusInfoErrorArray = useSelector(selectGetBonusInfoErrorArray);
    const getBonusInfoErrorString = useSelector(selectGetBonusInfoErrorString);
    const getBonusInfoMessages = useSelector(selectGetBonusInfoMessages);
    const getBonusInfoData = useSelector(selectGetBonusInfoData);

    const bonusSubscribeLoading = useSelector(selectBonusSubscribeLoading);
    const bonusSubscribeSuccess = useSelector(selectBonusSubscribeSuccess);
    const bonusSubscribeError = useSelector(selectBonusSubscribeError);
    const bonusSubscribeErrorString = useSelector(selectBonusSubscribeErrorString);

    const listBalancesSliceData = useSelector(selectListBalancesSliceData)
    const listBalancesSliceError = useSelector(selectListBalancesSliceError)
    const listBalancesSliceSuccess = useSelector(selectListBalancesSliceSuccess)
    const listBalancesSliceLoading = useSelector(selectListBalancesSliceLoading)
    const listBalancesSliceErrorArray = useSelector(selectListBalancesSliceErrorArray)
    const balanceMainCurrency = useSelector(selectBalanceMainCurrency)

    const isShowModalBonus = useSelector(selectIsShowModalBonus)

    const [bonusInfo, setBonusInfo] = useState<null | BonusInfo>(null);
    const [mainCurrency, setMainCurrency] = useState<null | BalanceMainCurrency>(null);

    const [ip, setIp] = useState<string>('')

    const countdown = {
        days: 10,
        hours: 10,
        minutes: 10
    };

    // useEffect(() => {
    //     setBonusInfo(null);
    // }, [])
    
    useEffect(() => {
        if (isAuthenticated && token) {
            dispatch(fetchListBalances({ token }));
        }
    }, [token, isAuthenticated])

    useEffect(() => {
        if (listBalancesSliceSuccess && balanceMainCurrency) {
            console.log('AddBonus balanceMainCurrency:', balanceMainCurrency)
            console.log('AddBonus listBalancesSliceData:', listBalancesSliceData)
            setMainCurrency({
                id: balanceMainCurrency.id,
                code: balanceMainCurrency.code,
            })
        }
    }, [token, listBalancesSliceSuccess, balanceMainCurrency, listBalancesSliceData])

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
                setIp(response.data.ip)
            } catch (error) {
                console.error('Error fetching IP:', error)
            }
        }

        fetchIp()
    }, [])

    useEffect(() => {
        if (isAuthenticated && token) {

            if (onBonus) {
                const params = {
                    id: onBonus.id,
                    language: '1',
                    ip: ip,
                };
                console.log('AddBonus params', params);
                dispatch(fetchGetBonusInfo({ token, params }));
            } else {
                console.error('Bonus ID is not set!');
            }
        }
        console.log('AddBonus: fetchGetBonusInfo');
    }, [dispatch, token, isAuthenticated, onBonus, ip]);


    useEffect(() => {
        console.log('AddBonus: getBonusInfoData', getBonusInfoData);
        console.log('AddBonus: onBonus', onBonus);
        setBonusInfo(null);
        if (getBonusInfoSuccess && balanceMainCurrency) {
            // TODO откуда заполнять закомментированные данные!
            const mainCurrencyId = balanceMainCurrency.id;
            const mainCurrencyCode = balanceMainCurrency.code;

            setBonusInfo({
                id: getBonusInfoData.id,
                id_main_currency: mainCurrencyId,
                name: getBonusInfoData.bonusLangs[0]?.name || "Bonus",

                description: (() => {
                    if (onBonus && onBonus.bonusEventDeposits.length > 0) {
                        const foundDeposit: (any) = onBonus.bonusEventDeposits.find(
                            (item: any) => item.currency.id == mainCurrencyId
                        );
                
                        if (foundDeposit) {
                            return `Deposit ${foundDeposit.amount} ${foundDeposit.currency.code} once to activate the bonus`;
                        }
                    }
                    return "";
                })(),
                

                // isActive: getBonusInfoData.flag_active === "YES",
                isActive: false,
                image_link: getBonusInfoData.image_link || bonus_image,
                period: `${getBonusInfoData.expiration_relative_duration} ${getBonusInfoData.expiration_relative_type}`,
                amount: `${getBonusInfoData.distribution_bet_real} ${mainCurrencyCode}`,
                // bonus: `${getBonusInfoData.bonusLangs[0]?.description.match(/\d+\s(?:Free Spins|FS)/)?.[0] || 'N/A'}`,
                bonus: getBonusInfoData.distribution_win_bonus,
                alreadySpent: `${getBonusInfoData.distribution_bet_real} ${mainCurrencyCode}`,
                // maxWin: getBonusInfoData.withdrawal_restriction === "none" ? "No max win" : `${getBonusInfoData.withdrawal_restriction} USD`,

                maxWin: (() => {
                    if (onBonus && onBonus.bonusAccruals.length > 0) {
                        const accrual = onBonus.bonusAccruals.find(
                            (item) => item.id_currency == mainCurrencyId
                        );
                        if (accrual) {
                            if (accrual.flag_bonus_max_unlim === "NO") {
                                return `${accrual.bonus_max} ${mainCurrencyCode}`;
                            }
                        }
                    }
                    return t("Unlimited");
                })(),


                // wagering: `x${getBonusInfoData?.wagering_type === "relative" ? getBonusInfoData.wagering_value : 0}`,
                wagering: (() => {
                    if (onBonus && onBonus.wagering_type) {

                        if (onBonus.wagering_type === 'none') {
                            return t("None");
                        }
                        if (onBonus.wagering_type === 'relative') {
                            if (onBonus && onBonus.bonusWagerings.length > 0) {
                                const wagering = onBonus.bonusWagerings.find(
                                    (item) => item.id_currency == mainCurrencyId
                                );
                                if (wagering) {
                                    return `X${wagering.multiplier}`;

                                }
                            }
                        }
                        if (onBonus.wagering_type === 'absolute') {
                            if (onBonus && onBonus.bonusWagerings.length > 0) {
                                const wagering = onBonus.bonusWagerings.find(
                                    (item) => item.id_currency == mainCurrencyId
                                );
                                if (wagering && wagering.amount) {
                                    return `${wagering.amount} ${mainCurrencyCode}`;

                                }
                            }
                        }
                    }
                    return t("None");
                })(),

                // minDeposit: getBonusInfoData.bonusLangs[0]?.description.match(/Minimal Deposit: (.*?)Maximal/)[1].trim(),

                minDeposit: (() => {
                    if (onBonus && onBonus.bonusEventDeposits.length > 0) {
                        const depositString = onBonus.bonusEventDeposits
                            .map((item: any) => `${item.amount} ${item.currency.code}`)
                            .join('; ');
                        return `${depositString}`
                    } else {
                        return "-";
                    }
                })(),
                // maxBet: getBonusInfoData.bonusLangs[0]?.description.match(/Maximal Bet: (.*?)Available/)[1].trim(),

                maxBet: (() => {
                    if (onBonus && onBonus.bonusWageringToward.length > 0) {
                        const bonusWageringTowardString = onBonus.bonusWageringToward
                            .map((item: any) => {
                                if (item.flag_unlim === 'NO') {
                                    return `${item.amount} ${item.currency.code}`;
                                } else {
                                    return `${t('Unlim')} ${item.currency.code}`;
                                }
                            })
                            .join('; ');
                        return `${bonusWageringTowardString}`;
                    } else {
                        return 'Unlim'; // or '-'
                    }
                })(),

                // freeSpinsGame: getBonusInfoData.bonusLangs[0]?.description.match(/Free Spins Game: (.*?)\(/)[1].trim(),
                freeSpinsGame: (() => {
                    if (onBonus && onBonus.bonusFreespins.length > 0) {
                        const freespins = onBonus.bonusFreespins.find(
                            (item) => item.currency.id == mainCurrencyId
                        );
                        if (freespins) {
                            return `${freespins.provider_name} / ${freespins.game_name}`;

                        }
                    }
                    return t("-");
                })(),

                // availability: "Available once a week on Friday. Please, note that you will see the bonus in active tab only after deposit."
                availability: (() => {
                    const availabilityData = getBonusInfoData.availability_data;
                    let infoString: (string | JSX.Element) = '';
                    let extraString = '';
                
                    if (availabilityData && typeof availabilityData === 'object') {
                        let datesString = null;
                        let daysString = null;
                        let timesString = null;
                
                        if (Array.isArray(availabilityData.dates) && availabilityData.dates.length > 0) {
                            datesString = `Dates: ${availabilityData.dates.join(', ')}`;
                        }
                
                        if (Array.isArray(availabilityData.days) && availabilityData.days.length > 0) {
                            daysString = `Days: ${availabilityData.days.join(', ')}`;
                        }
                
                        if (Array.isArray(availabilityData.times) && availabilityData.times.length > 0) {
                            timesString = `Time: ${availabilityData.times.join(', ')}`;
                        }
                
                        // Собираем строки для отображения, если они существуют
                        const availabilityParts = [
                            datesString && <p key="dates">{datesString}</p>,
                            daysString && <p key="days">{daysString}</p>,
                            timesString && <p key="times">{timesString}</p>
                        ].filter(Boolean); // Убираем null и undefined
                
                        if (availabilityParts.length > 0) {
                            infoString = <>{availabilityParts}</>;
                        }
                    }
                
                    if (infoString) {
                        return <>
                            {
                                infoString ? 
                                    <>
                                    <p>Availability:</p> 
                                    <div>{infoString} </div>
                                    </>
                                    
                                : 
                                ''
                            }
                            <p>{"Please, note that you will see the bonus in active tab only after deposit."}</p>
                        </>;
                    } else {
                        return <p>{t("Please, note that you will see the bonus in active tab only after deposit.")}</p>;
                    }
                })(),
                


            });
        }


    }, [dispatch, getBonusInfoData, getBonusInfoSuccess, balanceMainCurrency]);

    useEffect(() => {
        if (bonusSubscribeSuccess) {
            const timer = setTimeout(() => {
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }

    }, [dispatch, bonusSubscribeSuccess]);

    const onBonusSubscribe = (bonusId: string, currencyId: number) => {
        if (isAuthenticated && token) {
            dispatch(fetchBonusSubscribe({ token, bonusId, currencyId }));
        }
    };

    const getAlertProps = (): AlertProps | null => {
        if (getBonusInfoError) {
            return { type: "error", message: getBonusInfoErrorString, showButtomClose: false };
        }

        if (bonusSubscribeError) {
            return { type: "error", message: bonusSubscribeErrorString, showButtomClose: false };
        }

        if (bonusSubscribeSuccess) {
            return {
                type: "success",
                // message: t('Operation was successful'),
                message: t('Operation was successful'),
                // onClose: () => setIsWithdrawalSuccess(false) 
                showButtomClose: false
            };
        } else {
            if (!bonusSubscribeLoading && bonusSubscribeError) {
                return { type: "error", message: bonusSubscribeErrorString, showButtomClose: false };
            }
        }

        return null;
    }


    const getAlertPosition = (): 'top' | 'bottom' => {
        if (getBonusInfoError) {
            return "top";
        }
        return 'bottom';
    };

    return (
        <Wrapper
            alertProps={getAlertProps()}
            alertPosition={getAlertPosition()}
        >
            <div className={styles.addBonus}>
                {!bonusInfo && getBonusInfoLoading && (t('Loading...'))}
                {bonusInfo && (
                    <>
                        {/* Заголовок */}
                        <h5>{bonusInfo.name}</h5>

                        {/* Описание под заголовком */}
                        <div className="d-flex justify-content-left my-2">
                            <span>{bonusInfo.description}</span>
                        </div>

                        {/* Блок с изображением бонуса и статусом */}
                        <div className={styles.bonusImgContainer}>
                            <img src={bonusInfo.image_link} alt="Bonus Image" className={styles.bonusImage} />
                            {bonusInfo.isActive && <div className={styles.active}>Active</div>}

                            {/* Таймер обратного отсчета */}
                            {countdown && (
                                <div className={styles.countdown}>
                                    <span>{countdown.days} d</span>
                                    <img src={divider_vertical} alt="divider" className={styles.divider} />
                                    <span>{countdown.hours} h</span>
                                    <img src={divider_vertical} alt="divider" className={styles.divider} />
                                    <span>{countdown.minutes} m</span>
                                </div>
                            )}
                        </div>

                        {/* Информация о бонусе (Period, Amount, Bonus, Already Spent) */}
                        <div className="d-flex gap-2 my-4">
                            <div className="input-group">
                                <div className="form-control d-flex justify-content-between align-items-center">
                                    <span>{t('Period')}</span>
                                    <span>{bonusInfo.period}</span>
                                </div>
                            </div>

                            <div className="input-group">
                                <div className="form-control d-flex justify-content-between align-items-center">
                                    <span>{t('Amount')}</span>
                                    <span>{bonusInfo.amount}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex gap-2 my-2">
                            <div className="input-group">
                                <div className="form-control d-flex justify-content-between align-items-center">
                                    <span>{t('Bonus')}</span>
                                    <span>{bonusInfo.bonus}</span>
                                </div>
                            </div>

                            <div className="input-group">
                                <div className="form-control d-flex justify-content-between align-items-center">
                                    <span>{t('Already spent')}</span>
                                    <span>{bonusInfo.alreadySpent}</span>
                                </div>
                            </div>
                        </div>

                        {/* Детальная информация о бонусе */}
                        <div className={`${styles.bonusInfoBlockContainer} my-4`}>
                            <div className="d-flex justify-content-between align-items-center">
                                <span>{t('Max Win')}</span>
                                <span>{bonusInfo.maxWin}</span>
                            </div>
                            <hr />

                            <div className="d-flex justify-content-between align-items-center">
                                <span>{t('Wagering')}</span>
                                <span>{bonusInfo.wagering}</span>
                            </div>
                            <hr />

                            <div className="d-flex justify-content-between align-items-center">
                                <span>{t('Min Deposit')}</span>
                                <span>{bonusInfo.minDeposit}</span>
                            </div>
                            <hr />

                            {bonusInfo.maxBet && (
                                <>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>{t('Max Bet')}</span>
                                        <span>{bonusInfo.maxBet}</span>
                                    </div>
                                    <hr />
                                </>
                            )}


                            <div className="d-flex justify-content-between align-items-center">
                                <span>{t('Free Spins Game')}</span>
                                <span>{bonusInfo.freeSpinsGame}</span>
                            </div>
                            <hr />

                        </div>

                        {/* Примечание о бонусе */}
                        <div className="d-flex justify-content-center my-4">
                            <span>{bonusInfo.availability}</span>
                        </div>

                        {/* Кнопка активации */}
                        <div className="d-flex">
                            <button
                                type="button"
                                className="btn btn-outline-success w-100"
                                onClick={() => (onBonusSubscribe(bonusInfo.id, bonusInfo.id_main_currency))}
                            >
                                {
                                    bonusSubscribeLoading ? t('Loading...') : t('Activate')
                                }

                            </button>
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    );

};

export default AddBonus;
