import {useEffect, useState} from 'react'
import {useAppDispatch} from "../../../../hooks"
import {useSelector} from "react-redux"
import {
  selectIsAuthenticated
} from "../../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
  selectToken
} from "../../../../../src/redux/slices/site/loginSlice"
import {connectListBalancesWebSocket} from "src/redux/actions/player/listBalances"
import {selectListBalances} from "src/redux/selectors/player/listBalancesSelectors"
import {Balance} from "../../../../common/types"

export const useGetCurrencySelectOptions = () => {
  const [closeWebSocket, setCloseWebSocket] = useState<(() => void) | undefined>(undefined)
  const [currencySelectOptions, setCurrencySelectOptions] = useState<{ value: string; label: string; }[]>([])
  const dispatch = useAppDispatch()
  const token = useSelector(selectToken)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const listBalances = useSelector(selectListBalances) as Balance[] | null

  useEffect(() => {
    if (isAuthenticated && token) {
      const closeWebSocket = dispatch(connectListBalancesWebSocket(token))
      setCloseWebSocket(() => closeWebSocket) // saving closeWebSocket function to close socket after listBalances is received
    }
  }, [dispatch, isAuthenticated, token])

  useEffect(() => {
    if (listBalances) {
      const formattedBalances = listBalances?.map(item => ({
        value: item.currency_code,
        label: item.currency_code
      }))
      setCurrencySelectOptions(formattedBalances)
      closeWebSocket?.() // closing connection after listBalances is received
    }

  }, [listBalances])

  return {currencySelectOptions}
}
    