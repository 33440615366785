// //Withdrawal.js

import config from '../../../config.js'
import React, { FormEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from "../../../hooks"
import {
    selectIsAuthenticated
} from "../../../../src/redux/slices/site/isAuthenticatedSlice"
import {
    selectToken
} from "../../../../src/redux/slices/site/loginSlice"
import axios from 'axios'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';

import {
    selectPlayerBalances,
    selectPlayerBalancesErrorString,
    selectPlayerBalancesLoading
} from '../../../redux/selectors/playerDeposit/playerBalancesSelectors'
import { fetchGetPlayerBalances } from '../../../redux/actions/playerDeposit/playerBalancesActions'

import {
    selectGetPaymentMethodsWithdrawalLoading,
    selectGetPaymentMethodsWithdrawalSuccess,
    selectGetPaymentMethodsWithdrawalError,
    selectGetPaymentMethodsWithdrawalMessages,
    selectGetPaymentMethodsWithdrawalData,
    selectGetPaymentMethodsWithdrawalErrorArray,
    selectGetPaymentMethodsWithdrawalErrorString,
    resetState,
    fetchGetPaymentMethodsWithdrawal,
} from '../../../redux/slices/Withdrawal/getPaymentMethodsWithdrawalSlice'

import {
    selectSetWithdrawalRequestLoading,
    selectSetWithdrawalRequestSuccess,
    selectSetWithdrawalRequestError,
    selectSetWithdrawalRequestMessages,
    selectSetWithdrawalRequestData,
    selectSetWithdrawalRequestErrorArray,
    selectSetWithdrawalRequestErrorString,
    resetStateSetWithdrawalRequest,
    fetchSetWithdrawalRequest,
} from '../../../redux/slices/Withdrawal/setWithdrawalRequestSlice'

import { selectCurrentView, setCurrentView } from '../../../redux/slices/Withdrawal/paymentMethodsWithdrawalViewsSlice'

import CurrencyAndPayment from './CurrencyAndPayment/CurrencyAndPayment'
import PlayerInfo from './PlayerInfo/PlayerInfo'

import Alert, { AlertProps } from '../../layouts/ui/alert/Alert.js'

import Wrapper from './Wrapper/Wrapper'
import { openModal, closeModal, selectIsShowModal } from "../../../redux/slices/modals/modalPlayerDepositSlice"

export interface Balance {
    id: string;
    currency_id: number;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    currency_flag_active: string;
    flag_main: string;
    amount: string;
    comment: string | null;
    created_at: string;
    updated_at: string;
}

export interface PaymentMethod {
    id: string;
    name: string;
    image_path: string;
    direction: string;
    min_deposit: string;
    max_deposit: string;
    pay_system_id: string;
    pay_system_name: string;
    pay_system_tech_name: string;
    currency_id: number;
    currency_title: string;
    currency_code: string;
    currency_symbol: string | null;
    fields: any[]; // Можете уточнить тип для полей, если они будут известны
}

interface WithdrawalProps {
    propCurrencyId?: number | null;
}

const Withdrawal: React.FC<WithdrawalProps> = ({ propCurrencyId }) => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = useSelector(selectToken);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentView = useSelector(selectCurrentView);

    const playerBalances = useSelector(selectPlayerBalances);
    const playerBalancesLoading = useSelector(selectPlayerBalancesLoading);
    const playerBalancesErrorString = useSelector(selectPlayerBalancesErrorString);

    const getPaymentMethodsWithdrawalData = useSelector(selectGetPaymentMethodsWithdrawalData);
    const getPaymentMethodsWithdrawalLoading = useSelector(selectGetPaymentMethodsWithdrawalLoading);
    const getPaymentMethodsWithdrawalSuccess = useSelector(selectGetPaymentMethodsWithdrawalSuccess);
    const getPaymentMethodsWithdrawalError = useSelector(selectGetPaymentMethodsWithdrawalError);
    const getPaymentMethodsWithdrawalMessages = useSelector(selectGetPaymentMethodsWithdrawalMessages);
    const getPaymentMethodsWithdrawalErrorArray = useSelector(selectGetPaymentMethodsWithdrawalErrorArray);
    const getPaymentMethodsWithdrawalErrorString = useSelector(selectGetPaymentMethodsWithdrawalErrorString);

    const setWithdrawalRequestData = useSelector(selectSetWithdrawalRequestData);
    const setWithdrawalRequestLoading = useSelector(selectSetWithdrawalRequestLoading);
    const setWithdrawalRequestSuccess = useSelector(selectSetWithdrawalRequestSuccess);
    const setWithdrawalRequestError = useSelector(selectSetWithdrawalRequestError);
    const setWithdrawalRequestMessages = useSelector(selectSetWithdrawalRequestMessages);
    const setWithdrawalRequestErrorArray = useSelector(selectSetWithdrawalRequestErrorArray);
    const setWithdrawalRequestErrorString = useSelector(selectSetWithdrawalRequestErrorString);

    const [selectedCurrency, setSelectedCurrency] = useState<Balance | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
    const [ip, setIp] = useState<string>('')

    const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);

    useEffect(() => {
        // Возвращаем функцию очистки для сброса состояния при размонтировании компонента 
        return () => {
            dispatch(resetStateSetWithdrawalRequest());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated && token) {
            dispatch(fetchGetPlayerBalances(token));
            dispatch(setCurrentView('CurrencyAndPayment'));
        }

    }, [dispatch, token])

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
                setIp(response.data.ip)
            } catch (error) {
                console.error('Error fetching IP:', error)
            }
        }

        fetchIp()
    }, [])

    useEffect(() => {
        let defaultCurrency: Balance | null = null;
        if (propCurrencyId) {
            defaultCurrency = playerBalances.find(
                (balance: Balance) => balance.currency_id === propCurrencyId
            );
        } else if (selectedCurrency === null) {
            defaultCurrency = playerBalances.find(
                (balance: Balance) => balance.flag_main === "YES" && balance.currency_flag_active === "YES"
            );
        }

        if (defaultCurrency) {
            setSelectedCurrency(defaultCurrency);
            setSelectedPaymentMethod(null);
            findPaymentMethods(defaultCurrency.currency_id);
        }

    }, [playerBalances]);


    useEffect(() => {
        if (setWithdrawalRequestLoading === false) {
            if (setWithdrawalRequestSuccess) {
                setIsWithdrawalSuccess(true);
            } else {
                setIsWithdrawalSuccess(false);
            }
        }
        if (setWithdrawalRequestError) {
            console.error('set withdrawal request error', setWithdrawalRequestError);
        }
        // console.log('Withdrawal setWithdrawalRequestSuccess', setWithdrawalRequestSuccess);

    }, [setWithdrawalRequestSuccess, setWithdrawalRequestLoading, setWithdrawalRequestError]);

    useEffect(() => {
        if (isWithdrawalSuccess) {
            const timer = setTimeout(() => {
                navigate('/transactions', {
                    state: {
                        activeTabId: 2,
                    },
                  });
                  dispatch(closeModal());
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isWithdrawalSuccess, navigate]);

    //     // Функция для получения методов оплаты
    const findPaymentMethods = (currency_id: number) => {
        const direction = 'withdrawal';
        const language = 2;
        let params = {
            id_currency: currency_id,
            direction: direction,
            language: language
        };
        token && dispatch(fetchGetPaymentMethodsWithdrawal({ token, params }));
    };

    const handleCurrencyChange = (currency_id: number) => {
        const selected = playerBalances.find((balance: Balance) => balance.currency_id === currency_id);
        setSelectedCurrency(selected);
        setSelectedPaymentMethod(null);
        findPaymentMethods(selected.currency_id);
    };

    const handlePaymentMethodChange = (id: string) => {
        const selectedMethod = getPaymentMethodsWithdrawalData.find((method: PaymentMethod) => method.id === id);
        setSelectedPaymentMethod(selectedMethod);
        // console.log('selectedMethod', selectedMethod);
    };

    const handleFormSubmit = (e: FormEvent<Element>, fieldsToSave: any): void => {
        e.preventDefault();
        if (isAuthenticated && token) {
            if (selectedCurrency && selectedPaymentMethod) {
                
                let finance_details: { [key: string]: any } = {};
                if (selectedPaymentMethod && selectedPaymentMethod.fields) {
                    selectedPaymentMethod.fields.forEach((field) => {
                        // if (fieldsToSave.includes(field.name_tech)) {
                        //     finance_details[field.name_tech] = fieldsToSave[field.name_tech];
                        // }
                        if (fieldsToSave.hasOwnProperty(field.name_tech)) {
                            finance_details[field.name_tech] = fieldsToSave[field.name_tech];
                        }

                    });
                }

                let params = {
                    amount: fieldsToSave.amount,
                    comment: fieldsToSave.comment || "",

                    id_payment_paysystem: 1,
                    id_payment_method: selectedPaymentMethod.id,
                    id_payment_contract: 1,
                    id_currency: selectedCurrency.currency_id,
                    ip: ip,

                    // Доп. поля из метода оплаты
                    finance_details: JSON.stringify(finance_details),
                }
                // console.log('Withdrawal: params', params);
                dispatch(fetchSetWithdrawalRequest({ token, params }));

            }
        }
    };


    const renderView = () => {
        switch (currentView) {
            case 'CurrencyAndPayment':
                return (
                    <>
                        <CurrencyAndPayment
                            playerBalances={playerBalances}
                            getPaymentMethods={getPaymentMethodsWithdrawalData}
                            playerBalancesLoading={playerBalancesLoading}
                            playerBalancesErrorString={playerBalancesErrorString}
                            getPaymentMethodsLoading={getPaymentMethodsWithdrawalLoading}
                            getPaymentMethodsErrorString={getPaymentMethodsWithdrawalErrorString}
                            selectedCurrency={selectedCurrency}
                            selectedPaymentMethod={selectedPaymentMethod}
                            handleCurrencyChange={handleCurrencyChange}
                            handlePaymentMethodChange={handlePaymentMethodChange}
                        />
                        {selectedCurrency && selectedPaymentMethod &&
                            <PlayerInfo
                                handleFormSubmit={handleFormSubmit}
                                selectedCurrency={selectedCurrency}
                                selectedPaymentMethod={selectedPaymentMethod}
                            />
                        }

                    </>
                );

            default:
                return null; // Можно вернуть null или какой-то компонент по умолчанию
        }
    };

    const getAlertProps = (): AlertProps | null => {
        if (getPaymentMethodsWithdrawalError) {
            return { type: "error", message: getPaymentMethodsWithdrawalErrorString, showButtomClose: false };
        }

        if (isWithdrawalSuccess) {
            return {
                type: "success",
                // message: t('Operation was successful'),
                message: t('Operation was successful'),
                // onClose: () => setIsWithdrawalSuccess(false) 
                showButtomClose: false
            };
        } else {
            if (!setWithdrawalRequestLoading && setWithdrawalRequestError) {
                return { type: "error", message: setWithdrawalRequestErrorString, showButtomClose: false };
            }
        }

        return null;
    }


    const getAlertPosition = (): 'top' | 'bottom' => {
        if (getPaymentMethodsWithdrawalError) {
            return "top";
        }
        return 'bottom';
    };

    return (
        <Wrapper
            alertProps={getAlertProps()}
            alertPosition={getAlertPosition()}
        >
            <>
                {/* {setWithdrawalRequestError && setWithdrawalRequestErrorArray && setWithdrawalRequestErrorArray.length > 0 && (
                        <Alert type="error">
                            {setWithdrawalRequestMessages && <p><strong>{setWithdrawalRequestMessages}</strong></p>}
                            {setWithdrawalRequestErrorArray.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </Alert>
                    )} */}

                {/* Для того, чтобы выводить только сообщение */}
                {!isWithdrawalSuccess && renderView()}
            </>

        </Wrapper>
    );
};


export default Withdrawal;
