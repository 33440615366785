//ModalPlayerDeposit.js
import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch } from "../../../hooks"
import AddBonus from "../../pages/AddBonus/AddBonus"
import Modal from "../ui/modal/Modal"
import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'
import stylesModal from '../ui/modal/Modal.module.scss'

interface BonusAccrualsItem {
    id_currency: number,
    flag_bonus_max_unlim: string,
    bonus_max: number
}

interface BonusWageringsItem {
    id_currency: number,
    multiplier: number,
    amount?: number,
}

interface bonusWageringTowardItem {
    id_currency: number,
    flag_unlim: "YES" | "NO",
    amount?: number,
}

export type BonusFreespinsItem = {
    spin_count: number | null,
    wager_multiplier: number | null,
    currency: {
      id: number,
    },
    game_name: string,
    provider_name: string,
}

export interface OnBonus {
    id: number,
    bonusEventDeposits: [],
    bonusAccruals: BonusAccrualsItem[],
    bonusWagerings: BonusWageringsItem[],
    bonusFreespins: BonusFreespinsItem[] | [],
    bonusWageringToward: bonusWageringTowardItem[] | [],
    wagering_type: string,
}

interface ModalAddBonusProps {
    isShowModal: boolean;
    onCloseModal: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    onBonus: OnBonus | null;
}

const ModalAddBonus: React.FC<ModalAddBonusProps> = ({isShowModal, onCloseModal, onBonus})  => {

    const dispatch = useAppDispatch()
    const previousView = useSelector(selectPreviousView)

    const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setPreviousView())
    }
    
    return (

        <>
            <Modal
                show={isShowModal}
                onClose={onCloseModal}
                // title="Add Wallet"
                body={<AddBonus onBonus={onBonus ? onBonus : null} />}
            />
        </>
    );
};

export default ModalAddBonus;
